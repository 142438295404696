<template>
  <div class="title-with-circle-container"
       v-if="!activeMobile"
       :class="{'active' : activeIndexState}"
       @mouseenter="focusTrue"
       @mouseleave="focusFalse">
    <transition name="fade-scale">
      <icon-circle v-if="activeIndexState || (focusParam && !allowTouchMove)"/>
    </transition>
    <span class="title-with-circle-text-content">
       <slot />
    </span>
  </div>
  <div class="title-with-circle-container"
       v-else
       :class="{'active' : activeIndexState || activeIndexStatus}"
       @mouseenter="focusTrue"
       @mouseleave="focusFalse">
      <transition name="fade-scale">
        <icon-circle v-if="activeIndexState || (focusParam && !allowTouchMove)"/>
      </transition>
    <span class="title-with-circle-text-content">
       <slot />
    </span>
  </div>
</template>

<script>
import IconCircle from "@/components/shared/icons/IconCircle.vue";

export default {
  name: "TextWithCircle",
  components: {IconCircle},
  props: {
    activeIndexStatus: {
      type: Boolean,
      default: false
    },
    itemInfo: {
      type: Object,
      required: true
    },
    activeIndex: {
      required: true
    }
  },
  data() {
    return {
      focusParam: false
    }
  },
  computed: {
    allowTouchMove() {
      return this.$store.state.activeTouchAllow
    },
    activeIndexState() {
      if(typeof this.activeIndex === 'number') {
        return this.itemInfo.id === this.activeIndex
      } else {
        return this.activeIndex
      }
    }
  }

}
</script>

<style scoped lang="scss">
.title-with-circle-container {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;

  .title-with-circle-text-content {
    transition: all .6s ease;
    opacity: .3;
  }

  &.active {
    .title-with-circle-text-content {
      opacity: 1;
    }
  }

  .icon-circle-container {
    position: absolute;
  }
  &:hover {
    .title-with-circle-text-content {
      opacity: 1;
    }
  }
}

@media (max-width: 3024px){
        .title-with-circle-container {
          .icon-circle-container {
            left: -42px;
          }
        }
}


@media (max-width: 2100px) {
        .title-with-circle-container {
          .icon-circle-container {
            left: -36px;
          }
        }

}


@media (max-width: 1750px) {
        .title-with-circle-container {
          .icon-circle-container {
            left: -30px;
          }
        }
}
@media (max-height: 800px) {
        .title-with-circle-container {

          .icon-circle-container {
            left: -22px;
          }
        }
}

@media (max-width: 1400px) and (min-height: 801px) {
        .title-with-circle-container {

          .icon-circle-container {
            left: -30px;
          }
        }
}

@media (max-width: 1133px) {
}
@media (max-width: 769px) {
  .title-with-circle-container {
    width: 100%;

    &.active {
      .title-with-circle-text-content {
        padding-left: 24px;
      }
    }

    .title-with-circle-text-content {
      transition: padding-left .6s ease;
    }

    .icon-circle-container {
      left: 2px;
    }

  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){

}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {

        .title-with-circle-container {

          .icon-circle-container {
            left: -80px;
          }
        }
}

</style>