<template>
    <div class="card-project-container"
         :class="{'active-scale' : !activeCardMobile && activeMobile, 'active-color' : activeTouchAllow}"
         @mouseenter="focusTrue" @mouseleave="focusFalse">
      <div class="card-text-container">
        <div class="card-text-content">
          <main-title>
            {{ infoCard?.mainTitle }}
          </main-title>
          <div class="card-sub-title-content" :class="{'eng' : activeEng}">
            <p class="sub-title-container">
              {{ $t('projectScreen.yearTitle') }} {{ infoCard?.yearDate }}
            </p>
            <p class="sub-title-container">
              {{ $t('projectScreen.durationTitle') }} {{ infoCard?.durationDate }}
            </p>
            <p class="sub-title-container">
              {{ $t('projectScreen.clientTitle') }} {{ infoCard?.clientName }}
            </p>
          </div>
          <main-paragraph>
            {{ infoCard?.descriptionText }}
          </main-paragraph>
        </div>
        <p class="watermark-container">
          {{ infoCard?.sutTitle }}
        </p>
      </div>
      <transition name="fade-opacity-lazy">
        <div class="img-container" v-show="focusParam && infoCard?.img !== undefined || activeTouchAllow">
          <div class="color-container"></div>
          <img :src="getUrlProject(infoCard?.img)" alt="bg">
        </div>
      </transition>
    </div>
</template>

<script>
import MainTitle from "@/components/shared/card-text/MainTitle.vue";
import MainParagraph from "@/components/shared/card-text/MainParagraph.vue";

export default {
  name: "CardProject",
  components: {MainParagraph, MainTitle},
  props: {
    infoCard: {
      type: Object,
      required: true
    },
    activeCardMobile: {
      type: Boolean
    }
  },
  data() {
    return {
      focusParam: false
    }
  },
  computed: {
    activeTouchAllow() {
      return this.$store.state.activeTouchAllow;
    }
  }

}
</script>

<style scoped lang="scss">
p {
  line-height: 120%;
  white-space: pre-wrap;
}

  .card-project-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-card-division);
    transition: all 1s ease;

    &.active-color {
      .card-text-container {
        .card-text-content {
          .main-title-container {
            color: var(--color-main-title-hover) !important;
          }

          .card-sub-title-content {
            color: var(--color-main-title-hover) !important;
          }

          .card-description-content {
            color: var(--color-main-title-hover) !important;
          }
        }
      }
    }

    &.active-scale {
      transform: scale(95%);
      .card-text-container {
        .card-text-content {
          .main-title-container {
            color: var(--color-main-title-hover) !important;
          }

          .card-sub-title-content {
            color: var(--color-main-title-hover) !important;
          }

          .card-description-content {
            color: var(--color-main-title-hover) !important;
          }
        }

        .watermark-container {
          opacity: .5;
        }
      }
      opacity: .4;
    }


    &.active-mobile {
      opacity: 1;
      transform: scale(1);
      .card-text-container {
        .card-text-content {
          .main-title-container {
            color: var(--color-main-title-hover) !important;
          }

          .card-sub-title-content {
            color: var(--color-main-title-hover) !important;
          }

          .card-description-content {
            color: var(--color-main-title-hover) !important;
          }
        }

        .watermark-container {
          opacity: .5;
        }
      }
    }

    &:hover {
      .card-text-container {
        .card-text-content {
          .main-title-container {
            color: var(--color-main-title-hover) !important;
          }

          .card-sub-title-content {
            color: var(--color-main-title-hover) !important;
          }

          .card-description-content {
            color: var(--color-main-title-hover) !important;
          }
        }

        .watermark-container {
          opacity: .5;
        }
      }

    }

    .card-text-container {
      height: 100%;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .card-text-content {
        display: flex;
        flex-direction: column;

        .main-title-container {
          transition: color .6s ease;
          color: var(--color-card-main-title) !important;
        }

        .card-sub-title-content {
          transition: color .6s ease;
          color: var(--color-card-main-text) !important;
        }

        .card-description-content {
          transition: color .6s ease;
          color: var(--color-card-main-title) !important;
        }
      }

      .watermark-container {
        margin-left: auto;
        transition: opacity .6s ease;
        opacity: 0;
        color: var(--color-main-title-hover);
        white-space: pre-wrap;
      }
    }

    .img-container {
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(4, 13, 54, 0.60) 0%, rgba(4, 13, 54, 0.60) 100%), lightgray 50% / cover no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;

      .color-container {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--color-card-hover);
        opacity: .6;
      }

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

@media (max-width: 3840px) and (min-width: 3025px){
  .card-project-container {
    max-height: 1172px;
    padding: 120px 80px 80px;
    border-radius: 8px;


    .card-text-container {
      .card-text-content {
        row-gap: 64px;

        .main-title-container {
          font-size: 46px;
        }

        .card-sub-title-content {
          font-size: 32px;
        }
        .card-description-content {
          font-size: 42px;
        }
      }
      .watermark-container {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 3024px){
  .card-project-container {
    max-height: 556px;
    padding: 60px 40px 40px;
    border-radius: 8px;


    .card-text-container {
      .card-text-content {
        row-gap: 40px;

        .card-sub-title-content {
          font-size: 20px;
        }
      }
      .watermark-container {
        font-size: 14px;
      }
    }
  }
}



@media (max-width: 2100px) {
  .card-project-container {
    max-height: 492px;
    padding: 40px 30px 30px;
    border-radius: 8px;


    .card-text-container {
      .card-text-content {
        row-gap: 32px;

        .card-sub-title-content {
          font-size: 16px;
        }
      }
      .watermark-container {
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 1750px) {
  .card-project-container {
    max-height: 439px;
    padding: 30px 24px 24px;
    border-radius: 8px;


    .card-text-container {
      .card-text-content {
        row-gap: 24px;

        .card-sub-title-content {
          font-size: 14px;
        }
      }
      .watermark-container {
        font-size: 8px;
      }
    }
  }
}
@media (max-height: 800px) {
  .card-project-container {
    max-height: 364px;
    padding: 32px 16px 16px;
    border-radius: 8px;


    .card-text-container {
      .card-text-content {
        row-gap: 24px;
        .card-sub-title-content {
          font-size: 12px;
        }
      }
      .watermark-container {
        font-size: 6px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .card-project-container {
    max-height: 439px;
    padding: 30px 24px 24px;
    border-radius: 8px;


    .card-text-container {
      .card-text-content {
        row-gap: 24px;


        .card-sub-title-content {
          font-size: 14px;
        }
      }
      .watermark-container {
        font-size: 8px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .card-project-container {
    max-height: 364px;
    padding: 32px 16px 16px;
    border-radius: 8px;


    .card-text-container {
      .card-text-content {
        row-gap: 24px;
        .card-sub-title-content {
          font-size: 12px;
        }
      }
      .watermark-container {
        font-size: 6px;
      }
    }
  }
}
@media (max-width: 769px) {
  .card-project-container {
    max-height: 440px;
    padding: 24px 16px 16px;
    border-radius: 8px;

    .img-container {
      background: linear-gradient(0deg, rgba(36, 39, 44, 0.90) 0%, rgba(36, 39, 44, 0.90) 100%), lightgray 50% / cover no-repeat
    }

    .card-text-container {
      .card-text-content {
        row-gap: 16px;
        .card-sub-title-content {
          font-size: 12px;
        }
      }
      .watermark-container {
        font-size: 6px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .card-project-container {
    max-height: 462px;
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .card-project-container {
    max-height: 347px;
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .card-project-container {
    max-height: 412px;
  }
}


@media (max-width: 320px) {
  .card-project-container {
    max-height: 250px;

    .card-text-container {
      .card-text-content {
        row-gap: 12px;
        .card-sub-title-content {
          font-size: 10px;
        }
      }
      .watermark-container {
        font-size: 4px;
      }
    }
  }
}

@media (min-width: 3841px) {
  .card-project-container {
    max-height: 1270px;
    padding: 120px 80px 80px;
    border-radius: 8px;


    .card-text-container {
      .card-text-content {
        row-gap: 64px;

        .main-title-container {
          font-size: 46px;
        }

        .card-sub-title-content {
          font-size: 32px;
        }

        .card-description-content {
          font-size: 42px;
        }

      }
      .watermark-container {
        font-size: 18px;
      }
    }
  }
}

</style>