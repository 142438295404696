<template>
  <swiper @slide-change="onSwiperMain"
          @swiper="changeSettingSwiper"
          :spaceBetween="12"
          :slidesPerView="slidesPerView"
          :centeredSlides="true"
          :modules="modules"
          class="mobile-division"
          :allowTouchMove="true"
          :loop="true"
          :speed="800"
          direction="vertical">
    <swiper-slide v-for="item in activeTitleInfo"
                  @click="slideToTitle(item.id)"
                  v-slot="{ isActive }"
                  :key="item.id">
      <text-with-circle :active-index="activeIndex"
                        :class="{'is-active' : activeIndex === item.id}"
                        :item-info="item">
        <main-paragraph :class="{'eng' : activeEng}">
          {{ item.mainTitle }}
        </main-paragraph>
      </text-with-circle>
    </swiper-slide>
  </swiper>
</template>

<script>
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import TextWithCircle from "@/components/shared/TextWithCircle.vue";

export default {
  name: "ScrollMobileDivision",
  components: {TextWithCircle, MainParagraph},
  props: {
    activeTitleInfo: {
      type: Array,
      required: true
    },
    activeIndexScroll: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      modules: [],
      scrollSwiper: '',
      activeIndex: 0,
      blockScroll: true
    }
  },
  computed: {
    slidesPerView() {
      if(this.activeTitleInfo.length <= 2) {
        return 3
      } else {
        return 5
      }
    }
  },
  mounted() {
    if(this.activeIndexScroll !== this.activeIndex) {
      this.scrollSwiper.slideToLoop(this.activeIndexScroll, 0)
      this.$nextTick(() => {
        this.blockScroll = false
      })
    } else {
      this.blockScroll = false
    }
  },
  methods: {

    slideToTitle(id) {
      this.scrollSwiper.slideToLoop(id)
    },

    onSwiperMain(swiper) {
      this.activeIndex = swiper.realIndex;
      if(!this.blockScroll) {
        this.$emit('changeIndex', this.activeIndex)
      }
    },

    changeSettingSwiper(swiper) {
      this.scrollSwiper = swiper
    },
  }
}
</script>

<style scoped lang="scss">

.swiper {
  padding: 0 var(--controller-padding);
  &:after {
    visibility: visible;
    content: "";
    position: absolute;
    width: 100%;
    height: 30%;
    z-index: 2;
    bottom: 0;
    right: 0;
    pointer-events: none;
    background: linear-gradient(180deg,  rgba(34, 34, 59, 0.00) 0, var(--color-button-cookie) 90%);
  }

  &:before {
    visibility: visible;
    content: "";
    position: absolute;
    width: 100%;
    height: 30%;
    z-index: 2;
    top: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(0deg,  rgba(34, 34, 59, 0.00) 0, var(--color-button-cookie) 90%);
  }
}

.title-with-circle-container {
  transform: scale(90%);
  transition: all .6s ease;
  &.is-active {
    transform: scale(1);
  }
}

@media (max-width: 769px) {
  .main-paragraph-container {
    font-size: 12px;
  }
}

@media (max-width: 375px){
  .main-paragraph-container {
    font-size: 10px;
  }
}




</style>