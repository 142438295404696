<template>
  <div class="card-secretary-button-container" :class="{'eng' : activeEng}">
    <div class="top-info-content">
      <div class="img-container">
        <img :src="getUrlIcons(infoCard.icon)" alt="icon">
      </div>
      <p class="sub-title-container">
        {{ infoCard.mainTitle }}
      </p>
    </div>
    <div class="bottom-info-content">
      <sub-paragraph v-for="item in infoCard.subParagraph">
        {{ item }}
      </sub-paragraph>
    </div>
  </div>
</template>

<script>
import SubParagraph from "@/components/shared/text/SubParagraph.vue";

export default {
  name: "CardSecretaryButton",
  components: {SubParagraph},
  props: {
    infoCard: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .card-secretary-button-container {
    border-color: var(--color-button-border);
    border-style: solid;
    background-color: var(--color-background);
    display: flex;
    flex-direction: column;
    .top-info-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      .img-container {
        height: 100%;
        width: 100%;

        & img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .bottom-info-content {
      display: flex;
      flex-direction: column;
      .sub-paragraph-container {
        line-height: 120%;
        font-weight: 200;
        color: var(--color-main-title);
      }
    }
  }

  @media (max-width: 3024px){
    .card-secretary-button-container {
      border-width: 7px;
      padding: 32px;
      border-radius: 32px;
      row-gap: 32px;
      max-width: 550px;
      .top-info-content {
        column-gap: 24px;
        .img-container {
          max-width: 40px;
          max-height: 40px;
        }

        .sub-title-container {
          font-size: 28px;
        }
      }

      .bottom-info-content {
        row-gap: 5px;
        .sub-paragraph-container {
          font-size: 22px;
        }
      }
    }
  }

  @media (max-width: 2100px) {
    .card-secretary-button-container {
      border-width: 6px;
      padding: 24px;
      border-radius: 24px;
      row-gap: 24px;
      max-width: 440px;
      .top-info-content {
        column-gap: 20px;
        .img-container {
          max-width: 32px;
          max-height: 32px;
        }

        .sub-title-container {
          font-size: 24px;
        }
      }

      .bottom-info-content {
        row-gap: 4px;
        .sub-paragraph-container {
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: 1750px) {
    .card-secretary-button-container {
      border-width: 4px;
      padding: 16px;
      border-radius: 16px;
      row-gap: 16px;
      max-width: 367px;
      .top-info-content {
      column-gap: 16px;
      .img-container {
        max-width: 18px;
        max-height: 18px;
      }

      .sub-title-container {
        font-size: 18px;
      }
    }

      .bottom-info-content {
        row-gap: 3px;
        .sub-paragraph-container {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .card-secretary-button-container {
      border-width: 3px;
      padding: 12px;
      border-radius: 12px;
      row-gap: 12px;
      max-width: 275px;
      .top-info-content {
        column-gap: 22px;
        .img-container {
          max-width: 14px;
          max-height: 14px;
        }

        .sub-title-container {
          font-size: 16px;
        }
      }

      .bottom-info-content {
        row-gap: 2px;
        .sub-paragraph-container {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-secretary-button-container {
      border-width: 4px;
      padding: 16px;
      border-radius: 16px;
      row-gap: 16px;
      max-width: 367px;
      .top-info-content {
        column-gap: 16px;
        .img-container {
          max-width: 18px;
          max-height: 18px;
        }

        .sub-title-container {
          font-size: 18px;
        }
      }

      .bottom-info-content {
        row-gap: 3px;
        .sub-paragraph-container {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
  }
  @media (max-width: 769px) {
    .card-secretary-button-container {
      border-width: 3px;
      padding: 12px;
      border-radius: 12px;
      row-gap: 12px;
      max-width: 100%;
      .top-info-content {
        column-gap: 22px;
        .img-container {
          max-width: 14px;
          max-height: 14px;
        }

        .sub-title-container {
          font-size: 16px;
        }
      }

      .bottom-info-content {
        row-gap: 2px;
        .sub-paragraph-container {
          font-size: 12px;
          font-weight: 200;
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .card-secretary-button-container {
      border-width: 3px;
      padding: 10px;
      border-radius: 12px;
      row-gap: 8px;
      max-width: 100%;
      .top-info-content {
        column-gap: 22px;
        .img-container {
          max-width: 14px;
          max-height: 14px;
        }

        .sub-title-container {
          font-size: 16px;
        }
      }

      .bottom-info-content {
        row-gap: 2px;
        .sub-paragraph-container {
          font-size: 11px;
          font-weight: 200;
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .card-secretary-button-container {
      border-width: 3px;
      padding: 12px;
      border-radius: 12px;
      row-gap: 12px;
      max-width: 100%;
      .top-info-content {
        column-gap: 22px;
        .img-container {
          max-width: 14px;
          max-height: 14px;
        }

        .sub-title-container {
          font-size: 16px;
        }
      }

      .bottom-info-content {
        row-gap: 2px;
        .sub-paragraph-container {
          font-size: 12px;
          font-weight: 200;
        }
      }
    }
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .card-secretary-button-container {
      border-width: 10px;
      padding: 40px;
      border-radius: 40px;
      row-gap: 40px;
      max-width: 912px;
      .top-info-content {
        column-gap: 48px;
        .img-container {
          max-width: 64px;
          max-height: 64px;
        }

        .sub-title-container {
          font-size: 48px;
        }
      }

      .bottom-info-content {
        row-gap: 8px;
      }
    }
  }

</style>