<template>
  <p class="main-title-container" :class="{'eng' : activeEng}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainTitle"
}
</script>

<style scoped lang="scss">
p {
  line-height: 120%;
  white-space: pre-wrap;
}
      .main-title-container {
        transition: color .6s ease;
        color: var(--color-main-title);
      }

@media (max-width: 3840px){
        .main-title-container {
          font-size: 56px;
        }
}

@media (max-width: 3024px){
        .main-title-container {
          font-size: 28px;
        }
}


@media (max-width: 2100px) {
        .main-title-container {
          font-size: 24px;
        }
}

@media (max-width: 1750px) {
        .main-title-container {
          font-size: 20px;
        }
}
@media (max-height: 800px) {
        .main-title-container {
          font-size: 18px;
        }
}

@media (max-width: 1400px) and (min-height: 801px) {
        .main-title-container {
          font-size: 20px;
        }
}

@media (max-width: 1133px) {
        .main-title-container {
          font-size: 18px;
        }
}


@media (max-width: 320px) {
  .main-title-container {
    font-size: 14px;
  }
}


@media (min-width: 3841px) {
        .main-title-container {
          font-size: 56px;
        }
}

</style>