<template>
  <p class="sub-title-container" :class="{'eng' : activeEng && activeStaticEng}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "SubTitle",
  props: {
    activeStaticEng: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">

.sub-title-container {
  line-height: 120%;
  color: var(--color-main-title)
}

@media (max-width: 3024px){
  .sub-title-container {
    font-size: 32px;
  }
}


@media (max-width: 2100px) {
  .sub-title-container {
    font-size: 24px;
  }
}

@media (max-width: 1750px) {
  .sub-title-container {
    font-size: 20px;
  }
}
@media (max-width: 769px) {
  .sub-title-container {
    font-size: 18px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .sub-title-container {
    font-size: 20px;
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  .sub-title-container {
    font-size: 14px;
  }
}

@media (min-width: 3025px) {
  .sub-title-container {
    font-size: 46px;
  }
}

</style>