<template>
<div class="year-block-container">
  <p class="year-text" v-for="(item, index) in historyText" :key="index" :class="{'dash' : item === '–'}">
    {{ item }}
  </p>
</div>
</template>

<script>
export default {
  name: "YearBlock",
  props: {
    historyText: {
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .year-block-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .year-text {
      line-height: 120%;
      color: var(--color-main-title);

      &.dash {
        color: var(--color-main-visioners)
      }
    }
  }

  @media (max-width: 3024px){
    .year-block-container {
      .year-text {
        font-size: 170px;
        letter-spacing: 1.7px;
      }
    }
  }


  @media (max-width: 2100px) {
    .year-block-container {
      .year-text {
        font-size: 128px;
        letter-spacing: 1.28px;
      }
    }
  }


  @media (max-width: 1750px) {
    .year-block-container {
      .year-text {
        font-size: 96px;
        letter-spacing: 0.96px;
      }
    }
  }

  @media (max-height: 800px) {
    .year-block-container {
      .year-text {
        letter-spacing: 0.72px;
        font-size: 72px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {

    .year-block-container {
      .year-text {
        font-size: 96px;
        letter-spacing: 0.96px;
      }
    }
  }

  @media (max-width: 1133px) {
    .year-block-container {
      .year-text {
        letter-spacing: 0.72px;
        font-size: 72px;
      }
    }
  }

  @media (max-width: 769px) {
    .year-block-container {
      flex-direction: row;
      column-gap: 12px;
      .year-text {
        letter-spacing: 0.36px;
        font-size: 36px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 748px) and (max-height: 1000px) {
    .year-block-container {
      flex-direction: row;
      column-gap: 12px;
      .year-text {
        letter-spacing: 0.48px;
        font-size: 48px;
      }
    }
  }

  @media (max-width: 375px){
    .year-block-container {
      flex-direction: row;
      column-gap: 12px;
      .year-text {
        letter-spacing: 0.32px;
        font-size: 32px;
      }
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {
    .year-block-container {
      flex-direction: row;
      column-gap: 12px;
      .year-text {
        letter-spacing: 0.48px;
        font-size: 48px;
      }
    }
  }

  @media (max-width: 320px) {
    .year-block-container {
      flex-direction: row;
      column-gap: 12px;
      .year-text {
        letter-spacing: 0.24px;
        font-size: 24px;
      }
    }
  }

  @media (min-width: 3025px) {
      .year-block-container {
        .year-text {
          font-size: 256px;
          letter-spacing: 2.56px;
        }
      }
  }

</style>