<template>
  <div class="toggle-local-container" @click="changeLanguage">
    <span>
       {{ $t('local') }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ToggleLocal",
  data() {
    return {
      activeButton: false
    }
  },
  methods: {
      changeLanguage() {
        this.$i18n.locale === 'en' ? this.$i18n.locale = 'ru' :  this.$i18n.locale = 'en'
        document.documentElement.lang = this.$i18n.locale
        let newLocale = this.$route.params
        newLocale.lang = this.$i18n.locale
        this.$router.push({name: this.$route.name, params: newLocale})
      },
    },
}
</script>

<style scoped lang="scss">
  .toggle-local-container {
    background-color: var(--color-card-division);
    font-family: 'ProximaNova', sans-serif;
    cursor: pointer;
    color: var(--color-main-visioners);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .6s ease;

    &:hover {
      & span {
        filter: blur(0px);
      }
    }

    & span {
      transition: filter .6s ease;
      filter: blur(var(--active-blur));
    }

  }

  @media (max-width: 3024px){
    .toggle-local-container {
      font-size: 22px;
      border-radius: 10px;
      width: 80px;
      height: 40px;
    }
  }


  @media (max-width: 2100px) {
    .toggle-local-container {
      font-size: 18px;
      border-radius: 9px;
      width: 72px;
      height: 38px;
    }
  }

  @media (max-width: 1750px) {
    .toggle-local-container {
      font-size: 14px;
      border-radius: 7px;
      width: 60px;
      height: 30px;
    }
  }

  @media (max-height: 800px) {
    .toggle-local-container {
      font-size: 12px;
      border-radius: 5px;
      width: 48px;
      height: 24px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .toggle-local-container {
      font-size: 14px;
      border-radius: 7px;
      width: 60px;
      height: 30px;
    }
  }

  @media (max-width: 1133px) {
    .toggle-local-container {
      font-size: 12px;
      border-radius: 5px;
      width: 48px;
      height: 24px;
    }
  }


  @media (max-width: 769px) {
    .toggle-local-container {
      font-size: 14px;
      border-radius: 7px;
      width: 60px;
      height: 30px;

      & span {
        filter: blur(0px);
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //iPhone Plus
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .toggle-local-container {
      font-size: 32px;
      border-radius: 12px;
      width: 96px;
      height: 48px;
    }
  }

</style>