<template>
  <div class="strategy-screen-container main-container">
    <transition name="fade-opacity-1">
      <button-title-mobile v-if="activeMobile && activeTitle"
                           :active-button="false"
                           :main-title="$t('breadCrumb.dynamicTitle.strategy.staticTitle')"/>
    </transition>

    <card-text-info :paragraph-info="$tm('strategyScreen.mainParagraph')"
                    v-if="!activeMobile"
                    @endAnimation="endAnimation"
                    :active-text="'vision 2040'"
                    @clickActive="routTo"
                    :active-button="!activeMobile"
                    :active-static-title="true"
                    :active-eng-static="true"
                    :main-title="$t('strategyScreen.mainTitle')"/>

    <card-text-info :paragraph-info="$tm('strategyScreen.mainParagraph')"
                    @clickActive="routTo"
                    :active-padding-text="true"
                    v-else
                    @endAnimation="endAnimation"
                    :active-text="'vision 2040'"
                    :active-button="!activeMobile"
                    :active-static-title="true"
                    :active-eng-static="true"
                    :main-title="$t('strategyScreen.mainTitleMobile')"/>

    <div :class="['img-container desktop', {'active-img': activeImg && isLoadingImg}]"
         v-if="!activeMobile" >
        <img  @load="loadImg"   :src="getUrlStrategy('vector.webp')" alt="img">
    </div>
    <div :class="['img-container mobile', {'active-img': activeImg && isLoadingImg}]"
         v-if="activeMobile" >
        <img  @load="loadImg"  :src="getUrlStrategy('vector-mobile.webp')" alt="img">
    </div>
  </div>
</template>

<script>
import CardTextInfo from "@/components/entities/cards/CardTextInfo.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";

export default {
  name: "StrategyScreen",
  components: {ButtonTitleMobile, CardTextInfo},
  data() {
    return {
      isLoadingImg: false,
      activeImg: false,
      activeTitle: false
    }
  },
  mounted() {
    this.activeTitle = true
    if(!this.activeMobile) {
      setTimeout(() => {
        this.activeImg = true
      }, 500)
    }
  },
  methods: {
    loadImg() {
      this.isLoadingImg = true;
    },
    endAnimation() {
      this.activeImg = true
    },
    routTo() {
      this.$router.push({name: 'brand-history-page', params: {typeHistory: 'vision'}})
    }
  }
}
</script>

<style scoped lang="scss">
.strategy-screen-container {
  flex-direction: row;

  .img-container {
    width: 100%;
    height: var(--calc-height);
    position: relative;
    top: calc(-1 * var(--main-padding-top));
    opacity: 0;

    &.desktop {
      transition: all .5s ease;
      transform: translateX(30px);
      &.active-img {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &.mobile {
      transition: opacity 1s ease;
      &.active-img {
        opacity: 1;
      }
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 3024px){
  .strategy-screen-container {
    .img-container {
      max-width: 930px;
    }
  }
}



@media (max-width: 2100px) {
  .strategy-screen-container {
    .img-container {
      max-width: 762px;
    }
  }
}

@media (max-width: 1750px) {
  .strategy-screen-container {
    .img-container {
      max-width: 660px;
    }
  }
}
@media (max-height: 800px) {
  .strategy-screen-container {
    .img-container {
      max-width: 502px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .strategy-screen-container {
    .img-container {
      max-width: 660px;
    }

  }
}

@media (max-width: 1133px) {
  .strategy-screen-container {
    .img-container {
      max-width: 502px;
    }
  }
}
@media (max-width: 769px) {
  .strategy-screen-container {
    flex-direction: column;
    padding: var(--burger-height) 0 0;

    .card-text-container {
      max-width: 620px;
      position: relative;
      z-index: 801;
      justify-content: flex-start;
    }

    .main-title-container {
      padding-left: var(--controller-padding);
    }

    .img-container {
      max-width: 136px;
      right: 0;
      position: absolute;
      width: 100%;
      height: calc(100% + var(--burger-height));
      top: calc(-1 * var(--burger-height) + 16px);
      z-index: 800;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .strategy-screen-container {
    .card-text-container {
      max-width: 620px;
    }

    .img-container {
      max-width: 156px;
      top: calc(-1 * var(--burger-height));
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){

}


@media (max-width: 375px) and (min-height: 630px) {
  .strategy-screen-container {

    .card-text-container {
      max-width: 620px;
    }

    .img-container {
      max-width: 136px;
      top: calc(-1 * var(--burger-height) + 16px);
    }
  }
}


@media (min-width: 3025px) {
  .strategy-screen-container {
    .card-text-container {
      max-width: 1540px;
    }
    .img-container {
      max-width: 1582px;
    }
  }
}
</style>