<template>
  <transition name="fade-opacity-lazy">
  <div class="innovation-screen-container main-container" v-if="activeScreen">
    <transition name="fade-opacity-1">
      <button-title-mobile v-if="activeMobile && activeTitle"
                           @returnTo="routToHome"
                           :active-button="true"
                           :main-title="$t('breadCrumb.dynamicTitle.projects.staticTitle')"/>
    </transition>
    <div class="innovation-screen-content">
      <div class="left-info-content">
        <div class="top-info-content">
          <animation-text :active-color-text="$t('innovationScreen.colorText')"
                          v-if="!activeMobile"
                          @endAnimation="endAnimation"
                          :text-info="$t('innovationScreen.mainTitle')"/>
          <transition name="fade-opacity-1">
            <main-paragraph v-if="activeParagraph" :class="{'eng' : activeEng}">
              {{ $t('innovationScreen.mainParagraph') }}
            </main-paragraph>
          </transition>
        </div>

        <transition name="fade-opacity-1">
          <button-arrow v-if="activeArrow && !activeMobile"
                        @click="routToHome"
                        :direction-active="'left'"
                        :active-text="$t('innovationScreen.buttonReturn')"/>
        </transition>

        <div class="bottom-info-content">
          <text-with-circle v-for="(item, index) in $tm('innovationScreen.cardInfo')"
                            :active-index-status="activeMobile"
                            @click="changeContent(item.id)"
                            :class="{'is-visible' : isVisible(item.id)}"
              :active-index="activeIndex"
              :item-info="item">
            <main-paragraph :class="{'active' : index === activeIndex}">
              {{ item.subTitle }}
            </main-paragraph>
          </text-with-circle>
        </div>
      </div>


      <transition name="fade-translate-y-card">
        <div class="right-info-content" v-if="activeCard">
          <transition name="fade-translate-x" mode="out-in">
            <card-innovation :key="activeInfoInnovation"
                             :info-card="activeInfoInnovation"/>
          </transition>

        </div>
      </transition>

    </div>

  </div>
  </transition>
</template>

<script>
import CardInnovation from "@/components/entities/cards/CardInnovation.vue";
import MainTitle from "@/components/shared/text/MainTitle.vue";
import MainParagraph from "@/components/shared/card-text/MainParagraph.vue";
import AnimationText from "@/components/entities/AnimationText.vue";
import TextWithCircle from "@/components/shared/TextWithCircle.vue";
import ButtonArrow from "@/components/shared/buttons/ButtonArrow.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";

export default {
  name: "InnovationScreen",
  components: {ButtonTitleMobile, ButtonArrow, TextWithCircle, AnimationText, MainParagraph, MainTitle, CardInnovation},
  data() {
    return {
      typeInnovation: undefined,
      activeParagraph: false,
      activeItem: -1,
      activeCard: false,
      activeArrow: false,
      activeScreen: false,
      activeTitle: false
    }
  },
  mounted() {
    this.activeTitle = true
    setTimeout(() => {
      this.activeScreen = true
      if(this.activeMobile){
        setTimeout(() => {
          this.endAnimation();
        }, 300)
      }
    }, 500)
  },
  created() {
    this.typeInnovation = this.$route.params.typeInnovation
    this.$watch(
        () => this.$route.params,
        (toParams) => {
          this.typeInnovation = toParams?.typeInnovation
        }
    )
  },
  computed: {

    activeInfoInnovation() {
      return this.$tm('innovationScreen.cardInfo')[this.activeIndex]
    },
    activeIndex() {
        switch (this.typeInnovation) {
          case 'sop':
            return 0;
          case 'zet':
            return 1;
          case 'vit':
            return 2;
        }
    },
  },
  methods: {
    routToHome() {
      if(!this.activeMobile) {
        this.$router.push({name: 'home-navigation-page'});
      } else {
        this.$router.push({name: 'home-logo-page'});
      }
    },

    endAnimation() {
      this.activeParagraph = true
      setTimeout(() => {
        this.activeArrow = true
        this.showNextItem();
      }, 250)
    },

    showNextItem() {
      if (this.activeItem < this.$tm('innovationScreen.cardInfo').length) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 100)
      } else {
        this.activeCard = true
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },

    changeContent(id) {
      this.changeInnovationPageSlide(id)
    }
  }
}
</script>

<style scoped lang="scss">
  .innovation-screen-container {
    --main-padding-top: 0;
      .innovation-screen-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        height: 100%;
        .left-info-content {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          .top-info-content {
            display: flex;
            flex-direction: column;

            .card-description-content {
              line-height: 150%;
            }

            .main-title-container {
              font-weight: 400;
            }
          }

          .bottom-info-content {
            display: flex;
            flex-direction: column;

            .title-with-circle-container {
              opacity: 0;
              transition: opacity 1s ease;

              &.is-visible {
                opacity: 1;
              }
            }
          }
        }

      }
  }

  @media (max-width: 3024px){
    .innovation-screen-container {
      padding-right: 160px;
      --main-padding-bottom: 128px;
      .innovation-screen-content {
        padding-left: 64px;
        .left-info-content {
          padding-top: 235px;
          .top-info-content {
            row-gap: 48px;
            .main-title-container {
              max-width: 600px;
            }
          }
          .bottom-info-content {
            row-gap: 64px;
          }
        }
      }
    }
  }


  @media (max-width: 2100px) {
    .innovation-screen-container {
      padding-right: 128px;
      --main-padding-bottom: 96px;

      .innovation-screen-content {
        padding-left: 64px;
        .left-info-content {
          padding-top: 165px;
          .top-info-content {
            row-gap: 40px;
            .main-title-container {
              max-width: 525px;
            }
          }
          .bottom-info-content {
            row-gap: 48px;
          }
        }
      }
    }
  }

  @media (max-width: 1750px) {
    .innovation-screen-container {
      padding-right: 80px;
      --main-padding-bottom: 64px;
      .innovation-screen-content {
        padding-left: 24px;
        .left-info-content {
          padding-top: 140px;
          .top-info-content {
            row-gap: 32px;
            .main-title-container {
              max-width: 350px;
            }
          }
          .bottom-info-content {
            row-gap: 32px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .innovation-screen-container {
      padding-right: 64px;
      --main-padding-bottom: 48px;
      .innovation-screen-content {
        padding-left: 24px;
        .left-info-content {
          padding-top: 118px;
          .top-info-content {
            row-gap: 20px;
            .main-title-container {
              max-width: 320px;
            }
          }
          .bottom-info-content {
            row-gap: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .innovation-screen-container {
      padding-right: 80px;
      --main-padding-bottom: 64px;
      .innovation-screen-content {
        padding-left: 24px;
        .left-info-content {
          padding-top: 140px;
          .top-info-content {
            row-gap: 32px;
            .main-title-container {
              max-width: 350px;
            }
          }
          .bottom-info-content {
            row-gap: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .innovation-screen-container {
      padding-right: 64px;
      --main-padding-bottom: 48px;
      .innovation-screen-content {
        padding-left: 24px;
        .left-info-content {
          padding-top: 118px;
          .top-info-content {
            row-gap: 20px;
            .main-title-container {
              max-width: 320px;
            }
          }
          .bottom-info-content {
            row-gap: 24px;
          }
        }
      }
    }
  }
  @media (max-width: 769px) {

    .innovation-screen-container {
      padding: var(--burger-height) 0 0;


      .main-title-container {
        padding-left: var(--controller-padding);
      }

      .innovation-screen-content {
        padding: 0;
        flex-direction: column;

        .left-info-content {
          padding: 0 var(--controller-padding);
          justify-content: flex-start;
          row-gap: 24px;
          .top-info-content {
            row-gap: 20px;

            .card-description-content {
              font-size: 12px;
              line-height: 130%;
            }

            .main-title-container {
              max-width: 320px;
            }
          }

          .bottom-info-content {
            row-gap: 12px;

            .card-description-content {
              font-size: 12px;
              font-weight: 200;
              opacity: .3;
              color: var(--color-main-title) !important;

              &.active {
                opacity: 1;
              }
            }
          }
        }

        .right-info-content {
          max-height: 348px;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .innovation-screen-container {
      .innovation-screen-content {
        .left-info-content {
          row-gap: 24px;
          .top-info-content {
            row-gap: 20px;

            .card-description-content {
              font-size: 12px;
            }

            .main-title-container {
              max-width: 320px;
            }
          }
          .bottom-info-content {
            row-gap: 12px;

            .card-description-content {
              font-size: 12px;
            }
          }
        }

        .right-info-content {
          max-height: 350px;
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .innovation-screen-container {
      .innovation-screen-content {
        .left-info-content {
          row-gap: 16px;
          .top-info-content {
            row-gap: 20px;

            .card-description-content {
              font-size: 11px;
            }

            .main-title-container {
              max-width: 320px;
            }
          }
          .bottom-info-content {
            row-gap: 12px;

            .card-description-content {
              font-size: 11px;
            }
          }
        }

        .right-info-content {
          max-height: 285px;
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .innovation-screen-container {
      .innovation-screen-content {
        .left-info-content {
          row-gap: 24px;
          .top-info-content {
            row-gap: 20px;

            .card-description-content {
              font-size: 12px;
            }

            .main-title-container {
              max-width: 320px;
            }
          }
          .bottom-info-content {
            row-gap: 12px;

            .card-description-content {
              font-size: 12px;
            }
          }
        }

        .right-info-content {
          max-height: 290px;
        }
      }
    }
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .innovation-screen-container {
      padding-right: 256px;
      --main-padding-bottom: 256px;
      .innovation-screen-content {
        padding-left: 64px;
        .left-info-content {
          padding-top: 410px;
          .top-info-content {
            row-gap: 84px;
            .main-title-container {
              max-width: 1080px;
            }
          }
          .bottom-info-content {
            row-gap: 84px;
          }
        }
      }
    }
  }

</style>