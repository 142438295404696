<template>
    <p class="main-title-container">
         <span v-for="(item, index) in sliceText" :key="index" :class="['main-title-content',{'eng' : activeEng && item !== activeColorText, 'is-active-text': isVisible(index)}]">
           <span :class="{'active-text' : activeColorText === item && activeColorState}" >
              {{ item }}
           </span>
        </span>
    </p>
</template>

<script>
export default {
  name: "AnimationText",
  props: {
    textInfo: {
      type: String,
      required: true
    },
    activeColorText: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.showNextItem();
  },
  data() {
    return {
      activeItem: -1,
      activeColorState: false
    }
  },
  computed: {
    sliceText() {
      return this.textInfo.split(/(\s+)/);
    }
  },
  methods: {
    showNextItem() {
      if (this.activeItem < this.sliceText.length) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 100)
      } else {
        this.activeColorState = true
        this.$emit('endAnimation')
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
.main-title-container {
    color: var(--color-title-footer);
  font-weight: 600;
  .main-title-content {
    opacity: 0;
    transition: all 1.5s ease;
    &.is-active-text {
      opacity: 1;
    }
  }
  & span {
    & span {
      transition: color 1.5s ease;
      &.active-text {
        color: var(--color-main-visioners)
      }
    }

  }


}

@media (max-width: 3024px){
  .main-title-container {
    font-size: 56px;
  }
}



@media (max-width: 2100px) {
  .main-title-container {
    font-size: 48px;
  }
}

@media (max-width: 1750px) {
  .main-title-container {
    font-size: 32px;
  }
}
@media (max-height: 800px) {
  .main-title-container {
    font-size: 28px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-title-container {
    font-size: 32px;
  }
}

@media (max-width: 1133px) {
  .main-title-container {
    font-size: 28px;
  }
}
@media (max-width: 769px) {
  .main-title-container {
    font-size: 13px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .main-title-container {
    font-size: 14px;
  }
}

@media (max-width: 375px){
  .main-title-container {
    font-size: 12px;
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .main-title-container {
    font-size: 13px;
  }
}


@media (min-width: 3025px) {
  .main-title-container {
    font-size: 96px;
  }
}

</style>