<template>
  <div class="img-logo-container">
    <transition name="fade-opacity-lazy">
      <div class="img-logo-content" v-if="focusParam">
        <img v-if="activeColor" :src="getUrlLogoBrand(activeLogo + '-dark.svg')" alt="icon">
        <img v-else :src="getUrlLogoBrand(activeLogo + '-light.svg')" alt="icon">
      </div>
      <div class="img-logo-content" v-else>
        <img v-if="activeColor" :src="getUrlLogoBrand(activeLogo + '-grey-dark.svg')" alt="icon">
        <img v-else :src="getUrlLogoBrand(activeLogo + '-grey-light.svg')" alt="icon">
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "LogoBrandContainer",
  props: {
    activeLogo: {
      type: String,
      required: true
    },
    focusParam: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
    .img-logo-container {
      position: relative;
      width: fit-content;

      .img-logo-content {
        width: inherit;
        height: inherit;
        & img {
          width: inherit;
          height: inherit;
        }
      }
    }

@media (max-width: 3024px) {
      .img-logo-container {
        height: 40px;
      }
    }




@media (max-width: 2100px) {
      .img-logo-container {
        height: 32px;
      }
    }


@media (max-width: 1750px) {

      .img-logo-container {
        max-width: 135px;
        width: 100%;
        height: 28px;

        & img {
          object-fit: contain;
        }
      }
    }
@media (max-height: 800px) {

      .img-logo-container {
        max-width: 115px;
        height: 24px;
      }
    }

@media (max-width: 1400px) and (min-height: 801px) {

  .img-logo-container {
    max-width: 135px;
    height: 28px;
  }
}

@media (max-width: 1133px) {
      .img-logo-container {
        max-width: 115px;
        height: 24px;
      }
    }
@media (max-width: 769px) {
  .img-logo-container {
    max-width: 90px;
    height: 18px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .img-logo-container {
    max-width: 116px;
    height: 24px;
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .img-logo-container {
    max-width: 80px;
    height: 15px;
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .img-logo-container {
    max-width: 90px;
    height: 18px;
  }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {

  .img-logo-container {
    height: 70px;
  }
}
</style>