<template>
  <transition name="fade-opacity-lazy" mode="out-in">
    <div class="structure-screen-container main-container" v-if="!activeMobile || activeContentMobile">

      <button-title-mobile v-if="activeMobile"
                           @returnTo="changeContent"
                           :main-title="$tm('breadCrumb.dynamicTitle.division.staticTitle')"/>

      <card-text-info :paragraph-info="$tm('divisionScreen.subParagraph')"
                      :active-static-title="!activeMobile"
                      @endAnimation="endAnimationText"
                      :main-title="$t('divisionScreen.mainTitle')"/>

      <div class="right-info-container">
        <div class="card-division-container" v-for="item in $tm('divisionScreen.buttonInfo')" :key="item.id">
          <transition name="fade-opacity-1">
            <card-info class="division-card"
                       @click="routTo(item.id)"
                       v-show="isVisible(item.id)"
                       :active-division="true"
                       :info-card="item"/>
          </transition>
        </div>
      </div>
    </div>

    <group-screen v-else :active-index="1" @changeContent="changeContent"/>
  </transition>

</template>

<script>
import MainController from "@/components/widgets/MainController.vue";
import CardInfo from "@/components/entities/cards/CardInfo.vue";
import MainTitle from "@/components/shared/text/MainTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import CardTextInfo from "@/components/entities/cards/CardTextInfo.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
import GroupScreen from "@/components/features/GroupScreen.vue";

export default {
  name: "StructureScreen",
  components: {
    GroupScreen,
    ButtonTitleMobile,
    CardTextInfo,
    MainParagraph,
    MainTitle, CardInfo, MainController},

  data() {
    return {
      mainImg: 'structure-frame-all.svg',
      partOne: 'structure-frame-one.svg',
      partTwo: 'structure-frame-two.svg',
      partThree: 'structure-frame-three.svg',
      currentItem: -1,
      sliceElem: 0,
      activeFocus: false,
      activeContentMobile: false
    }
  },

  watch: {
    currentItem() {
      if(this.currentItem === this.infoDivision.length) {
        this.activeFocus = true
      }
    },

    activeRoute() {
      if(this.activeMobile && !this.activeRoute.includes('division')) {
        this.activeContentMobile = false
        this.$emit('changeActiveContentRout', this.activeContentMobile)
        if(!this.activeContentMobile) {
          this.currentItem = -1
        }
      }
    }
  },
  mounted() {
      if(!this.activeMobile) {
        this.showNextItem(1000);
      } else {
        if(this.lastRout?.name?.includes('type-division')) {
          this.changeContent();
        }
      }
  },

  computed: {

    activeRoute() {
      return this.$route.name
    },
    infoDivision() {
      return this.$tm('structureScreen.infoDivision')
    },
    lastRout() {
      return this.$store.state.lastRout;
    }
  },

  methods: {

    changeContent() {
      this.activeContentMobile = !this.activeContentMobile
      this.$emit('changeActiveContent', this.activeContentMobile)
      if(!this.activeContentMobile) {
        this.currentItem = -1
      }
    },

    routTo(type) {
      switch (type) {
        case 0:
          this.$router.push({name: 'main-type-division-page', params: {typeDivision: 'project'}})
          break;
        case 1:
          this.$router.push({name: 'main-type-division-page', params: {typeDivision: 'consulting'}})
          break;
        case 2:
          this.$router.push({name: 'main-type-division-page', params: {typeDivision: 'engineering'}})
          break;
      }
    },

    showNextItem(time) {
      if (this.currentItem < 3) {
        setTimeout(() => {
          this.currentItem++
          this.showNextItem(time)
        }, time)
      }
    },

    endAnimationText() {
      if(this.activeMobile) {
        this.showNextItem(500);
      }
    },

    isVisible(index) {
      return index <= this.currentItem
    },
  }
}
</script>

<style scoped lang="scss">


.structure-screen-container {
  flex-direction: row;
  align-items: center;
  --main-padding-bottom: 0;
  .left-info-container {
    display:  inherit;
    width: 100%;
    flex-direction: column;
    white-space: pre-wrap;

    .sub-paragraph-container {
      display: flex;
      flex-direction: column;
      text-align: justify;
    }
  }

  .right-info-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .card-division-container {
      width: 100%;
      flex-basis: 33.33%;
    }
  }
}

@media (max-width: 3024px){
  .structure-screen-container {
    .left-info-container {
      height: 544px;
      max-width: 940px;
      padding: 0 128px;
      row-gap: 48px;

      .sub-paragraph-container {
        row-gap: 32px;
      }
    }

    .right-info-container {
      max-width: 1020px;
    }
  }
}

@media (max-width: 2240px) {
  .structure-screen-container {
    .left-info-container {
      height: 430px;
      max-width: 868px;
      padding: 0 128px;
      row-gap: 40px;

      .sub-paragraph-container {
        row-gap: 30px;
      }
    }

    .right-info-container {
      max-width: 820px;
    }
  }
}


@media (max-width: 2100px) {
  .structure-screen-container {
    .left-info-container {
      height: 430px;
      max-width: 868px;
      padding: 0 128px;
      row-gap: 40px;

      .sub-paragraph-container {
        row-gap: 32px;
      }
    }

    .right-info-container {
      max-width: 820px;
    }
  }
}

@media (max-width: 1750px) {
  .structure-screen-container {
    .left-info-container {
      height: 430px;
      max-width: 568px;
      padding: 0 96px;
      row-gap: 32px;

      .sub-paragraph-container {
        row-gap: 24px;
      }
    }

    .right-info-container {
      max-width: 620px;
    }
  }
}
@media (max-height: 800px) {
  .structure-screen-container {
    .left-info-container {
      height: 250px;
      max-width: 476px;
      padding: 0 64px;
      row-gap: 20px;

      .sub-paragraph-container {
        row-gap: 16px;
      }
    }

    .right-info-container {
      max-width: 442px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .structure-screen-container {
    .left-info-container {
      height: 430px;
      max-width: 568px;
      padding: 0 96px;
      row-gap: 32px;

      .sub-paragraph-container {
        row-gap: 24px;
      }
    }

    .right-info-container {
      max-width: 620px;
    }
  }
}

@media (max-width: 1133px) {
  .structure-screen-container {
    .left-info-container {
      height: 250px;
      max-width: 476px;
      padding: 0 64px;
      row-gap: 20px;

      .sub-paragraph-container {
        row-gap: 16px;
      }
    }

    .right-info-container {
      max-width: 442px;
    }
  }
}
@media (max-width: 769px) {
  .structure-screen-container {
    flex-direction: column;
    --controller-padding: 0;

    .main-title-container {
      padding-left: 14px;
    }

    .card-text-container {
      justify-content: flex-start;
      padding-top: 16px;
      max-height: 252px;
      max-width: 100%;
    }

    .right-info-container {
      max-width: 100%;
      .card-division-container {
        margin-bottom: 14px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .structure-screen-container {
    flex-direction: column;
    --controller-padding: 0;

    .main-title-container {
      padding-left: 16px;
    }

    .card-text-container {
      justify-content: flex-start;
      padding-top: 16px;
      max-height: 252px;
      max-width: 100%;
    }

    .right-info-container {
      max-width: 100%;
      .card-division-container {
        margin-bottom: 16px;
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .structure-screen-container {
    flex-direction: column;
    --controller-padding: 0;

    .main-title-container {
      padding-left: 14px;
    }

    .card-text-container {
      justify-content: flex-start;
      padding-top: 0;
      max-height: 252px;
      max-width: 100%;
    }

    .right-info-container {
      max-width: 100%;
      max-height: 154px;
      .card-division-container {
        margin-bottom: 14px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .structure-screen-container {
    flex-direction: column;
    --controller-padding: 0;

    .main-title-container {
      padding-left: 14px;
    }

    .card-text-container {
      justify-content: flex-start;
      padding-top: 16px;
      max-height: 252px;
      max-width: 100%;
    }

    .right-info-container {
      max-width: 100%;
      .card-division-container {
        margin-bottom: 14px;
      }
    }
  }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .structure-screen-container {
    .left-info-container {
      height: 920px;
      max-width: 1790px;
      padding: 0 160px;
      row-gap: 84px;

      .sub-paragraph-container {
        row-gap: 64px;
      }
    }

    .right-info-container {
      max-width: 1420px;

      .card-info-container {
        padding: 0 120px;
      }
    }
  }
}

</style>