<template>
  <div class="footer-screen-container" v-if="!activeMobile">
    <transition name="fade-opacity-lazy">
      <modal-mistakes v-if="activeModal" @closeModal="changeStateModal" />
    </transition>
    <div class="top-container" :key="activeEng">
      <transition name="fade-opacity-animation" mode="out-in">
      <div class="footer-text-content" :key="activeIndexSignature">
          <animation-text @endAnimation="endAnimationText" :active-color-text="'visioners'" :text-info="mainTile"/>
            <div class="sub-title-container">
              <p :key="activeIndexSignature" :class="{'eng' : activeEng, 'active-sub-title' : activeSubTitle}">
                {{ signatureContent.mainTitle }}
              </p>
            </div>
      </div>
      </transition>
      <transition name="fade-opacity-animation" mode="out-in">
        <div class="signature-container" v-if="activeSignature" :key="activeIndexSignature">
          <img v-if="activeColor" :src="getUrlFooter(signatureContent.signatureIcon + '-dark.svg')" alt="">
          <img v-else :src="getUrlFooter(signatureContent.signatureIcon + '-light.svg')" alt="">
        </div>
      </transition>
    </div>

    <transition name="fade-translate-y">
      <main-footer v-if="activeFooter" @changeStateModal="changeStateModal"/>
    </transition>
  </div>

  <footer-screen-mobile v-else/>
</template>

<script>
import MainFooter from "@/components/widgets/MainFooter.vue";
import AnimationText from "@/components/entities/AnimationText.vue";
import ModalMistakes from "@/components/entities/modal/ModalMistakes.vue";
import FooterScreenMobile from "@/components/features/FooterScreenMobile.vue";

export default {
  name: "FooterScreen",
  components: {FooterScreenMobile, ModalMistakes, AnimationText, MainFooter},
  data() {
    return {
      activeIndexSignature: 0,
      activeSubTitle: false,
      activeSignature: false,
      activeFooter: false,
      activeStartAnimation: false,
      activeModal: false
    }
  },
  mounted() {
    this.activeStartAnimation = true
    setTimeout(() => {
      this.activeFooter = true;
    }, 300)
  },
  computed: {
    signatureContent() {
      return this.$tm('mobileSignatureContent')[this.activeIndexSignature]
    },
    mainTile() {
      if(this.activeIndexSignature === 1) {
        return this.$t('footerScreen.mainTitleSecond')
      } else {
        return this.$t('footerScreen.mainTitle')
      }
    }
  },
  methods: {
    endAnimationText() {
      this.activeSubTitle = true
      this.activeAnimationSignature();
      setTimeout(() => {
        this.activeSignature = true
      }, 500)
    },

    activeAnimationSignature() {
      setTimeout(() => {
        if(this.activeIndexSignature === 0) {
          this.activeIndexSignature = this.activeIndexSignature + 1;
        } else {
          this.activeIndexSignature = 0
        }
      }, 7000)
    },

    changeStateModal() {
      this.activeModal = !this.activeModal;
    }
  }
}
</script>

<style scoped lang="scss">
  .footer-screen-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    height: var(--calc-height);

    .top-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .signature-container {
        height: 100%;
        width: 100%;

        & img {
          width: 100%;
          height: 100%;
        }
      }


      .footer-text-content {
        display: flex;
        flex-direction: column;

        .sub-title-container {
          text-align: justify;
          line-height: 150%;
          white-space: pre-wrap;

          p {
            opacity: 0;
            transition: all .5s ease;
            &.active-sub-title {
              opacity: 1;
            }
          }

        }
      }
    }

    .footer-container {
      position: relative;
      bottom: 0;
    }
  }

  @media (max-width: 3024px){
    .footer-screen-container {
      .top-container {
        padding: 0 160px;
        max-height: 811px;

        .signature-container {
          max-width: 408px;
          max-height: 370px;
        }


        .footer-text-content {
          row-gap: 32px;

          .main-title-container {
            max-width: 1319px;
          }

          .sub-title-container {
            font-size: 25px;
          }
        }
      }
    }
  }


  @media (max-width: 2100px) {
    .footer-screen-container {

      .top-container {
        padding: 0 128px;
        max-height: 520px;

        .signature-container {
          max-width: 320px;
          max-height: 290px;
        }


        .footer-text-content {
          row-gap: 24px;

          .main-title-container {
            max-width: 1300px;
          }

          .sub-title-container {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 1750px) {
    .footer-screen-container {

      .top-container {
        padding: 0 96px;
        max-height: 457px;

        .signature-container {
          max-width: 260px;
          max-height: 236px;
        }


        .footer-text-content {
          row-gap: 18px;

          .main-title-container {
            max-width: 754px;
          }

          .sub-title-container {
            font-size: 14px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .footer-screen-container {

      .top-container {
        padding: 0 64px;
        max-height: 367px;

        .signature-container {
          max-width: 240px;
          max-height: 216px;
        }


        .footer-text-content {
          row-gap: 16px;

          .main-title-container {
            max-width: 660px;
          }

          .sub-title-container {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .footer-screen-container {

      .top-container {
        padding: 0 96px;
        max-height: 457px;

        .signature-container {
          max-width: 260px;
          max-height: 236px;
        }


        .footer-text-content {
          row-gap: 18px;

          .main-title-container {
            max-width: 754px;
          }

          .sub-title-container {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .footer-screen-container {

      .top-container {
        padding: 0 64px;
        max-height: 367px;

        .signature-container {
          max-width: 240px;
          max-height: 216px;
        }


        .footer-text-content {
          row-gap: 16px;

          .main-title-container {
            max-width: 660px;
          }

          .sub-title-container {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media (max-width: 769px) {
    //iPhone 14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //iPhone Plus
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .footer-screen-container {

      .top-container {
        padding: 0 256px;
        max-height: 1377px;

        .signature-container {
          max-width: 662px;
          max-height: 600px;
        }


        .footer-text-content {
          row-gap: 48px;

          .main-title-container {
            max-width: 2260px;
          }

          .sub-title-container {
            font-size: 42px;
          }
        }
      }
    }
  }

</style>