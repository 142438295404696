<template>
<div class="card-info-container"
     :class="{'active-text' : activeText}"
     @mouseenter="focusTrue"
     @mouseleave="focusFalse">
  <div class="info-title-container">
    <text-with-icon v-if="activeDivision" :info-title="infoCard"/>
    <subbrand-icon v-else :focus-param="focusParam" :icon-text="infoCard"/>
    <icon-arrow :direction="'right'"/>
  </div>
  <p class="info-description-container" v-if="activeText">
    {{ infoCard.mainParagraph }}
  </p>
</div>
</template>

<script>
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import TextWithIcon from "@/components/shared/TextWithIcon.vue";
import IconArrow from "@/components/shared/icons/IconArrow.vue";
import SubbrandIcon from "@/components/shared/icons/SubbrandIcon.vue";

export default {
  name: "CardInfo",
  components: {SubbrandIcon, IconArrow, TextWithIcon, MainParagraph},
  props: {
    infoCard: {
      type: Object,
      required: true
    },
    activeText: {
      type: Boolean,
      default: true
    },
    activeDivision: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      focusParam: false
    }
  }
}
</script>

<style scoped lang="scss">
  .card-info-container {
    width: 100%;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    transition: all .8s ease;
    line-height: 150%;
    position: relative;
    height: 100% !important;

    &.active {
        background: var(--color-card-division);
    }

    .info-title-container {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .info-description-container {
      line-height: 150%;
      text-align: justify;
    }

    & a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
    }


    &:hover {
      background: var(--color-card-division);
    }
  }


  @media (max-width: 3024px){
    .card-info-container {
      max-width: 1020px;
      max-height: 454px;
      padding: 0 128px;
      row-gap: 36px;

      .info-description-container {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 2240px) {
    .card-info-container {
      max-width: 820px;
      max-height: 400px;
      padding: 0 96px;
      row-gap: 24px;

      .info-description-container {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 1750px) {
    .card-info-container {
      max-width: 620px;
      max-height: 282px;
      padding: 0 64px;
      row-gap: 18px;

      .info-description-container {
        font-size: 14px;
      }
    }
  }
  @media (max-height: 800px) {
    .card-info-container {
      max-width: 442px;
      max-height: 224px;
      padding: 0 48px;
      row-gap: 16px;

      .info-description-container {
        font-size: 10px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-info-container {
      max-width: 620px;
      max-height: 282px;
      padding: 0 64px;
      row-gap: 18px;

      .info-description-container {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1133px) {
    .card-info-container {
      max-width: 442px;
      max-height: 224px;
      padding: 0 48px;
      row-gap: 16px;

      .info-description-container {
        font-size: 10px;
      }
    }
  }
  @media (max-width: 769px) {
    .card-info-container {
      background-color: var(--color-button-cookie);
      max-width: 100%;
      max-height: 91px;
      padding: 0 14px;
      row-gap: 10px;

      .info-description-container {

        font-weight: 200;
        font-size: 10px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-info-container {
      max-height: 106px;
      padding: 0 16px;
      row-gap: 12px;

      .info-description-container {
        font-size: 12px;
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .card-info-container {
      background-color: var(--color-button-cookie);
      max-width: 100%;
      max-height: 42px;
      padding: 0 14px;
      row-gap: 12px;

      &.active-text {
        .info-description-container {
          display: block;
        }
      }

      .info-description-container {
        display: none;
        font-weight: 200;
        font-size: 10px;
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .card-info-container {
      background-color: var(--color-button-cookie);
      max-width: 100%;
      max-height: 91px;
      padding: 0 14px;
      row-gap: 10px;

      .info-description-container {
        display: block;
        font-weight: 200;
        font-size: 10px;
      }
    }
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .card-info-container {
      max-width: 1594px;
      max-height: 765px;
      padding: 0 120px;
      row-gap: 72px;

      .info-description-container {
        font-size: 32px;
      }
    }
  }


</style>