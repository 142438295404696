<template>
  <div class="card-hr-container" v-if="typeof infoCard !== 'string'"
       :class="{'not-active-mobile' : !activeCardMobile && activeMobile, 'active-mobile' : activeCardMobile}"
       @mouseenter="focusTrue" @mouseleave="focusFalse">
    <div class="top-info-content">
      <main-title>
        {{ infoCard.mainTitle }}
      </main-title>

      <div class="icon-with-text-container" :class="{'eng' : activeEng}">
        <div class="icon-with-text-content" v-for="item in smallTitleInfo" :key="item">
          <div class="img-container">
            <img :src="getUrlIcons(item.icon)" alt="icon">
          </div>
          <small-paragraph>
            {{ infoCard[item.name] }}
          </small-paragraph>
        </div>

      </div>

      <small-paragraph class="description-info-container">
        {{ infoCard.descriptionInfo }}
      </small-paragraph>
    </div>
    
    <div class="bottom-info-content">
      <logo-brand-container :active-logo="'look'"
                            :focus-param="activeLogo"/>

      <main-paragraph @click="openModal" v-if="!activeMobile"  :class="{'eng' : activeEng}">
        {{ $t('HRCenterScreen.buttonCV') }}
      </main-paragraph>


      <button-arrow v-else @click="openModal"
                    :direction-active="'right'"
                    :active-text="$t('HRCenterScreen.buttonCV')"/>
    </div>
  </div>
  <div class="card-hr-container active-text" v-else>
    <main-paragraph-empty>
      {{ infoCard }}
    </main-paragraph-empty>

    <div class="bottom-info-content">
      <main-paragraph @click="openModal"
                      v-if="!activeMobile"
                      :class="{'eng' : activeEng}">
        {{ $t('HRCenterScreen.buttonCV') }}
      </main-paragraph>

      <button-arrow v-else @click="openModal"
                    :direction-active="'right'"
                    :active-text="$t('HRCenterScreen.buttonCV')"/>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/card-text/MainTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import SmallParagraph from "@/components/shared/card-text/SmallParagraph.vue";

import MainParagraphEmpty from "@/components/shared/card-text/MainParagraph.vue";
import LogoBrandContainer from "@/components/entities/LogoBrandContainer.vue";
import ButtonArrow from "@/components/shared/buttons/ButtonArrow.vue";
export default {
  name: "CardHR",
  components: {ButtonArrow, LogoBrandContainer, SmallParagraph, MainParagraph, MainTitle, MainParagraphEmpty},
  props: {
    infoCard: {
      required: true
    },
    activeCardMobile: {
      type: Boolean
    }
  },
  data() {
    return {
      focusParam: false,
      smallTitleInfo: [
        {
          name: "dateInfo",
          icon: "calendar.svg"
        },
        {
          name: "timeInfo",
          icon: "time.svg"
        },
        {
          name: "modeInfo",
          icon: "location.svg"
        },
        {
          name: "salaryInfo",
          icon: "money.svg"
        }
      ]
    }
  },
  computed: {
    activeLogo() {
      if(this.activeMobile) {
        return true
      } else {
        return this.focusParam
      }
    }
  },
  methods: {
    openModal() {
      this.$emit('openModal')
    }
  }
}
</script>

<style scoped lang="scss">
  .card-hr-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0);
    border-style: solid;
    border-color: var(--color-card-division);
    transition: all .6s ease;

    &.not-active-mobile {
      transform: scale(95%);
      opacity: .4;
    }

    &.active-mobile {
      opacity: 1;
      transform: scale(1);
    }

    &.active-text {
      max-width: 100%;
      width: auto;
      text-align: center;

      .card-description-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .bottom-info-content {
        justify-content: flex-end;
      }
    }

    .top-info-content {
      display: flex;
      flex-direction: column;

      .icon-with-text-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .icon-with-text-content {
          display: flex;
          flex-direction: row;
          flex-basis: 50%;

          .img-container {
            width: 100%;
            height: 100%;

            & img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .bottom-info-content {
      margin-top: 64px;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .main-paragraph-container {
        opacity: .3;
        cursor: pointer;
        transition: opacity .6s ease;

        &:hover {
          opacity: 1;
        }
      }
    }


    &.active-text {
      background-color: var(--color-card-division);
    }

    &:hover {
      background-color: var(--color-card-division);
    }
  }


  @media (max-width: 3024px) {
    .card-hr-container {
      padding: 40px;
      border-radius: 24px;
      border-width: 8px;
      &.active-text {
        margin: 32px 64px;
      }

      .top-info-content {
        row-gap: 48px;
        .icon-with-text-container {
          row-gap: 12px;
          .icon-with-text-content {
            column-gap: 20px;

            .img-container {
              max-width: 40px;
              max-height: 40px;
            }
          }
        }
      }

    }
  }




  @media (max-width: 2100px) {
    .card-hr-container {
      padding: 30px;
      border-radius: 16px;
      border-width: 7px;

      &.active-text {
        margin: 24px 48px;
      }

      .top-info-content {
        row-gap: 32px;
        .icon-with-text-container {
          row-gap: 8px;
          .icon-with-text-content {
            column-gap: 16px;

            .img-container {
              max-width: 32px;
              max-height: 32px;
            }
          }
        }
      }

    }
  }


  @media (max-width: 1750px) {
    .card-hr-container {
      padding: 24px;
      border-radius: 12px;
      border-width: 6px;

      &.active-text {
        margin: 32px;
      }

      .top-info-content {
        row-gap: 24px;
        .icon-with-text-container {
          row-gap: 6px;
          .icon-with-text-content {
            column-gap: 14px;

            .img-container {
              max-width: 24px;
              max-height: 24px;
            }
          }
        }
      }

    }
  }
  @media (max-height: 800px) {
    .card-hr-container {
      padding: 16px;
      border-radius: 10px;
      border-width: 4px;

      &.active-text {
        margin: 24px;
      }

      .top-info-content {
        row-gap: 32px;
        .icon-with-text-container {
          row-gap: 5px;
          .icon-with-text-content {
            column-gap: 12px;

            .img-container {
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
      }

    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-hr-container {
      padding: 24px;
      border-radius: 12px;
      border-width: 6px;

      &.active-text {
        margin: 32px;
      }

      .top-info-content {
        row-gap: 24px;
        .icon-with-text-container {
          row-gap: 6px;
          .icon-with-text-content {
            column-gap: 14px;

            .img-container {
              max-width: 24px;
              max-height: 24px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .card-hr-container {
      padding: 16px;
      border-radius: 10px;
      border-width: 4px;

      &.active-text {
        margin: 24px;
      }

      .top-info-content {
        row-gap: 32px;
        .icon-with-text-container {
          row-gap: 5px;
          .icon-with-text-content {
            column-gap: 12px;

            .img-container {
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 769px) {
    .card-hr-container {
      background-color: var(--color-card-division);
      padding: 16px;
      border-radius: 10px;
      border-width: 4px;

      .bottom-info-content {
      margin-top: 0;
    }

      .description-info-container {
        color: var(--color-main-title)
      }

      .card-description-content {
        color: var(--color-main-title)
      }

      .sub-button-container {
        margin-right: 22px;
      }

      &.active-text {
        margin: 24px;
      }

      .top-info-content {
        row-gap: 32px;
        .icon-with-text-container {
          row-gap: 5px;
          .icon-with-text-content {
            column-gap: 12px;

            .img-container {
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-hr-container {
      padding: 16px;
      border-radius: 10px;
      border-width: 4px;

      .sub-button-container {
        margin-right: 22px;
      }

      &.active-text {
        margin: 24px;
      }

      .top-info-content {
        row-gap: 32px;
        .icon-with-text-container {
          row-gap: 5px;
          .icon-with-text-content {
            column-gap: 12px;
            .img-container {
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .card-hr-container {
      padding: 16px;
      border-radius: 10px;
      border-width: 4px;

      .sub-button-container {
        margin-right: 22px;
      }

      &.active-text {
        margin: 24px;
      }

      .top-info-content {
        row-gap: 16px;
        .icon-with-text-container {
          row-gap: 5px;
          .icon-with-text-content {
            column-gap: 12px;
            .img-container {
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .card-hr-container {
      padding: 16px;
      border-radius: 10px;
      border-width: 4px;

      .sub-button-container {
        margin-right: 22px;
      }

      &.active-text {
        margin: 24px;
      }

      .top-info-content {
        row-gap: 32px;
        .icon-with-text-container {
          row-gap: 5px;
          .icon-with-text-content {
            column-gap: 12px;
            .img-container {
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .card-hr-container {
      padding: 80px;
      border-radius: 32px;
      border-width: 10px;

      &.active-text {
        margin: 64px 160px;
      }

      .top-info-content {
        row-gap: 64px;
        .description-info-container {
          font-size: 32px;
        }
        .icon-with-text-container {
        row-gap: 24px;
        .icon-with-text-content {
          column-gap: 24px;

          .img-container {
            max-width: 48px;
            max-height: 48px;
          }
        }
      }
      }
    }
  }

</style>