<template>
  <p class="card-description-content">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainParagraph"
}
</script>

<style scoped lang="scss">
p {
  line-height: 120%;
  white-space: pre-wrap;
}
      .card-description-content {
        transition: all .6s ease;
        color: var(--color-card-title);
      }

@media (max-width: 3840px){
        .card-description-content {
          font-size: 48px;
        }
}

@media (max-width: 3024px){
        .card-description-content {
          font-size: 24px;
        }
}


@media (max-width: 2100px) {
        .card-description-content {
          font-size: 18px;
        }
      }

@media (max-width: 1750px) {
        .card-description-content {
          font-size: 16px;
        }
}
@media (max-height: 800px) {
        .card-description-content {
          font-size: 14px;
        }
}

@media (max-width: 1400px) and (min-height: 801px) {
        .card-description-content {
          font-size: 16px;
        }
}

@media (max-width: 1133px) {
        .card-description-content {
          font-size: 14px;
        }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  .card-description-content {
    font-size: 10px;
  }
}

@media (min-width: 3841px) {
        .card-description-content {
          font-size: 48px;
        }
}

</style>