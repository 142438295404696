<template>
  <div class="chat-block-container" @keyup="checkEnter">
    <transition name="fade-opacity-lazy" mode="out-in">
      <div class="chat-block-message-container" v-if="activeChat">
        <div class="chat-block-message-content">
          <!--        <transition-group name="fade-translate-x">-->
          <!--          <block-chat-message v-for="(item, index) in resultChat"-->
          <!--                              :infoMessage="item"-->
          <!--                              :key="index"/>-->
          <!--        </transition-group>-->

          <slider-scroll-vertical :active-info-length="resultChat.length">
            <swiper-slide v-for="(item, index) in resultChat" :virtual-index="index">
              <block-chat-message  :infoMessage="item"/>
            </swiper-slide>
          </slider-scroll-vertical>
        </div>
        <!--      <div class="swiper-scrollbar swiper-scrollbar-vertical">-->
        <!--        <div class="swiper-scrollbar-drag-custom"></div>-->
        <!--      </div>-->
      </div>
    </transition>


      <form class="chat-input-container">
        <input-main :length-text-area="200"
                    @changeTextInput="changeTextInput"
                    :submit-active="submitActive"
                    :name-block="'chat-input-container'"
                    :info="$tm('secretaryScreen.inputInfo')"/>
        <main-button :icon-active="'message'"
                     v-if="!activeMobile"
                     @click="submitInfo">
        </main-button>

        <button-with-icon v-else
                          @click="submitInfo"
                          :info-text="$t('modalHotline.submitInfo')"
                          :info-icon="'message.svg'"/>
      </form>
  </div>
</template>

<script>
import InputMain from "@/components/shared/input/InputMain.vue";
import BlockChatMessage from "@/components/entities/blocks/BlockChatMessage.vue";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon.vue";
import MainButton from "@/components/shared/MainButton.vue";
import SliderScroll from "@/components/entities/sliders/SliderScroll.vue";
import SliderScrollVertical from "@/components/entities/sliders/SliderScrollVertical.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import ButtonWithIcon from "@/components/shared/buttons/ButtonWithIcon.vue";

export default {
  name: "BlockChat",
  components: {
    ButtonWithIcon,
    MainParagraph, SliderScrollVertical, SliderScroll, MainButton, ButtonIcon, BlockChatMessage, InputMain},
  data() {
    return {
      testMessageAI: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
      resultChat: [
        // {
        //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        //   ai: false,
        // },
        // {
        //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        //   ai: true,
        // },
        // {
        //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        //   ai: false,
        // },
        // {
        //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        //   ai: true,
        // },
        // {
        //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        //   ai: false,
        // },
        // {
        //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        //   ai: true,
        // },
        // {
        //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        //   ai: false,
        // },
        // {
        //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        //   ai: true,
        // }
      ],
      submitActive: false,
      inputText: '',
      activeChat: false
    }
  },

  computed: {
    isValid() {
      return this.inputText.length
    }
  },

  methods: {
    changeTextInput(item) {
      this.inputText = item.trim();
    },
    checkEnter(e) {
      if(e.key === 'Enter') {
        this.submitInfo();
      }
    },

    changeStateChat() {
      if(this.resultChat.length > 0) {
        if(!this.activeMobile) {
          this.activeChat = true
        } else {
          setTimeout(() => {
            this.activeChat = true
          }, 500)
        }
      }
    },

    submitInfo() {
      if(this.isValid) {
        this.resultChat.push({
          text: this.inputText,
          ai: false
        });
        this.resultChat.push({
          text: this.testMessageAI,
          ai: true
        });
        this.submitActive = true
        this.changeStateChat();
        this.$nextTick(() => {
          this.submitActive = false
          this.$emit('startChat')
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

.swiper-slide {
  display: block !important;
}

  .chat-block-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    row-gap: 128px;
    .chat-block-message-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      row-gap: 8px;
      overflow: hidden;
      position: relative;
      .chat-block-message-content {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: 100%;
        height: 100%;
      }
    }

    .chat-input-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      column-gap: 64px;
      width: 100%;

      .input-container {
        width: 100%;
      }
    }
  }

@media (max-width: 3024px){
  .chat-block-container {
    row-gap: 48px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      column-gap: 48px;
    }
  }
}


@media (max-width: 2100px) {
  .chat-block-container {
    row-gap: 48px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      column-gap: 32px;
    }
  }
}


@media (max-width: 1750px) {
  .chat-block-container {
    row-gap: 32px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      column-gap: 24px;
    }
  }
}
@media (max-height: 800px) {
  .chat-block-container {
    row-gap: 32px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      column-gap: 18px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .chat-block-container {
    row-gap: 32px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      column-gap: 24px;
    }
  }
}

@media (max-width: 1133px) {
  .chat-block-container {
    row-gap: 32px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      column-gap: 18px;
    }
  }
}
@media (max-width: 769px) {
  .chat-block-container {
    position: relative;
    row-gap: 24px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      flex-direction: column;
      row-gap: 12px;

      .input-container {
        width: 100%;
        border-radius: 0;
        border-width: 0;
        max-height: 76px;
        min-height: 76px;
      }

      .sub-button-container {
        padding: 10px 20px;
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .chat-block-container {
    position: relative;
    row-gap: 8px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      flex-direction: column;
      row-gap: 8px;

      .input-container {
        width: 100%;
        border-radius: 0;
        border-width: 0;
        max-height: 76px;
        min-height: 76px;
      }

      .sub-button-container {
        padding: 10px 20px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .chat-block-container {
    position: relative;
    row-gap: 24px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      flex-direction: column;
      row-gap: 12px;

      .input-container {
        width: 100%;
        border-radius: 0;
        border-width: 0;
        max-height: 76px;
        min-height: 76px;
      }

      .sub-button-container {
        padding: 10px 20px;
      }
    }
  }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {

  .chat-block-container {
    row-gap: 128px;
    .chat-block-message-container {
      row-gap: 8px;
      .chat-block-message-content {
        row-gap: 8px;
      }
    }

    .chat-input-container {
      column-gap: 64px;
    }
  }
}

</style>