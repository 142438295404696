<template>
  <div class="logo-container" @click="routToHomePage">
    <img v-if="activeColor" :src="getUrlLogo('logo-dark.svg')" alt="logo">
    <img v-else :src="getUrlLogo('logo-light.svg')" alt="logo">
  </div>
</template>

<script>
export default {
  name: "BlockLogo",
  methods: {
    routToHomePage() {
      this.$emit('routToHomePage')
    }
  }
}
</script>

<style scoped lang="scss">
.logo-container {
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 3024px){
    .logo-container {
      max-width: 95px;
      max-height: 52px;
    }
}


@media (max-width: 2100px) {

    .logo-container {
      max-width: 73px;
      max-height: 40px;
    }
}


@media (max-width: 1750px) {
    .logo-container {
      max-width: 59px;
      max-height: 32px;
    }

}
@media (max-height: 800px) {

    .logo-container {
      max-width: 44px;
      max-height: 24px;
    }
}

@media (max-width: 1400px) and (min-height: 801px) {
    .logo-container {
      max-width: 59px;
      max-height: 32px;
    }
}

@media (max-width: 1133px) {
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
    .logo-container {
      max-width: 155px;
      max-height: 85px;
    }
}
</style>