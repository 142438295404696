<template>
  <div class="avatar-team-container" >
    <div class="image-container">
      <img class="profile-img"  :src="getUrlTeam(imgDivisioner)" alt="project-img"/>
      <transition name="fade-opacity-lazy">
        <img class="profile-img-background" v-show="!activeColor" :src="getUrlTeam(imgBackground)" alt="divisioner">
      </transition>
      <transition name="fade-opacity-lazy">
        <img class="profile-img-background" v-show="activeColor" :src="getUrlTeam(imgBackgroundHover)" alt="divisioner">
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvatarTeam",
  props: {
    imgDivisioner: {
      type: String || undefined,
      required: true
    }
  },
  data() {
    return {
      imgBackground: 'background.png',
      imgBackgroundHover: 'background-hover.png'
    }
  }
}
</script>

<style scoped lang="scss">
.avatar-team-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: var(--color-main-ash);

  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 0;
    max-width: inherit;
    max-height: inherit;
    min-width: inherit;
    min-height: inherit;

    .profile-img {
      max-width: inherit;
      max-height: inherit;
      min-width: inherit;
      min-height: inherit;
      width: 100%;
      height: 100%;
      bottom: -2%;
      position: relative;
      z-index: 1;
    }

    .profile-img-background {
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
    }

    .loader-img {
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      margin: auto;
      width: 50px;
      height: 50px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 3024px){
  .avatar-team-container {
    .image-container {
      .profile-img {
        padding-top: 57px;
      }
    }
  }
}

@media (max-width: 2100px) {
  .avatar-team-container {
    .image-container {
      .profile-img {
        padding-top: 48px;
      }
    }
  }
}

@media (max-width: 1750px) {
  .avatar-team-container {
    .image-container {
      .profile-img {
        padding-top: 26px;
      }
    }
  }
}
@media (max-height: 800px) {
  .avatar-team-container {
    .image-container {
      .profile-img {
        padding-top: 24px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .avatar-team-container {
    .image-container {
      .profile-img {
        padding-top: 26px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .avatar-team-container {
    .image-container {
      .profile-img {
        padding-top: 24px;
      }
    }
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .avatar-team-container {
    .image-container {
      .profile-img {
        padding-top: 95px;
      }
    }
  }
}

</style>