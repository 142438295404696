<template>
  <div class="burger-menu-content">
    <transition name="fade-translate-x" mode="out-in">
      <div class="burger-menu-info-content" v-if="!activeModal">
        <div class="button-main-container">
          <div class="button-main-content"
               @click="changeActiveModal(item.id)"
               :key="item.id"
               v-for="item in $tm('navigationScreen.navigationInfo')">
            <p class="text-info-container">
              {{ item.mainTitleMobile }}
            </p>
            <button-icon :focus-param="true" :infoIcon="'arrow-right.svg'"/>
          </div>
        </div>

        <div class="button-icon-container">
          <div class="button-icon-content"
               @click="changeActivePageButton(item.id)"
               :key="item.id"
               v-for="item in $tm('navigationScreen.buttonNavigationInfo')">
            <p class="text-info-container">
              {{ item.mainTitle }}
            </p>

            <button-icon :focus-param="true" :infoIcon="'navigation/' + item.icon"/>
          </div>
        </div>
      </div>

      <div class="burger-menu-info-content modal" v-else>
        <button-title-mobile :main-title="activeSubNavigation.mainTitleMobile"
                             @returnTo="activeModal = false"/>

        <div class="button-main-container">
          <div class="button-main-content"
               @click="changeActivePageMobile(activeSubNavigation.id, item.id)"
               :key="item.id"
               v-for="item in activeSubNavigation.navigation">
            <p class="text-info-container">
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
    </transition>


    <div class="controller-button-container">
      <toggle-theme name-check="main"/>
      <p class="controller-text-content" :class="{'eng' : activeEng}" @click="changeStateModalConsulting">
        {{ $t('buttonTextController') }}
      </p>
      <toggle-local/>
    </div>
  </div>
</template>

<script>
import ButtonIcon from "@/components/shared/buttons/ButtonIcon.vue";
import ToggleTheme from "@/components/shared/toggle/ToggleTheme.vue";
import ToggleLocal from "@/components/shared/toggle/ToggleLocal.vue";
import ButtonArrow from "@/components/shared/buttons/ButtonArrow.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
import {mapActions} from "vuex";

export default {
  name: "BurgerMenuContent",
  components: {ButtonTitleMobile, ButtonArrow, ToggleLocal, ToggleTheme, ButtonIcon},
  data() {
    return {
      activeIndexModal: 0,
      activeModal: false
    }
  },
  computed: {
    activeSubNavigation() {
      return this.$tm('navigationScreen.navigationInfo')[this.activeIndexModal]
    }
  },
  methods: {
    ...mapActions(['IS_ACTIVE_MODAL_LOGIN']),

    changeActivePageMobile(idPage, idScreen) {
      console.log(idPage, idScreen)
      if(idPage === 0) {
        this.changeActivePage(idPage, idScreen - 1)
      } else {
        this.changeActivePage(idPage, idScreen)
      }
    },

    changeActiveModal(id) {
      if(id !== 2) {
        this.activeModal = true
        this.activeIndexModal = id
      } else {
        this.changeActivePage(2, 0)
      }
    },
    changeStateModalConsulting() {
      this.$emit('changeStateModalConsulting')
    },

    changeStateModalLogin() {
      this.IS_ACTIVE_MODAL_LOGIN(true)
    }
  }
}
</script>

<style scoped lang="scss">

.icon-img-container {
  opacity: 1;
}
  .burger-menu-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 901;
    width: 100%;
    height: var(--calc-height);
    background-color: var(--color-background);
    padding: calc(var(--burger-height) + var(--padding-burger)) 0 var(--padding-controller-bottom);


    .burger-menu-info-content {
      position: revert;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      &.modal {
        justify-content: flex-start;

        .button-main-container {
          max-height: 100%;
        }
      }

      .button-main-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 100%;


        .button-main-content {
          display: flex;
          width: 100%;
          height: 100%;
          background-color: var(--color-button-cookie);
          justify-content: space-between;
          align-items: center;

          .text-info-container {
            text-transform: uppercase;
          }
        }
      }

      .button-icon-container {
        height: 100%;
        display: flex;
        width: 100%;
        flex-direction: column;

        .button-icon-content {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          justify-content: space-between;
          background-color: var(--color-button-cookie);
        }
      }
    }

    .controller-button-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .controller-text-content {
        font-weight: 200;
        color: var(--color-main-visioners);
      }

    }
  }

@media (max-width: 769px) {
  .burger-menu-content {
    row-gap: 24px;
    --padding-burger: 24px;
    --padding-controller-bottom: 24px;

    .burger-menu-info-content {
      row-gap: 24px;
      width: 100%;
      height: 100%;

      .main-title-container {
        padding: 0 14px;
      }

      &.modal {
        row-gap: 24px;
      }

      .button-main-container {
        row-gap: 3px;
        max-height: 245px;


        .button-main-content {
          padding: 0 24px 0 16px;

          .text-info-container {
            font-size: 18px;
          }
        }
      }

      .button-icon-container {
        row-gap: 2px;

        .button-icon-content {
          padding: 0 20px;

          .text-info-container {
            font-size: 12px;
          }
        }
      }
    }

    .controller-button-container {
      padding: 0 20px;

      .controller-text-content {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

  .burger-menu-content {
    row-gap: 24px;
    --padding-burger: 24px;
    --padding-controller-bottom: 24px;



    .burger-menu-info-content {
      width: 100%;
      height: 100%;

      .main-title-container {
        padding: 0 16px;
      }


      &.modal {
        row-gap: 24px;
      }

      .button-main-container {
        row-gap: 4px;
        max-height: 300px;


        .button-main-content {
          padding: 0 24px 0 16px;

          .text-info-container {
            font-size: 24px;
          }
        }
      }

      .button-icon-container {
        row-gap: 2px;

        .button-icon-content {
          padding: 0 20px;

          .text-info-container {
            font-size: 12px;
          }
        }
      }
    }

    .controller-button-container {
      padding: 0 24px;

      .controller-text-content {
        font-size: 12px;
      }
    }
  }
}


@media (max-width: 375px){
  .burger-menu-content {
    row-gap: 24px;
    --padding-burger: 24px;
    --padding-controller-bottom: 24px;


    .burger-menu-info-content {
      row-gap: 16px;
      width: 100%;
      height: 100%;

      &.modal {
        row-gap: 24px;
      }

      .button-main-container {
        row-gap: 3px;
        max-height: 245px;


        .button-main-content {
          padding: 0 24px 0 16px;

          .text-info-container {
            font-size: 18px;
          }
        }
      }

      .button-icon-container {
        row-gap: 2px;

        .button-icon-content {
          padding: 0 20px;

          .text-info-container {
            font-size: 12px;
          }
        }
      }
    }

    .controller-button-container {
      padding: 0 20px;

      .controller-text-content {
        opacity: 0;
        pointer-events: none;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 375px) and (min-height: 630px){
  .burger-menu-content {
    row-gap: 16px;
    --padding-burger: 16px;
    --padding-controller-bottom: 16px;


    .burger-menu-info-content {
      width: 100%;
      height: 100%;

      &.modal {
        row-gap: 16px;
      }

      .button-main-container {
        row-gap: 3px;
        max-height: 218px;


        .button-main-content {
          padding: 0 24px 0 16px;

          .text-info-container {
            font-size: 18px;
          }
        }
      }

      .button-icon-container {
        row-gap: 3px;

        .button-icon-content {
          padding: 0 20px;

          .text-info-container {
            font-size: 12px;
          }
        }
      }
    }

    .controller-button-container {
      padding: 0 20px;


      .controller-text-content {
        opacity: 1;
        pointer-events: visible;
      }
    }
  }
}

@media (max-width: 320px){

}
</style>