<template>
  <transition name="fade-opacity-lazy" mode="out-in">
    <div class="team-screen-container main-container" v-if="!activeMobile">
      <line-controller-container :line-controller="$tm('teamScreen.lineController')"
                                 @changeActiveLine="changeActiveLine"
                                 :active-screen="true"
                                 @endAnimation="endAnimation"
                                 :active-index-line="activeIndex"/>
      <slider-team :counter-page="counterInfo"
                   v-if="activeTeamSlider"
                   @blockNextSlide="blockNextSlide"
                   @blockPrevSlide="blockPrevSlide"
                   @changeSlide="changeActiveLine"
                   :activeLineChange="activeLineChange"
                   :active-index="activeIndex"
                   :length-info="this.filterInfo.length"
                   :team-info="filterInfo"/>
    </div>
    <div class="team-screen-container main-container" v-else-if="activeMobile && activeContentMobile">
      <transition name="fade-opacity-1">
        <button-title-mobile @returnTo="changeContent"
                             v-if="activeTitle"
                             :main-title="$t('teamScreen.mainTitleMobile')"/>
      </transition>

      <controller-title-block :info-controller="$tm('teamScreen.lineController')"
                              @changeActiveItem="changeActiveLine"
                              :active-index="activeIndex"/>
      <transition name="fade-opacity-lazy" mode="out-in">
        <slider-scroll-team-mobile :key="filterInfo" :active-info="filterInfo"/>
      </transition>
    </div>

    <group-screen v-else :active-index="2" @changeContent="changeContent"/>
  </transition>

</template>

<script>
import CardTeam from "@/components/entities/cards/CardTeam.vue";
import LineControllerContainer from "@/components/entities/LineControllerContainer.vue";
import SliderTeam from "@/components/entities/sliders/SliderTeam.vue";
import ControllerTitleBlock from "@/components/entities/ControllerTitleBlock.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
import SliderScrollTeamMobile from "@/components/entities/sliders/SliderScrollTeamMobile.vue";
import GroupScreen from "@/components/features/GroupScreen.vue";

export default {
  name: "TeamScreen",
  components: {
    GroupScreen,
    SliderScrollTeamMobile,
    ButtonTitleMobile, ControllerTitleBlock, SliderTeam, LineControllerContainer, CardTeam},
  created() {
    this.typeManagement = this.$route.params.typeManagement
    this.$watch(
        () => this.$route.params,
        (toParams) => {
          this.typeManagement = toParams?.typeManagement
        }
    )
  },
  data() {
    return {
      typeManagement: '',
      activeLineChange: false,
      activeTeamSlider: false,
      activeContentMobile: false,
      activeTitle: false
    }
  },
  mounted() {
    this.activeTitle = true
  },
  watch: {
    activeRoute() {
     if(this.activeMobile) {
       this.activeContentMobile = false
       this.$emit('changeActiveContentRout', this.activeContentMobile)
       if(!this.activeContentMobile) {
         this.currentItem = -1
       }
     }
    }
  },
  computed: {
    activeRoute() {
      return this.$route.name
    },
    teamInfo() {
      return this.$tm('teamScreen.teamInfo');
    },

    filterInfo() {
      switch (this.typeManagement) {
        case 'founders':
          return this.teamInfo.filter(item => item.type.find(item => item === 'founders'));
        case 'senior-partners':
          return this.teamInfo.filter(item => item.type.find(item => item === 'senior-partners'));
        case 'council':
          return this.teamInfo.filter(item => item.type.find(item => item === 'partner-council'));
        case 'leaders':
          return this.teamInfo.filter(item => item.type.find(item => item === 'leaders'));
      }
    },

    activeIndex() {
      switch (this.typeManagement) {
        case 'founders':
          return 0;
        case 'senior-partners':
          return 1;
        case 'council':
          return 2;
        case 'leaders':
          return 3;
      }
    },

    counterInfo() {
      return Math.ceil(this.filterInfo.length / 4);
    }
  },
  methods: {
    changeActiveLine(id, state) {
      switch (id) {
        case 0:
          this.$router.push({name: 'main-management-page', params: {typeManagement: 'founders'}})
          break;
        case 1:
          this.$router.push({name: 'main-management-page', params: {typeManagement: 'senior-partners'}})
          break;
        case 2:
          this.$router.push({name: 'main-management-page', params: {typeManagement: 'council'}})
          break;
        case 3:
          this.$router.push({name: 'main-management-page', params: {typeManagement: 'leaders'}})
          break;
      }
    },

    changeContent() {
      this.activeContentMobile = !this.activeContentMobile
      this.$emit('changeActiveContent', this.activeContentMobile)
    },


    endAnimation() {
      this.activeTeamSlider = true
    },

    blockNextSlide(state) {
      this.$emit('blockNextSlide', state)
    },
    blockPrevSlide(state) {
      this.$emit('blockPrevSlide', state)
    }
  }
}
</script>

<style scoped lang="scss">
.team-screen-container {
  .controller-container {

    flex-direction: column;
    align-items: center;
    row-gap: 16px;
  }

  .main-title-container {
    padding-left: 0;
  }
}

@media (max-width: 320px) {
  .team-screen-container {
    .controller-container {
      row-gap: 4px;
    }
  }
}


</style>