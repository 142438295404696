<template>
  <div class="card-rp-container"
       :class="{'not-active-mobile' : !activeCardMobile && activeMobile, 'active-mobile' : activeCardMobile}"
       @mouseenter="focusTrue"
       @mouseleave="focusFalse">
    <div class="top-info-content">
      <div class="title-info-content">
        <mini-paragraph>
          {{ infoCard.datePublish }}
        </mini-paragraph>

        <div class="view-info-container">
          <mini-paragraph>
<!--            {{ infoCard.view }}-->
            {{ viewRandom }}
          </mini-paragraph>

          <div class="img-container">
            <div class="img-content" v-if="activeColor">
              <transition name="fade-opacity-lazy">
                <img v-if="activeLogo" :src="getUrlIcons('view-dark.svg')" alt="view">
                <img v-else :src="getUrlIcons('view-grey-dark.svg')" alt="view">
              </transition>
            </div>
            <div class="img-content" v-else>
              <transition name="fade-opacity-lazy">
                <img v-if="activeLogo" :src="getUrlIcons('view-light.svg')" alt="view">
                <img v-else :src="getUrlIcons('view-grey-light.svg')" alt="view">
              </transition>
            </div>
          </div>
        </div>
      </div>

      <div class="text-info-content">
        <main-title>
          {{ infoCard.mainTitle }}
        </main-title>

        <small-paragraph>
          {{ infoCard.description }}
        </small-paragraph>
      </div>
    </div>



    <div class="bottom-info-content">
        <logo-brand-container :focus-param="activeLogo"
                              :active-logo="infoCard.typeLogo"/>

      <div class="right-info-content">
        <div class="href-info-container">
          <small-paragraph v-for="item in infoCard.href" :class="{'eng' : activeEng}">
            #{{ item }}
          </small-paragraph>
        </div>
        <div class="link-info-container">
          <div class="img-container" v-for="item in linkInfo" :key="item.id">
            <img v-if="activeColor" :src="getUrlIcons(item.icon + '-dark.svg')" alt="view">
            <img v-else :src="getUrlIcons(item.icon + '-light.svg')" alt="view">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBrandContainer from "@/components/entities/LogoBrandContainer.vue";
import MiniParagraph from "@/components/shared/card-text/MiniParagraph.vue";
import MainParagraph from "@/components/shared/card-text/MainParagraph.vue";
import SmallParagraph from "@/components/shared/card-text/SmallParagraph.vue";
import MainTitle from "@/components/shared/card-text/MainTitle.vue";

export default {
  name: "CardRP",
  components: {MainTitle, SmallParagraph, MainParagraph, MiniParagraph, LogoBrandContainer},
  props: {
    infoCard: {
      type: Object,
      required: true
    },
    activeCardMobile: {
      type: Boolean
    }
  },
  computed: {
    activeLogo() {
      if(this.activeMobile) {
        return true
      } else {
        return this.focusParam
      }
    }
  },
  mounted() {
    this.getRandomInt(100, 1000)
  },
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      this.viewRandom = Math.floor(Math.random() * (max - min) + min);
    }
  },
  data() {
    return {
      viewRandom: 0,
      focusParam: false,
      linkInfo: [
        {
          id: 0,
          icon: "telegram"
        },
        {
          id: 1,
          icon: "vk"
        },
        {
          id: 2,
          icon: "links"
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.img-container {

  .img-content {
    width: inherit;
    height: inherit;
  }
  & img {
    width: inherit;
    height: inherit;
  }
}


.card-rp-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0);
  border-style: solid;
  border-color: var(--color-card-division);
  transition: all .6s ease;

  &.not-active-mobile {
    transform: scale(95%);
    opacity: .4;
  }

  &.active-mobile {
    opacity: 1;
    transform: scale(1);
  }

  &:hover {
    .top-info-content {
      .text-info-content {

        .small-paragraph-container {
          color: var(--color-card-main-title) !important;
        }

        .main-title-container {
          color: var(--color-card-main-title) !important;
        }
      }
    }

    .bottom-info-content {
      .right-info-content {
        .href-info-container {
          .small-paragraph-container {
            color: var(--color-card-main-title) !important;
          }
        }
      }
    }
  }

  .top-info-content {
    display: flex;
    flex-direction: column;

    .title-info-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .view-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .mini-paragraph-container {
          line-height: normal;
        }
      }
    }

    .text-info-content {
      display: flex;
      flex-direction: column;

      .small-paragraph-container {
        transition: all .6s ease;
        color: var(--color-card-main-text) !important;
        font-weight: 200;
      }

      .main-title-container {
        color: var(--color-card-main-text) !important;
      }
    }
  }

  .bottom-info-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    .right-info-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .href-info-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .small-paragraph-container {
          white-space: nowrap;
          transition: all .6s ease;
          color: var(--color-card-main-text)
        }
      }

      .link-info-container {
        display: flex;
        flex-direction: row;

      }
    }
  }


  &:hover {
    background-color: var(--color-card-division);
  }
}


@media (max-width: 3024px) {

  .img-container {
    width: 32px;
    height: 32px;
  }

  .card-rp-container {
    padding: 40px;
    border-radius: 24px;
    border-width: 8px;

    .top-info-content {
      row-gap: 32px;

      .title-info-content {
        .view-info-container {
          column-gap: 14px;
        }
      }

      .text-info-content {
        row-gap: 24px;
      }

    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 24px;

        .link-info-container {
          column-gap: 10px;
        }
      }
    }
  }
}




@media (max-width: 2100px) {
  .img-container {
    width: 24px;
    height: 24px;
  }

  .card-rp-container {
    padding: 30px;
    border-radius: 16px;
    border-width: 7px;


    .top-info-content {
      row-gap: 24px;

      .title-info-content {
        .view-info-container {
          column-gap: 10px;
        }
      }

      .text-info-content {
        row-gap: 16px;
      }

    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 16px;

        .link-info-container {
          column-gap: 8px;
        }
      }
    }
  }
}


@media (max-width: 1750px) {
  .img-container {
    width: 20px;
    height: 20px;
  }

  .card-rp-container {
    padding: 24px;
    border-radius: 12px;
    border-width: 6px;

    .top-info-content {
      row-gap: 18px;

      .title-info-content {
        .view-info-container {
          column-gap: 10px;
        }
      }

      .text-info-content {
        row-gap: 14px;
      }
    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 14px;

        .link-info-container {
          column-gap: 6px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .img-container {
    width: 18px;
    height: 18px;
  }

  .card-rp-container {
    padding: 16px;
    border-radius: 10px;
    border-width: 4px;

    .top-info-content {
      row-gap: 16px;

      .title-info-content {
        .view-info-container {
          column-gap: 8px;
        }
      }

      .text-info-content {
        row-gap: 12px;
      }
    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 12px;

        .link-info-container {
          column-gap: 6px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .img-container {
    width: 20px;
    height: 20px;
  }

  .card-rp-container {
    padding: 24px;
    border-radius: 12px;
    border-width: 6px;

    .top-info-content {
      row-gap: 18px;

      .title-info-content {
        .view-info-container {
          column-gap: 10px;
        }
      }

      .text-info-content {
        row-gap: 14px;
      }
    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 14px;

        .link-info-container {
          column-gap: 6px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .img-container {
    width: 18px;
    height: 18px;
  }

  .card-rp-container {
    padding: 16px;
    border-radius: 10px;
    border-width: 4px;

    .top-info-content {
      row-gap: 16px;

      .title-info-content {
        .view-info-container {
          column-gap: 8px;
        }
      }

      .text-info-content {
        row-gap: 12px;
      }
    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 12px;

        .link-info-container {
          column-gap: 6px;
        }
      }
    }
  }
}
@media (max-width: 769px) {
  .img-container {
    width: 18px;
    height: 18px;
  }

  .card-rp-container {
    background-color: var(--color-card-division);
    padding: 16px 14px;
    border-radius: 10px;
    border-width: 4px;

    .top-info-content {
      .text-info-content {

        .small-paragraph-container {
          color: var(--color-card-main-title) !important;
        }

        .main-title-container {
          color: var(--color-card-main-title) !important;
        }
      }
    }

    .bottom-info-content {
      .right-info-content {
        .href-info-container {
          .small-paragraph-container {
            color: var(--color-card-main-title) !important;
          }
        }
      }
    }

    .top-info-content {
      row-gap: 16px;

      .title-info-content {
        .view-info-container {
          column-gap: 8px;
        }
      }

      .text-info-content {
        row-gap: 12px;

        .main-title-container {
          font-size: 16px;
        }

        .small-paragraph-container {
          font-weight: 200;
        }
      }
    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 12px;

        .href-info-container {
          .small-paragraph-container {
            font-size: 10px;
          }
        }

        .link-info-container {
          column-gap: 6px;

          .img-container {
            width: 20px;
            height: 20px;
          }

        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .img-container {
    width: 18px;
    height: 18px;
  }

  .card-rp-container {
    padding: 16px;
    border-radius: 10px;
    border-width: 4px;

    .top-info-content {
      row-gap: 16px;

      .title-info-content {
        .view-info-container {
          column-gap: 8px;
        }
      }

      .text-info-content {
        row-gap: 12px;
      }
    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 12px;

        .link-info-container {
          column-gap: 6px;

          .img-container {
            width: 24px;
            height: 24px;
          }

        }
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .img-container {
    width: 18px;
    height: 18px;
  }

  .card-rp-container {
    padding: 8px 14px;
    border-radius: 10px;
    border-width: 4px;

    .top-info-content {
      row-gap: 8px;

      .title-info-content {
        .view-info-container {
          column-gap: 8px;
        }
      }

      .text-info-content {
        row-gap: 8px;
        .main-title-container {
          font-size: 12px;
        }

        .small-paragraph-container {
          font-size: 10px;
        }
      }
    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 8px;

        .link-info-container {
          column-gap: 6px;

          .img-container {
            width: 16px;
            height: 16px;
          }

        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .img-container {
    width: 18px;
    height: 18px;
  }

  .card-rp-container {
    padding: 14px;
    border-radius: 10px;
    border-width: 4px;

    .top-info-content {
      row-gap: 12px;

      .title-info-content {
        .view-info-container {
          column-gap: 8px;
        }
      }

      .text-info-content {
        row-gap: 12px;
        .main-title-container {
        font-size: 16px;
      }
        .small-paragraph-container {
          font-size: 12px;
        }
      }
    }


    .bottom-info-content {
      .right-info-content {
        row-gap: 12px;

        .link-info-container {
          column-gap: 6px;

          .img-container {
            width: 20px;
            height: 20px;
          }

        }
      }
    }
  }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {

  .img-container {
    width: 48px;
    height: 48px;
  }

  .card-rp-container {
    padding: 80px;
    border-radius: 32px;
    border-width: 10px;

    .top-info-content {
      row-gap: 32px;

      .title-info-content {
        .view-info-container {
          column-gap: 14px;
        }
      }

      .text-info-content {
        row-gap: 32px;
        .small-paragraph-container {
          font-size: 32px;
        }
      }
    }

    .bottom-info-content {
      .right-info-content {
        row-gap: 32px;

        .link-info-container {
          column-gap: 16px;
        }
      }
    }
  }
}
</style>