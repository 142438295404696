<template>
  <div class="card-innovation-info">
    <div class="top-info-content">
      <div class="title-info-container">
        <p class="sub-title-container" :class="{'eng' : activeEng}">
          {{ $t('innovationScreen.mainTitleCard') }}
        </p>
        <medium-title>
          {{ infoCard.mainTitle }}
        </medium-title>
      </div>

      <div class="description-info-container">
        <main-paragraph v-for="(item, index) in infoCard.description"
                        :class="{'active-animation' : isVisible(index + 2)}"
                        :key="index">
          {{ item }}
        </main-paragraph>
      </div>
    </div>

    <div class="bottom-info-content" :class="{'eng' : activeEng}">
      <p class="sub-title-container">
        {{ $t('innovationScreen.subTitleCard') }}
      </p>
      <main-paragraph>
        {{ infoCard.type }}
      </main-paragraph>
    </div>

  </div>
</template>

<script>
import MediumTitle from "@/components/shared/text/MediumTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import SubParagraph from "@/components/shared/text/SubParagraph.vue";

export default {
  name: "CardInnovation",
  components: {SubParagraph, MainParagraph, MediumTitle},
  props: {
    infoCard: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeItem: -1
    }
  },
  methods: {
    showNextItem() {
      if (this.activeItem < this.infoCard.description.length + 4) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 100)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">

.medium-title-container {
  color: var(--color-main-title);
}


  .card-innovation-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: var(--calc-height);
    background-color: var(--color-card-division);
    width: 100%;

    .top-info-content {
      display: flex;
      flex-direction: column;

      .description-info-container {
        display: flex;
        flex-direction: column;
      }
    }

    .bottom-info-content {
      display: flex;
      flex-direction: column;

      .main-paragraph-container {
        color: var(--color-main-title);
      }
    }
  }

@media (max-width: 3024px){
  .sub-title-container {
    font-size: 18px;
  }

  .card-innovation-info {
    max-width: 900px;
    padding: 200px 100px 100px;

    .top-info-content {
      row-gap: 112px;

      .description-info-container {
        row-gap: 38px;
      }
    }

    .bottom-info-content {
      row-gap: 18px;
    }
  }
}


@media (max-width: 2100px) {
    .sub-title-container {
      font-size: 16px;
    }

    .card-innovation-info {
      max-width: 740px;
      padding: 150px 100px 100px;

      .top-info-content {
        row-gap: 112px;

        .description-info-container {
          row-gap: 38px;
        }
      }

      .bottom-info-content {
        row-gap: 18px;
      }
    }
}

@media (max-width: 1750px) {
  .sub-title-container {
    font-size: 14px;
  }

  .card-innovation-info {
    max-width: 570px;
    padding: 100px 50px 50px;

    .top-info-content {
      row-gap: 112px;

      .description-info-container {
        row-gap: 38px;
      }
    }

    .bottom-info-content {
      row-gap: 18px;
    }
  }
}
@media (max-height: 800px) {
  .sub-title-container {
    font-size: 10px;
  }

  .card-innovation-info {
    max-width: 460px;
    padding: 50px;

    .top-info-content {
      row-gap: 112px;

      .description-info-container {
        row-gap: 38px;
      }
    }

    .bottom-info-content {
      row-gap: 18px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .sub-title-container {
    font-size: 14px;
  }

  .card-innovation-info {
    max-width: 570px;
    padding: 100px 50px 50px;

    .top-info-content {
      row-gap: 112px;

      .description-info-container {
        row-gap: 38px;
      }
    }

    .bottom-info-content {
      row-gap: 18px;
    }
  }
}

@media (max-width: 1133px) {
}
@media (max-width: 769px) {
  .sub-title-container {
    font-size: 10px;
  }

  .card-innovation-info {
    max-height: inherit;
    max-width: 100%;
    padding: 16px 14px;

    .medium-title-container {
      font-weight: 400;
      font-size: 26px;
    }

    .top-info-content {
      row-gap: 18px;

      .description-info-container {
        row-gap: 12px;
        .main-paragraph-container {
          line-height: 120%;
        }
      }
    }

    .bottom-info-content {
      row-gap: 12px;
    }
  }
}

@media (max-width: 769px) and (min-height: 720px) and (max-height: 1000px){

  .card-innovation-info {
    padding: 16px;

    .medium-title-container {
      font-size: 28px;
    }
  }
}


@media (max-width: 375px){
  .sub-title-container {
    font-size: 10px;
  }

  .card-innovation-info {

    .medium-title-container {
      font-size: 24px;
    }
    .top-info-content {
      row-gap: 4px;

      .main-paragraph-container {
        font-size: 11px;
      }

      .description-info-container {
        row-gap: 12px;
      }
    }

    .bottom-info-content {
      row-gap: 12px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .sub-title-container {
    font-size: 10px;
  }

  .card-innovation-info {

    .medium-title-container {
      font-size: 26px;
    }

    .top-info-content {
      row-gap: 0;

      .main-paragraph-container {
        font-size: 12px;
      }

      .description-info-container {
        row-gap: 12px;
      }
    }

    .bottom-info-content {
      row-gap: 12px;
    }
  }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .sub-title-container {
    font-size: 32px;
  }

  .card-innovation-info {
    max-width: 1500px;
    padding: 448px 82.5px 150px;

    .top-info-content {
      row-gap: 112px;

      .description-info-container {
        row-gap: 38px;
      }
    }

    .bottom-info-content {
      row-gap: 18px;
    }
  }
}

</style>