<template>
    <div class="card-values-container">
      <transition name="fade-translate-y" mode="out-in">
          <div class="top-info-container" :key="infoCard">
            <main-title>
              {{ infoCard?.mainTitle }}
            </main-title>
            <main-paragraph v-if="!activeMobile">
              {{ infoCard?.description }}
            </main-paragraph>
            <main-paragraph v-else>
              {{ infoCard?.descriptionMobile || infoCard?.description }}
            </main-paragraph>
          </div>
      </transition>
      <transition name="fade-translate-y" mode="out-in">
          <div class="img-container" :key="infoCard">
            <p class="copyright-text-content">
              {{ infoCard?.copyright }}
            </p>
            <img v-if="activeColor" :src="getUrlValues(infoCard?.img + '-dark.webp')" alt="img">
            <img v-else :src="getUrlValues(infoCard?.img + '-light.webp')" alt="img">
          </div>
      </transition>
    </div>
</template>

<script>
import MainTitle from "@/components/shared/text/MainTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";

export default {
  name: "CardValues",
  components: {MainParagraph, MainTitle},
  props: {
    infoCard: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
 .card-values-container {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   .top-info-container {
     display: flex;
     flex-direction: column;
     margin: 64px 0;

     .main-paragraph-container {
       white-space: pre-wrap;
     }
   }

   .img-container {
     align-self: flex-end;
     height: 100%;
     width: 100%;
     position: relative;
     overflow: hidden;

     & img {
       width: 100%;
       height: 100%;
     }

     .copyright-text-content {
       white-space: pre-wrap;
       position: absolute;
     }
   }
 }

 @media (max-width: 3024px){
   .card-values-container {
     max-height: 906px;

     .top-info-container {
       row-gap: 48px;

       .main-paragraph-container {
         font-size: 42px;
       }
     }

     .img-container {
       max-width: 1495px;
       max-height: 515px;
       border-radius: 200px 100px 50px 25px;

       .copyright-text-content {
         font-size: 14px;
         bottom: 40px;
         right: 40px;
       }
     }
   }
 }


 @media (max-width: 2100px) {
   .card-values-container {
     max-height: 743px;

     .top-info-container {
       row-gap: 40px;

       .main-paragraph-container {
         font-size: 32px;
       }
     }

     .img-container {
       max-width: 1400px;
       max-height: 482px;
       border-radius: 192px 96px 48px 24px;

       .copyright-text-content {
         font-size: 10px;
         bottom: 36px;
         right: 36px;
       }
     }
   }
 }


 @media (max-width: 1750px) {
   .card-values-container {
     max-height: 598px;

     .top-info-container {
       row-gap: 32px;

       .main-paragraph-container {
         font-size: 24px;
       }
     }

     .img-container {
       max-width: 1070px;
       max-height: 368px;
       border-radius: 142px 71px 35px 18px;

       .copyright-text-content {
         font-size: 8px;
         bottom: 28px;
         right: 28px;
       }
     }
   }
 }
 @media (max-height: 800px) {
   .card-values-container {
     max-height: 480px;

     .top-info-container {
       row-gap: 20px;

       .main-paragraph-container {
         font-size: 20px;
       }
     }

     .img-container {
       max-width: 847px;
       max-height: 292px;
       border-radius: 112px 56px 28px 14px;

       .copyright-text-content {
         font-size: 6px;
         bottom: 22px;
         right: 22px;
       }
     }
   }
 }

 @media (max-width: 1400px) and (min-height: 801px) {
   .card-values-container {
     max-height: 598px;

     .top-info-container {
       row-gap: 32px;

       .main-paragraph-container {
         font-size: 24px;
       }
     }

     .img-container {
       max-width: 1070px;
       max-height: 368px;
       border-radius: 142px 71px 35px 18px;

       .copyright-text-content {
         font-size: 8px;
         bottom: 28px;
         right: 28px;
       }
     }
   }
 }

 @media (max-width: 1133px) {
 }
 @media (max-width: 769px) {
   .card-values-container {
     max-height: 400px;

     .top-info-container {
       row-gap: 0;
       margin: 0;
       height: 100%;
       justify-content: space-between;

       .main-paragraph-container {
         font-size: 18px;
         white-space: normal;
       }
     }

     .img-container {
       margin-top: 32px;
       max-width: 100%;
       max-height: 200px;
       border-radius: 95.652px 22.767px 11.383px 5.692px;

       .copyright-text-content {
         font-size: 2.4px;
         bottom: 9px;
         right: 9px;
       }
     }
   }
 }

 @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
   .card-values-container {
     max-height: 442px;

     .top-info-container {
       .main-paragraph-container {
         font-size: 20px;
       }
     }

     .img-container {
       max-height: 230px;
       border-radius: 110px 26.182px 13.091px 6.545px;

       .copyright-text-content {
         font-size: 3.14px;
         bottom: 11px;
         right: 11px;
       }
     }
   }
 }
 @media (min-height: 600px) and (max-height: 630px){
   //для экранов 8 плюс
 }

 @media (max-width: 375px){
   .card-values-container {
     max-height: 335px;

     .top-info-container {
       .main-paragraph-container {
         font-size: 16px;
       }
     }

     .img-container {
       margin-top: 24px;
       max-height: 150px;
       border-radius: 110.038px 26.191px 13.095px 6.548px;

       .copyright-text-content {
         font-size: 2.8px;
         bottom: 10px;
         right: 10px;
       }
     }
   }
 }


 @media (max-width: 375px) and (min-height: 630px) {
   .card-values-container {
     max-height: 376px;

     .top-info-container {
       .main-paragraph-container {
         font-size: 18px;
       }
     }

     .img-container {
       margin-top: 24px;
       max-height: 200px;
       border-radius: 105.101px 25.016px 12.508px 6.254px;

       .copyright-text-content {
         font-size: 2.6px;
         bottom: 10px;
         right: 10px;
       }
     }
   }
 }


 @media (max-width: 320px) {
   .card-values-container {
     max-height: 250px;

     .top-info-container {
       .main-paragraph-container {
         font-size: 12px;
       }
     }

     .img-container {
       max-height: 150px;
       border-radius: 110.038px 26.191px 13.095px 6.548px;

       .copyright-text-content {
         font-size: 2.8px;
         bottom: 10px;
         right: 10px;
       }
     }
   }
 }

 @media (min-width: 3025px) {
   .card-values-container {
     max-height: 1578px;

     .top-info-container {
       margin: 233px 0 64px;
       row-gap: 84px;

       .main-paragraph-container {
         font-size: 52px;
       }
     }

     .img-container {
       max-width: 2421px;
       max-height: 834px;
       border-radius: 320px 160px 80px 40px;

       .copyright-text-content {
         font-size: 20px;
         bottom: 64px;
         right: 64px;
       }
     }
   }
 }

</style>