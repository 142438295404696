<template>
  <div class="main-division-screen main-container" v-if="!activeMobile">
    <div class="left-info-content">
      <transition name="fade-opacity-1">
      <div class="top-info-content" v-if="isVisible(0)">
        <main-title> {{ activeDivision.mainTitle }}
          <span class="circle-container"></span>
        </main-title>

        <div class="description-info-container">
          <main-paragraph v-for="(item, index) in activeDivision.description"
                          :key="index">
            {{ item }}
          </main-paragraph>
        </div>
      </div>
      </transition>

      <transition name="fade-opacity-1">
      <button-arrow @click="routToTechnologies"
                    v-if="isVisible(1)"
                    :direction-active="'right'"
                    :active-text="$t('divisionPage.technologiesButton')"/>
      </transition>

      <div class="bottom-info-content">
        <transition name="fade-opacity-1">
          <main-paragraph v-if="isVisible(3)">
          {{ $t('divisionPage.complexDivisionTitle') }}
          </main-paragraph>
        </transition>
        <div class="complex-title-container">
          <transition-group name="fade-opacity-1">
              <title-complex v-for="item in activeDivision.complexInfo"
                             v-show="isVisible(item.id + 3)"
                             @click="routToServices(item)"
                             :key="item.id"
                             :active-division="typeDivision"
                             :info-title="item"/>
          </transition-group>
        </div>
      </div>
    </div>
    <transition name="fade-opacity-1">
    <div class="right-info-content" v-if="isVisible(5)">
      <avatar-team :img-divisioner="activePerson.img"/>

      <div class="title-info-container">
        <sub-title>
            {{ activePerson.name }}
        </sub-title>
        <sub-paragraph :class="{'eng' : activeEng}">
          {{ activePerson.descriptionDivision }}
        </sub-paragraph>
      </div>
    </div>
    </transition>
  </div>

  <div class="main-division-screen main-container" v-else>
    <transition name="fade-opacity-1">
      <button-title-mobile v-if="isVisible(0)"
                           @returnTo="returnTo"
                           :main-title="activeDivision.mainTitle"
                           :active-circle="true"/>
    </transition>



        <div class="description-info-container">
          <transition name="fade-opacity-1">
          <div class="description-info-content" v-if="isVisible(1)">
            <main-paragraph v-for="(item, index) in activeDivision.descriptionMobile"
                            :key="index">
              {{ item }}
            </main-paragraph>
          </div>
          </transition>
        </div>


      <div class="button-container">
        <transition name="fade-opacity-1">
        <button-arrow @click="routToTechnologies"
                      :direction-active="'right'"
                      v-if="isVisible(2)"
                      :active-text="this.$t('divisionPage.consulting.technologiesInfo.mainTitle')"/>
        </transition>
      </div>

        <div class="main-profile-container">
          <transition name="fade-opacity-1">
          <avatar-team v-if="isVisible(3)" :img-divisioner="activePerson.img"/>
          </transition>
          <transition name="fade-opacity-1">
          <div class="title-info-container" v-if="isVisible(3)">
            <sub-title style="white-space: pre-wrap">
              {{ activePerson.nameMobile }}
            </sub-title>
            <sub-paragraph :class="{'eng' : activeEng}">
              {{ activePerson.description }}
            </sub-paragraph>
          </div>
          </transition>
        </div>



      <div class="bottom-info-content">
        <transition name="fade-opacity-1">
          <main-paragraph v-if="isVisible(4)">
            {{ $t('divisionPage.complexDivisionTitle') }}
          </main-paragraph>
        </transition>
        <div class="complex-title-container">
          <transition-group name="fade-opacity-1">
            <title-complex v-for="item in activeDivision.complexInfo"
                           v-show="isVisible(item.id + 4)"
                           @click="routToServices(item)"
                           :key="item.id"
                           :active-division="typeDivision"
                           :info-title="item"/>
          </transition-group>
        </div>
      </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/MainTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import TitleComplex from "@/components/entities/TitleComplex.vue";
import ButtonArrow from "@/components/shared/buttons/ButtonArrow.vue";
import AvatarTeam from "@/components/entities/AvatarTeam.vue";
import SubTitle from "@/components/shared/text/SubTitle.vue";
import SubParagraph from "@/components/shared/text/SubParagraph.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";

export default {
  name: "MainDivisionScreen",
  components: {
    ButtonTitleMobile,
    SubParagraph, SubTitle, AvatarTeam, ButtonArrow, TitleComplex, MainParagraph, MainTitle},
  props: ['typeDivision'],
  data() {
    return {
      currentItem: -1,
      timerFirst: null,
      timerSecond: null
    }
  },
  mounted() {
    if(this.activeMobile) {
        this.showNextItem();
    } else {
      this.timerFirst = setTimeout(() => {
        this.showNextItem();
      }, 250)
    }
  },
  unmounted() {
    clearTimeout(this.timerFirst);
    clearTimeout(this.timerSecond);
  },
  computed: {
    activeDivision() {
      return this.$tm('divisionPage')[this.typeDivision]
    },
    activePerson() {
      switch (this.typeDivision) {
        case 'project':
          return this.$tm('teamScreen.teamInfo').find(item => item.id === 2);
        case 'consulting':
          return this.$tm('teamScreen.teamInfo').find(item => item.id === 0);
        case 'engineering':
          return this.$tm('teamScreen.teamInfo').find(item => item.id === 4);
      }
    }
  },
  methods: {

    routToTechnologies() {
      this.$router.push({name: "main-type-division-technologies-page", params:
            {
              typeDivision: this.typeDivision,
              typeTechnologies: this.activeDivision.technologiesInfo.subInfo[0].url
            }
      })
    },

    returnTo() {
      this.$router.push({name: 'main-division-page'})
    },

    showNextItem() {
      let time;
      if(this.activeMobile) {
        time = 500;
      } else {
        time = 150;
      }
      this.timerSecond = setTimeout(() => {
        if (this.currentItem < this.activeDivision.complexInfo.length+5) {
          this.currentItem++
          this.showNextItem()
        }
      }, time)
    },
    isVisible(index) {
      return index <= this.currentItem
    },

    routToServices(item) {
      this.$router.push({name: "main-type-division-services-page", params:
            {
              typeDivision: this.typeDivision,
              typeServices: item.url,
              typeSubServices:  item.subInfo[0].url
            }
      })
    }
  }

}
</script>

<style scoped lang="scss">
.circle-container {
  position: absolute;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--color-main-visioners);
}

  .main-division-screen {
    flex-direction: row;
    .left-info-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .top-info-content {
        display: flex;
        flex-direction: column;
        .main-title-container {
          width: fit-content;
          position: relative;
        }

        .description-info-container {
          display: flex;
          flex-direction: column;
        }
      }

      .bottom-info-content {
        display: flex;
        flex-direction: column;

        .complex-title-container {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .right-info-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: space-between;

      .avatar-team-container {
        margin-bottom: 32px;
      }

      .title-info-container {
        display: flex;
        flex-direction: column;
      }
    }
  }

@media (max-width: 3024px){
  .circle-container {
    right: -16px;
    bottom: 13px;
    height: 10px;
    width: 10px;
  }

  .main-division-screen {
    .left-info-content {
      max-width: 828px;
      padding: 128px 0 0 64px;

      .top-info-content {
        row-gap: 48px;

        .description-info-container {
          row-gap: 32px;
        }
      }

      .bottom-info-content {
        row-gap: 36px;
        height: 301px;

        .complex-title-container {
          row-gap: 24px;
        }
      }
    }

    .right-info-content {
      padding: 64px 96px 0 0;
      max-width: 590px;
      .avatar-team-container {
        max-height: 780px;
        border-radius: 104px 52px 26px 13px;
      }

      .title-info-container {
        row-gap: 32px;
      }
    }
  }
}


@media (max-width: 2100px) {
  .circle-container {
    right: -12px;
    bottom: 12px;
    height: 8px;
    width: 8px;
  }

  .main-division-screen {
    .left-info-content {
      max-width: 678px;
      padding: 96px 0 0 48px;

      .top-info-content {
        row-gap: 40px;

        .description-info-container {
          row-gap: 30px;
        }
      }

      .bottom-info-content {
        row-gap: 30px;
        height: 245px;

        .complex-title-container {
          row-gap: 20px;
        }
      }
    }

    .right-info-content {
      padding: 72px 48px 0 0;
      max-width: 490px;
      .avatar-team-container {
        max-height: 660px;
        border-radius: 88px 44px 22px 11px;
      }

      .title-info-container {
        row-gap: 24px;
      }
    }
  }
}

@media (max-width: 1750px) {
  .circle-container {
    right: -9px;
    bottom: 8px;
    height: 6px;
    width: 6px;
  }

  .main-division-screen {
    .left-info-content {
      max-width: 520px;
      padding: 64px 0 0 32px;

      .top-info-content {
        row-gap: 40px;

        .description-info-container {
          row-gap: 30px;
        }
      }

      .bottom-info-content {
        row-gap: 24px;
        height: 189px;

        .complex-title-container {
          row-gap: 16px;
        }
      }
    }

    .right-info-content {
      padding: 32px 64px 0 0;
      max-width: 398px;
      .avatar-team-container {
        max-height: 510px;
        border-radius: 68px 34px 17px 8.5px;
      }

      .title-info-container {
        row-gap: 16px;
      }
    }
  }
}
@media (max-height: 800px) {
  .circle-container {
    right: -7px;
    bottom: 7px;
    height: 5px;
    width: 5px;
  }

  .main-division-screen {
    .left-info-content {
      max-width: 440px;
      padding: 48px 0 0 24px;

      .top-info-content {
        row-gap: 20px;

        .description-info-container {
          row-gap: 16px;
        }
      }

      .bottom-info-content {
        row-gap: 21px;
        height: 153px;

        .complex-title-container {
          row-gap: 14px;
        }
      }
    }

    .right-info-content {
      padding: 24px 48px 0 0;
      max-width: 290px;
      .avatar-team-container {
        max-height: 360px;
        border-radius: 48px 24px 12px 6px;
      }

      .title-info-container {
        row-gap: 16px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .circle-container {
    right: -9px;
    bottom: 8px;
    height: 6px;
    width: 6px;
  }

  .main-division-screen {
    .left-info-content {
      max-width: 520px;
      padding: 64px 0 0 32px;

      .top-info-content {
        row-gap: 40px;

        .description-info-container {
          row-gap: 30px;
        }
      }

      .bottom-info-content {
        row-gap: 24px;
        height: 189px;
        .complex-title-container {
          row-gap: 16px;
        }
      }
    }

    .right-info-content {
      padding: 32px 64px 0 0;
      max-width: 398px;
      .avatar-team-container {
        max-height: 510px;
        border-radius: 68px 34px 17px 8.5px;
      }

      .title-info-container {
        row-gap: 16px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .circle-container {
    right: -7px;
    bottom: 7px;
    height: 5px;
    width: 5px;
  }

  .main-division-screen {
    .left-info-content {
      max-width: 440px;
      padding: 48px 0 0 24px;

      .top-info-content {
        row-gap: 20px;

        .description-info-container {
          row-gap: 16px;
        }
      }

      .bottom-info-content {
        row-gap: 21px;

        .complex-title-container {
          height: 114px;
          row-gap: 14px;
        }
      }
    }

    .right-info-content {
      padding: 24px 48px 0 0;
      max-width: 290px;
      .avatar-team-container {
        max-height: 360px;
        border-radius: 48px 24px 12px 6px;
      }

      .title-info-container {
        row-gap: 16px;
      }
    }
  }
}

@media (max-width: 769px) {
  .main-division-screen {
    --main-padding-bottom: 14px;
    padding: var(--burger-height) var(--controller-padding) var(--main-padding-bottom) var(--controller-padding);
    flex-direction: column;

    .button-container {
      height: 12px;
      align-self: flex-end;
      margin-right: 22px;
    }

    .main-title-container {
      padding-right: 0;
    }

    .description-info-container {
      text-align: justify;
      margin-top: -24px;
      .description-info-content {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
    }

    .bottom-info-content {
      height: 138px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .main-paragraph-container {
        font-size: 8px;
      }

      .complex-title-container {
        display: flex;
        flex-direction: column;
        height: auto;
        row-gap: 16px;
      }
    }
  }

  .main-profile-container {
    display: flex;
    flex-direction: row;
    column-gap: 44px;
    align-items: center;
    width: 100%;
    height: 180px;
    .avatar-team-container {
      max-width: 114px;
      width: 100%;
      max-height: 180px;
      border-radius: 24px 12px 6px 3px;
    }

    .title-info-container {
      display: flex;
      flex-direction: column;
      max-width: 198px;
      row-gap: 16px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .main-division-screen {
    --main-padding-bottom: 16px;

    .button-container {
      height: 12px;
      align-self: flex-end;
      margin-right: 22px;
    }

        .description-info-container {
          margin-top: -32px;
          .description-info-content {
            row-gap: 16px;
          }
        }

      .bottom-info-content {
        height: 156px;
        row-gap: 11px;

        .main-paragraph-container {
          font-size: 10px;
        }

        .complex-title-container {
          row-gap: 18px;
        }
      }
    }

    .main-profile-container {
      column-gap: 38px;
      height: 220px;
      .avatar-team-container {
        max-width: 140px;
        max-height: 220px;
        border-radius: 30px 15px 8px 3px;
      }

      .title-info-container {
        max-width: 198px;
        row-gap: 16px;
      }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .main-division-screen {
    justify-content: flex-start;

    .description-info-container {
      margin-top: 14px;
      margin-bottom: 5%;
      .description-info-content {
        row-gap: 12px;
      }
    }

    .bottom-info-content {
      display: none;
    }
  }

  .main-profile-container {
    column-gap: 29px;
    height: 180px;
    .avatar-team-container {
      max-height: 180px;
      border-radius: 24px 12px 6px 3px;
    }

    .title-info-container {
      max-width: 198px;
      row-gap: 16px;
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .main-division-screen {
    justify-content: space-between;

    .description-info-container {
      margin-top: -18px;
      margin-bottom: 0;
      .description-info-content {
        row-gap: 12px;
      }
    }

    .bottom-info-content {
      display: flex;
      height: 138px;
      row-gap: 8px;

      .main-paragraph-container {
        font-size: 8px;
      }

      .complex-title-container {
        row-gap: 16px;
      }
    }
  }

  .main-profile-container {
    column-gap: 29px;
    height: 180px;
    .avatar-team-container {
      max-height: 180px;
      border-radius: 24px 12px 6px 3px;
    }

    .title-info-container {
      max-width: 198px;
      row-gap: 16px;
    }
  }
}


@media (max-width: 320px) {
  .main-division-screen {
    justify-content: flex-start;

    .description-info-container {
      margin-top: 14px;
      margin-bottom: 10%;
      .description-info-content {
        row-gap: 12px;
      }
    }

    .bottom-info-content {
      display: none;
    }
  }

  .main-profile-container {
    column-gap: 29px;
    height: 156px;
    .avatar-team-container {
      max-height: 156px;
      border-radius: 24px 12px 6px 3px;
    }

    .title-info-container {
      max-width: 198px;
      row-gap: 16px;
    }
  }
}

@media (min-width: 3025px) {
  .circle-container {
    right: -32px;
    bottom: 24px;
    height: 20px;
    width: 20px;
  }

  .main-division-screen {
    .left-info-content {
      max-width: 1604px;
      padding: 256px 0 0 120px;

      .top-info-content {
        row-gap: 84px;

        .description-info-container {
          row-gap: 64px;

          .main-paragraph-container {
            font-size: 32px;
          }
        }
      }

      .bottom-info-content {
        row-gap: 72px;
        height: 535px;

        .complex-title-container {
          row-gap: 48px;
        }
      }
    }

    .right-info-content {
      padding: 128px 160px 0 0;
      max-width: 1028px;
      .avatar-team-container {
        max-height: 1369px;
        border-radius: 183px 92px 46px 23px;
      }

      .title-info-container {
        row-gap: 48px;
      }
    }
  }
}

</style>