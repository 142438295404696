<template>
  <div style="position: relative;">
    <transition name="fade-opacity-lazy">
      <div class="rp-screen-container sub-container" v-if="activeScreen && !activeMobile">
        <transition name="fade-opacity-lazy">
          <modal-consulting v-if="activeModal"
                            :active-text="{
                            mainTitle: this.$t('consultingModal.mainTitleRP'),
                            resultTitle: this.$t('consultingModal.resultTitleRP'),
                            resultText: this.$t('consultingModal.resultTextRP'),
                        }"
                            @closeModal="changeStateModal"/>
        </transition>
        <div class="rp-screen-content">
          <div class="top-info-content">
            <div class="left-info-content">
              <transition name="fade-opacity-1">
                <main-title v-if="isVisible(0)">
                  {{ $t('RPCenterScreen.mainTitle') }}
                </main-title>
              </transition>
              <main-paragraph :class="{'is-visible' : isVisible(1)}">
                {{ $t('RPCenterScreen.mainParagraph') }}
              </main-paragraph>
            </div>

            <div class="right-info-content">
              <button-with-icon v-for="item in $tm('RPCenterScreen.buttonInfo')"
                                :class="{'is-visible' : isVisible(item.id + 2)}"
                                @click="isClick(item.id)"
                                :info-icon="item.icon"
                                :info-text="item.text"/>
            </div>
          </div>
          <transition name="fade-opacity-1">
            <div class="slider-container" :class="{'active-opacity': isVisible(4)}">
              <controller-title-block :info-controller="$tm('RPCenterScreen.controllerInfo')"
                                      :active-sub-page="true"
                                      @changeActiveItem="changeActiveRP"
                                      :active-index="activeIndex"/>
              <transition name="fade-opacity-lazy" mode="out-in">
                <slider-scroll-r-p :key="filterInfo"
                                   :active-info="filterInfo"
                                   @openModal="changeStateModal"/>
              </transition>

            </div>
          </transition>
        </div>
      </div>
    </transition>

    <transition name="fade-opacity-lazy">
      <div class="rp-screen-container sub-container" v-if="activeScreen && activeMobile">
        <transition name="fade-opacity-lazy">
          <modal-consulting v-if="activeModal"
                            :active-text="{
                            mainTitle: this.$t('consultingModal.mainTitleRP'),
                            resultTitle: this.$t('consultingModal.resultTitleRP'),
                            resultText: this.$t('consultingModal.resultTextRP'),
                        }"
                            @closeModal="changeStateModal"/>
        </transition>


        <transition name="fade-opacity-lazy">
          <button-title-mobile v-if="activeMobile && activeTitle"
                               :active-button="true"
                               @returnTo="routToHome"
                               :main-title="$t('RPCenterScreen.mainTitle')"/>
        </transition>

        <div class="rp-screen-content">
          <div class="top-info-content">
            <div class="left-info-content">
              <main-paragraph :class="{'is-visible' : isVisible(0)}">
                {{ $t('RPCenterScreen.mainParagraphMobile') }}
              </main-paragraph>
            </div>
          </div>
          <div class="slider-container" :class="{'active-opacity': isVisible(1)}">
            <transition name="fade-opacity-1">
              <controller-title-block :info-controller="$tm('RPCenterScreen.controllerInfo').slice(1,4)"
                                      v-if="isVisible(1)"
                                      :active-sub-page="true"
                                      @changeActiveItem="changeActiveRP"
                                      :active-index="activeIndex - 1"/>
            </transition>
            <transition name="fade-opacity-1" mode="out-in">
              <slider-scroll-r-p :key="filterInfo"
                                 v-if="isVisible(2)"
                                 :active-info="filterInfo"
                                 @openModal="changeStateModal"/>
            </transition>

          </div>

          <div class="right-info-content">
            <button-with-icon v-for="item in $tm('RPCenterScreen.buttonInfo')"
                              :class="{'is-visible' : isVisible(item.id + 3)}"
                              :active-mobile="true"
                              @click="isClick(item.id)"
                              :info-icon="item.icon"
                              :info-text="item.text"/>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/MainTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import ButtonWithIcon from "@/components/shared/buttons/ButtonWithIcon.vue";
import ControllerTitleBlock from "@/components/entities/ControllerTitleBlock.vue";
import SliderScrollRP from "@/components/entities/sliders/SliderScrollRP.vue";
import ModalConsulting from "@/components/entities/modal/ModalConsulting.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";

export default {
  name: "RPScreen",
  components: {
    ButtonTitleMobile,
    ModalConsulting, SliderScrollRP, ControllerTitleBlock, ButtonWithIcon, MainParagraph, MainTitle},
  props: {
    typeNews: {
      type: String
    }
  },
  data() {
    return {
      activeModal: false,
      activeScreen: false,
      activeTitle: false,
      activeItem: -1
    }
  },
  mounted() {
    this.activeTitle = true
    setTimeout(() => {
      this.activeScreen = true
      this.showNextItem();
    }, 500)
  },
  computed: {
    rpInfo() {
      return this.$tm('RPCenterScreen.newsInfo')
    },
    activeIndex() {
      switch (this.typeNews) {
        case 'all':
          return 0;
        case 'news':
          return 1;
        case 'cases':
          return 2;
        case 'press':
          return 3;
      }
    },
    activeInfo() {
      switch (this.activeIndex) {
        case 0:
          return this.$tm('HRCenterScreen.openInternshipsInfo')
        case 1:
          return this.$t('HRCenterScreen.emptyBlock')
      }
    },
    filterInfo() {
      switch (this.activeIndex) {
        case 0:
          return this.rpInfo;
        case 1:
          return this.rpInfo.filter(item => item.type === 'news');
        case 2:
          return this.rpInfo.filter(item => item.type === 'expert');
        case 3:
          return this.rpInfo.filter(item => item.type === 'press');
      }
    }
  },
  methods: {



    showNextItem() {
      if (this.activeItem < 4) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 350)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },

    routToHome() {
      this.$router.push({name: 'home-logo-page'});
    },

    changeActiveRP(id) {
      if(this.activeMobile) {
        switch (id) {
          case 0:
            return this.$router.push({name: "static-rp-page", params: {typeNews: 'news'}});
          case 1:
            return this.$router.push({name: "static-rp-page", params: {typeNews: 'cases'}});
          case 2:
            return this.$router.push({name: "static-rp-page", params: {typeNews: 'press'}});
        }
      } else {
        switch (id) {
          case 0:
            return this.$router.push({name: "static-rp-page", params: {typeNews: 'all'}});
          case 1:
            return this.$router.push({name: "static-rp-page", params: {typeNews: 'news'}});
          case 2:
            return this.$router.push({name: "static-rp-page", params: {typeNews: 'cases'}});
          case 3:
            return this.$router.push({name: "static-rp-page", params: {typeNews: 'press'}});
        }
      }

    },
    changeStateModal() {
      this.activeModal = !this.activeModal;
    },

    isClick(id) {
      switch (id) {
        case 0:
          this.changeStateModal();
          break;
        case 1:
          console.log('download')
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.rp-screen-container {
  justify-content: flex-end;

  .rp-screen-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .sub-button-container {
      opacity: 0;
      transition: opacity 1s ease;

      &.is-visible {
        opacity: 1;
      }
    }

    .top-info-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;

      .left-info-content {
        display: flex;
        flex-direction: column;

        .main-paragraph-container {
          white-space: pre-wrap;
          opacity: 0;
          line-height: 120%;
          transition:  opacity 1s ease;

          &.is-visible {
            opacity: 1;
          }
        }
      }

      .right-info-content {
        display: flex;
        flex-direction: column;
        width: 100%;

      }
    }

    .slider-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      opacity: 0;
      transition: opacity .6s ease;

      &.active-opacity {
        opacity: 1;
      }
    }
  }


}
@media (max-width: 3024px){
  .rp-screen-container {
    .top-info-content {
      max-height: 541px;
      padding: 0 180px;
      .left-info-content {
        row-gap: 48px;
        .main-paragraph-container {
          font-size: 28px;
        }
      }

      .right-info-content {
        max-width: 560px;
        row-gap: 22px;
      }
    }

    .slider-container {
      min-height: 627px;
      max-height: 627px;
    }
  }

}


@media (max-width: 2100px) {
  .rp-screen-container {
    .rp-screen-content {
      .top-info-content {
        max-height: 400px;
        padding: 0 136px;
        .left-info-content {
          row-gap: 40px;
          .main-paragraph-container {
            font-size: 24px;
          }
        }

        .right-info-content {
          max-width: 480px;
          row-gap: 18px;
        }
      }

      .slider-container {
        min-height: 520px;
        max-height: 520px;
      }
    }

  }
}

@media (max-width: 1750px) {
  .rp-screen-container {

    .rp-screen-content {
      .top-info-content {
        max-height: 335px;
        padding: 0 80px;

        .left-info-content {
          row-gap: 32px;
          .main-paragraph-container {
            font-size: 18px;
          }
        }

        .right-info-content {
          max-width: 340px;
          row-gap: 14px;
        }
      }

      .slider-container {
        max-height: 453px;
        min-height: 453px;
        padding: 0 20px;
      }
    }
  }
}
@media (max-height: 800px) {
  .rp-screen-container {

    .rp-screen-content {
      .top-info-content {
        max-height: 292px;
        padding: 0 56px;

        .left-info-content {
          row-gap: 20px;
          .main-paragraph-container {
            font-size: 16px;
          }
        }

        .right-info-content {
          max-width: 300px;
          row-gap: 12px;
        }
      }

      .slider-container {
        max-height: 344px;
        min-height: 344px;
        padding: 0 8px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .rp-screen-container {

    .rp-screen-content {
      .top-info-content {
        max-height: 459px;
        padding: 0 80px;

        .left-info-content {
          row-gap: 32px;
          .main-paragraph-container {
            font-size: 18px;
          }
        }

        .right-info-content {
          max-width: 340px;
          row-gap: 14px;
        }
      }

      .slider-container {
        max-height: 453px;
        min-height: 453px;
        padding: 0 20px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .rp-screen-container {

    .rp-screen-content {
      .top-info-content {
        max-height: 316px;
        padding: 0 56px;

        .left-info-content {
          row-gap: 20px;
          .main-paragraph-container {
            font-size: 16px;
          }
        }

        .right-info-content {
          max-width: 300px;
          row-gap: 12px;
        }
      }

      .slider-container {
        max-height: 344px;
        min-height: 344px;
        padding: 0 8px;
      }
    }
  }
}

@media (max-width: 769px) {
  .rp-screen-container {
    --main-padding-bottom: 16px;
    .main-title-container {
      padding-left: var(--controller-padding);
    }

    .rp-screen-content {
      .top-info-content {
        padding: 8px var(--controller-padding);
        max-height: 60px;

        .left-info-content {
          .main-paragraph-container {
            line-height: 120%;
            font-size: 12px;
          }
        }
      }

      .slider-container {
        --padding-bottom-slider: 16px;
        transition: padding .6s ease;
        max-height: 390px;
        min-height: 390px;
        padding: 8px 0 var(--padding-bottom-slider) 14px;
        row-gap: 16px;
      }

      .right-info-content {
        display: flex;
        flex-direction: column;
        row-gap: 2px;

        .sub-button-container {
          max-height: 46px;
          min-height: 46px;
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .rp-screen-container {
    .rp-screen-content {

      .top-info-content {
        padding: 8px var(--controller-padding);
        max-height: 84px;

        .left-info-content {
          .main-paragraph-container {
            font-size: 14px;
          }
        }
      }

      .slider-container {
        --padding-bottom-slider: 16px;
        max-height: 400px;
        min-height: 400px;
        padding: 8px 0 var(--padding-bottom-slider) 16px;
        row-gap: 16px;

        .slider-scroll-container {
          max-height: 346px;
        }
      }

      .right-info-content {
        .sub-button-container {
          max-height: 46px;
          min-height: 46px;
        }
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .rp-screen-container {
    --main-padding-bottom: 8px;
    .rp-screen-content {

      .top-info-content {
        padding: 8px var(--controller-padding);
        max-height: 56px;

        .left-info-content {
          .main-paragraph-container {
            font-size: 11px;
          }
        }
      }

      .slider-container {
        --padding-bottom-slider: 8px;
        max-height: 301px;
        min-height: 301px;
        padding: 8px 0 var(--padding-bottom-slider) 8px;
        row-gap: 8px;

        .slider-scroll-container {
          max-height: 255px;
        }
      }

      .right-info-content {
        .sub-button-container {
          max-height: 46px;
          min-height: 46px;
        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .rp-screen-container {
    --main-padding-bottom: 16px;
    .rp-screen-content {

      .top-info-content {
        padding: 8px var(--controller-padding);
        max-height: 60px;

        .left-info-content {
          .main-paragraph-container {
            line-height: 120%;
            font-size: 12px;
          }
        }
      }

      .slider-container {
        --padding-bottom-slider: 16px;
        max-height: 348px;
        min-height: 348px;
        padding: 8px 0 var(--padding-bottom-slider) 14px;
        row-gap: 16px;

        .slider-scroll-container {
          max-height: 302px;
        }
      }

      .right-info-content {
        .sub-button-container {
          max-height: 46px;
          min-height: 46px;
        }
      }
    }
  }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .rp-screen-container {

    .rp-screen-content {
      .top-info-content {
        max-height: 1028px;
        padding: 0 288px;

        .left-info-content {
          row-gap: 84px;
          .main-paragraph-container {
            font-size: 48px;
          }
        }

        .right-info-content {
          row-gap: 32px;
          max-width: 900px;
        }
      }

      .slider-container {
        min-height: 1027px;
        max-height: 1027px;
      }
    }
  }
}
</style>