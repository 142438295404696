<template>
  <div class="complex-title-content">
    <div class="icon-container">
      <img :src="getUrlDivisionPage(activeDivision + '-division/' + infoTitle.icon + '.svg')" alt="icon">
    </div>

    <main-paragraph>
      {{ infoTitle.mainTitle }}
    </main-paragraph>

    <icon-arrow v-if="activeMobile" direction="right"/>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import IconArrow from "@/components/shared/icons/IconArrow.vue";

export default {
  name: "TitleComplex",
  components: {IconArrow, MainParagraph},
  props: {
    infoTitle: {
      type: Object,
      required: true
    },
    activeDivision: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.complex-title-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    .main-paragraph-container {
      opacity: 1;
    }
    .icon-container {
      opacity: 1;
    }
  }

  .main-paragraph-container {
    opacity: .3;
    transition: opacity .6s ease;
  }

  .icon-container {
    opacity: .3;
    transition: opacity .6s ease;
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
@media (max-width: 3024px){
  .complex-title-content {
    column-gap: 16px;

    .icon-container {
      width: 40px;
      height: 40px;
    }
  }
}


@media (max-width: 2100px) {
  .complex-title-content {
    column-gap: 14px;

    .icon-container {
      width: 32px;
      height: 32px;
    }
  }
}

@media (max-width: 1750px) {
  .complex-title-content {
    column-gap: 12px;

    .icon-container {
      width: 24px;
      height: 24px;
    }
  }
}
@media (max-height: 800px) {
  .complex-title-content {
    column-gap: 8px;

    .icon-container {
      width: 18px;
      height: 18px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .complex-title-content {
    column-gap: 12px;

    .icon-container {
      width: 24px;
      height: 24px;
    }
  }
}

@media (max-width: 1133px) {
  .complex-title-content {
    column-gap: 8px;

    .icon-container {
      width: 18px;
      height: 18px;
    }
  }
}
@media (max-width: 769px) {
  .complex-title-content {
    column-gap: 8px;

    .main-paragraph-container {
      opacity: 1;
      font-size: 10px;
      color: var(--color-main-title)
    }

    .icon-container {
      opacity: 1;
    }

    .icon-arrow-container {
      margin-left: auto;
    }

    .icon-container {
      width: 16px;
      height: 16px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .complex-title-content {
    column-gap: 8px;

    .main-paragraph-container {
      font-size: 12px;
    }


    .icon-container {
      width: 18px;
      height: 18px;
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .complex-title-content {
    column-gap: 28px;

    .icon-container {
      width: 64px;
      height: 64px;
    }
  }
}

</style>