<template>
  <div class="project-screen-container main-container">
    <button-title-mobile v-if="activeMobile"
                         :active-button="false"
                         :main-title="$t('projectScreen.smallTitleMobile')"/>
    <div class="project-screen-content">
      <transition :name="activeAnimation" mode="out-in">
        <div class="top-content" v-show="activeTop">
          <transition :name="activeAnimation">
            <medium-title :key="activeTitle">
              {{ activeTitle }}
            </medium-title>
          </transition>

          <div class="img-content">
            <svg width="116" height="117" viewBox="0 0 116 117" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="lineFirst_0"  fill-rule="evenodd"  clip-rule="evenodd" d="M101.496 38.7673V77.8351C101.496 81.2909 99.6546 84.4809 96.6628 86.2064L62.8294 105.738C59.8376 107.463 56.1546 107.463 53.1628 105.738L19.3294 86.2064C16.3376 84.4809 14.4961 81.2861 14.4961 77.8351V38.7673C14.4961 35.3114 16.3376 32.1214 19.3294 30.3959L53.1628 10.8644C56.1546 9.13894 59.8376 9.13894 62.8294 10.8644L96.6628 30.3959C99.6546 32.1214 101.496 35.3114 101.496 38.7673" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="lineFirst_1"  d="M99.6167 83.4284H16.3867" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="lineFirst_2" d="M15.4,81.8L57.1,9.7h1.8l41.7,72.1" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>

              <path id="lineFirst_0_hidden" style="visibility:hidden;" fill-rule="evenodd" clip-rule="evenodd" d="M101.496 38.7673V77.8351C101.496 81.2909 99.6546 84.4809 96.6628 86.2064L62.8294 105.738C59.8376 107.463 56.1546 107.463 53.1628 105.738L19.3294 86.2064C16.3376 84.4809 14.4961 81.2861 14.4961 77.8351V38.7673C14.4961 35.3114 16.3376 32.1214 19.3294 30.3959L53.1628 10.8644C56.1546 9.13894 59.8376 9.13894 62.8294 10.8644L96.6628 30.3959C99.6546 32.1214 101.496 35.3114 101.496 38.7673" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="lineFirst_1_hidden" style="visibility:hidden;"  d="M99.6167 83.4284H16.3867" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="lineFirst_2_hidden" style="visibility:hidden;" d="M15.4,81.8L57.1,9.7h1.8l41.7,72.1" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>

              <path id="lineSecond_0" style="visibility:hidden;"  fill-rule="evenodd" clip-rule="evenodd" d="M57.9961 101.797V101.797C33.9696 101.797 14.4961 82.3234 14.4961 58.2969V58.2969C14.4961 34.2704 33.9696 14.7969 57.9961 14.7969V14.7969C82.0226 14.7969 101.496 34.2704 101.496 58.2969V58.2969C101.496 82.3234 82.0226 101.797 57.9961 101.797" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="lineSecond_1" style="visibility:hidden;"  fill-rule="evenodd" clip-rule="evenodd" d="M57.9961 101.797V101.797C41.9784 101.797 28.9961 88.8145 28.9961 72.7969V72.7969C28.9961 56.7792 41.9784 43.7969 57.9961 43.7969V43.7969C74.0138 43.7969 86.9961 56.7792 86.9961 72.7969V72.7969C86.9961 88.8145 74.0138 101.797 57.9961 101.797" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="lineSecond_2" style="visibility:hidden;"  fill-rule="evenodd" clip-rule="evenodd" d="M58.0026 101.794V101.794C48.6598 101.794 41.0859 94.2204 41.0859 84.8776V84.8776C41.0859 75.5348 48.6598 67.9609 58.0026 67.9609V67.9609C67.3454 67.9609 74.9193 75.5348 74.9193 84.8776V84.8776C74.9193 94.2204 67.3454 101.794 58.0026 101.794" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>

              <path id="lineThree_0" fill-rule="evenodd" style="visibility:hidden;" clip-rule="evenodd" d="M90.7905 102.683H24.7509C17.4564 102.683 11.543 96.5846 11.543 89.0621V27.7691C11.543 20.2466 17.4564 14.1484 24.7509 14.1484H90.7905C98.085 14.1484 103.998 20.2466 103.998 27.7691V89.0621C103.998 96.5846 98.085 102.683 90.7905 102.683" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="lineThree_1"  style="visibility:hidden;" d="M49.7274 74.7843L36.5195 61.1636L49.7274 47.543" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
              <path id="lineThree_2" style="visibility:hidden;" d="M69.5391 47.543L82.747 61.1636L69.5391 74.7843" stroke="#71757F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>

            </svg>
            <!--            <div v-html="responsePhoto[activeIndex]"></div>-->
          </div>
        </div>
      </transition>

      <transition name="fade-translate-y-revert">
        <div class="slider-container" v-if="activeBottom">
          <controller-title-block :info-controller="activeInfoController"
                                  @changeActiveItem="changeActiveProject"
                                  :active-index="activeIndex"/>
          <transition name="fade-opacity-lazy" mode="out-in">
            <slider-scroll-project :key="filterInfo" :active-info="filterInfo"/>
          </transition>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>
import SliderScroll from "@/components/entities/sliders/SliderScroll.vue";
import SliderScrollProject from "@/components/entities/sliders/SliderScrollProject.vue";
import MediumTitle from "@/components/shared/text/MediumTitle.vue";
import ControllerTitleBlock from "@/components/entities/ControllerTitleBlock.vue";
import SmallTitle from "@/components/shared/text/SmallTitle.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
// import { MorphSVGPlugin } from 'gsap-trial/MorphSVGPlugin';
// import gsap from 'gsap-trial';
import KUTE from 'kute.js';
export default {
  name: "ProjectScreen",
  created() {
    this.typeProject = this.$route.params.typeProject
    this.$watch(
        () => this.$route.params,
        (toParams) => {
          this.typeProject = toParams?.typeProject
        }
    )
  },
  components: {ButtonTitleMobile, SmallTitle, ControllerTitleBlock, MediumTitle, SliderScrollProject, SliderScroll},
  data() {
    return {
      animationChain: '',
      imgTitle: ['project.svg', 'consulting.svg', 'engineering.svg'],
      typeProject: '',
      activeTop: false,
      activeBottom: false,
      activeAnimation: 'fade-opacity-1',
      responsePhoto: []
    }
  },

  async mounted() {
    // gsap.registerPlugin(MorphSVGPlugin)
    setTimeout(() => {
      this.startAnimation();
    }, 500)
    // let promiseArr = [];
    // this.imgTitle.forEach(item => {
    //   promiseArr.push(axios.get(this.getUrlProject(item)).then(r => r.data))
    // })
    // this.responsePhoto = await Promise.all(promiseArr);
  },

  computed: {

    activeInfoController() {
      if(this.activeMobile){
        return this.$tm('projectScreen.controllerTitleMobile');
      } else {
        return this.$tm('projectScreen.controllerTitle');
      }
    },

    activeIndex() {
      switch (this.typeProject) {
        case 'project-division':
          return 0;
        case 'consulting-division':
          return 1;
        case 'engineering-division':
          return 2;
      }
    },

    projectInfo() {
      return this.$tm('projectScreen.projectInfo');
    },

    filterInfo() {
      switch (this.typeProject) {
        case 'project-division':
          return this.projectInfo.filter(item => item.type === 'project')
        case 'consulting-division':
          return this.projectInfo.filter(item => item.type === 'consulting')
        case 'engineering-division':
          return this.projectInfo.filter(item => item.type === 'engineering')
      }
    },

    activeTitle() {
      return this.$tm('projectScreen.projectTitle')[this.activeIndex]
    },
    activeImg() {
      return this.imgTitle[this.activeIndex]
    }
  },
  methods: {
    startAnimation() {
      this.checkAnimationLogo(this.activeIndex);
      setTimeout(() => {
        this.activeTop = true;
        setTimeout(() => {
          this.activeBottom = true
          this.activeAnimation = 'fade-opacity-lazy'
        }, 500)
      }, 500)
    },

    checkAnimationLogo(id) {
      switch (id) {
        case 0:
         KUTE.fromTo('#lineFirst_0',  { path: '#lineFirst_0'}, { path: '#lineThree_0'}, { duration: 500, easing: 'easingQuadraticInOutOut'}).start();
          KUTE.fromTo('#lineFirst_1',  { path: '#lineFirst_1'}, { path: '#lineThree_1'}, { duration: 500, easing: 'easingQuadraticInOutOut'}).start();
          KUTE.fromTo('#lineFirst_2',  { path: '#lineFirst_2'}, { path: '#lineThree_2'}, { duration: 500, easing: 'easingQuadraticInOutOut'}).start();
          // this.animationChain = KUTE.fromTo('#lineSecond_0',  { path: '#lineSecond_0'}, { path: '#lineThree_0'}).start();
          //
          // gsap.to("#lineFirst_0", { duration: 1, morphSVG:"#lineThree_0",
          //   ease: 'power3.inOut'})
          // gsap.to("#lineFirst_1", { duration: 1, morphSVG:"#lineThree_1",
          //   ease: 'power3.inOut'})
          // gsap.to("#lineFirst_2", { duration: 1, morphSVG:"#lineThree_2",
          //   ease: 'power3.inOut'})
          break;
        case 1:
         KUTE.fromTo('#lineFirst_0', { path: '#lineFirst_0'}, { path: '#lineSecond_0'}, { duration: 500, easing: 'easingQuadraticInOut'}).start();
          KUTE.fromTo('#lineFirst_1',  { path: '#lineFirst_1'}, { path: '#lineSecond_2'}, { duration: 500,  easing: 'easingQuadraticInOut'}).start();
          KUTE.fromTo('#lineFirst_2',  { path: '#lineFirst_2'}, { path: '#lineSecond_1'}, { duration: 500,  easing: 'easingQuadraticInOut'}).start();

          // gsap.to("#lineFirst_0", { duration: 1, morphSVG:"#lineSecond_0",
          //   ease: 'power3.inOut'})
          // gsap.to("#lineFirst_1", { duration: 1, morphSVG:"#lineSecond_2",
          //   ease: 'power3.inOut'})
          // gsap.to("#lineFirst_2", { duration: 1, morphSVG:"#lineSecond_1",
          //   ease: 'power3.inOut'})
          break;
        case 2:
          KUTE.fromTo('#lineFirst_0',  { path: '#lineFirst_0'}, { path: '#lineFirst_0_hidden'},{ duration: 500,  easing: 'easingQuadraticInOut'}).start();
          KUTE.fromTo('#lineFirst_1',  { path: '#lineFirst_1'}, { path: '#lineFirst_1_hidden'}, { duration: 500,  easing: 'easingQuadraticInOut'}).start();
          KUTE.fromTo('#lineFirst_2',  { path: '#lineFirst_2'}, { path: '#lineFirst_2_hidden'}, { duration: 500,  easing: 'easingQuadraticInOut'}).start();

          // gsap.to("#lineFirst_0", { duration: 1, morphSVG:"#lineFirst_0",
          //   ease: 'power3.inOut'})
          // gsap.to("#lineFirst_1", { duration: 1, morphSVG:"#lineFirst_1",
          //   ease: 'power3.inOut'})
          // gsap.to("#lineFirst_2", { duration: 1, morphSVG:"#lineFirst_2",
          //   ease: 'power3.inOut'})
          break;
      }
    },

    changeActiveProject(id) {
      this.checkAnimationLogo(id);
      switch (id) {
        case 0:
          this.$router.push({name: 'main-project-page', params: {typeProject: 'project-division'}})
          break;
        case 1:
          this.$router.push({name: 'main-project-page', params: {typeProject: 'consulting-division'}})
          break;
        case 2:
          this.$router.push({name: 'main-project-page', params: {typeProject: 'engineering-division'}})
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.project-screen-container {
    justify-content: flex-end;

  .project-screen-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .top-content {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .img-content {
        width: 100%;
        height: 100%;

        & svg {
          width: 100%;
          height: 100%;
        }

        & img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .slider-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
}

@media (max-width: 3840px){
  .project-screen-container {
    .project-screen-content {
      max-height: 1760px;
      .top-content {
        padding: 0 320px 0 120px;

        .main-title-container {
          font-size: 96px;
        }

        .img-content {
          max-width: 232px;
          max-height: 232px;
        }
      }

      .slider-container {
        max-height: 1387px;
      }
    }
  }
}

@media (max-width: 3024px){
  .project-screen-container {
    .project-screen-content {
      max-height: 1050px;

      .top-content {
        padding: 0 128px 0 64px;

        .main-title-container {
          font-size: 56px;
        }

        .img-content {
          max-width: 136px;
          max-height: 136px;
        }
      }

      .slider-container {
        max-height: 667px;
      }
    }
  }
}


@media (max-width: 2100px) {
  .project-screen-container {
    .project-screen-content {
      max-height: 848px;

      .top-content {
        padding: 0 96px 0 48px;

        .main-title-container {
          font-size: 48px;
        }

        .img-content {
          max-width: 116px;
          max-height: 116px;
        }
      }

      .slider-container {
        max-height: 611px;
      }
    }
  }
}


@media (max-width: 1750px) {
  .project-screen-container {
    .project-screen-content {
      max-height: 713px;

      .top-content {
        padding: 0 64px 0 32px;

        .main-title-container {
          font-size: 32px;
        }

        .img-content {
          max-width: 78px;
          max-height: 78px;
        }
      }

      .slider-container {
        max-height: 536px;
      }
    }
  }
}
@media (max-height: 800px) {
  .project-screen-container {
    .project-screen-content {
      max-height: 576px;

      .top-content {
        padding: 0 48px 0 24px;

        .main-title-container {
          font-size: 28px;
        }

        .img-content {
          max-width: 68px;
          max-height: 68px;
        }
      }

      .slider-container {
        max-height: 436px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .project-screen-container {
    .project-screen-content {
      max-height: 775px;

      .top-content {
        padding: 0 64px 0 32px;

        .main-title-container {
          font-size: 32px;
        }

        .img-content {
          max-width: 78px;
          max-height: 78px;
        }
      }

      .slider-container {
        max-height: 536px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .project-screen-container {
    .project-screen-content {
      max-height: 576px;

      .top-content {
        padding: 0 48px 0 24px;

        .main-title-container {
          font-size: 28px;
        }

        .img-content {
          max-width: 68px;
          max-height: 68px;
        }
      }

      .slider-container {
        max-height: 436px;
      }
    }
  }
}
@media (max-width: 769px) {
  .project-screen-container {
    .project-screen-content {
      max-height: 100%;

      .top-content {
        padding: 26px 16px 0 0;

        .img-content {
          max-width: 42px;
          max-height: 42px;
        }
      }

      .slider-container {
        max-height: 485px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .project-screen-container {
    .project-screen-content {
      .top-content {
        padding: 0 14px 0 0;
        .img-content {
          max-width: 48px;
          max-height: 48px;
        }
      }

      .slider-container {
        max-height: 510px;
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){
  .project-screen-container {
    .project-screen-content {
      max-height: 100%;

      .top-content {
        padding: 0 14px 0 0;

        .img-content {
          max-width: 40px;
          max-height: 40px;
        }
      }

      .slider-container {
        max-height: 445px;
      }
    }
  }
}

@media (max-width: 375px){
  .project-screen-container {
    .project-screen-content {
      max-height: 100%;

      .top-content {
        padding: 0 14px 0 0;

        .img-content {
          max-width: 40px;
          max-height: 40px;
        }
      }

      .slider-container {
        max-height: 395px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .project-screen-container {
    .project-screen-content {
      max-height: 100%;

      .top-content {
        padding: 0 14px 0 0;

        .img-content {
          max-width: 42px;
          max-height: 42px;
        }
      }

      .slider-container {
        max-height: 460px;
      }
    }
  }
}


@media (max-width: 320px) {
  .project-screen-container {
    .project-screen-content {
      max-height: 100%;

      .top-content {
        padding: 0 14px 0 0;

        .img-content {
          max-width: 32px;
          max-height: 32px;
        }
      }

      .slider-container {
        max-height: 300px;
      }
    }
  }
}

@media (min-width: 3841px) {
  .project-screen-container {
    .project-screen-content {
      max-height: 1940px;

      .top-content {
        padding: 0 320px 0 120px;

        .main-title-container {
          font-size: 96px;
        }

        .img-content {
          max-width: 232px;
          max-height: 232px;
        }
      }

      .slider-container {
        max-height: 1387px;
      }
    }
  }
}

</style>