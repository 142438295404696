<template>
  <div class="main-controller-container">
    <block-logo @routToHomePage="routToHome"/>
    <transition name="fade-opacity-lazy">
      <div class="menu-container" v-if="activeMenuContainer">
        <div class="menu-content">
          <icon-arrow class="main-swiper-container top"
                      direction="top"
                      :class="{'hover': !activePrevNavigation}"
                      @click="slideToPrev" />

          <text-with-circle v-for="item in infoMenu"
                            @click="changeIndex(item)"
                            :active-index="activeIndex === item.id"
                            :item-info="item"
                            :key="item.id">
            <sub-paragraph :class="{'eng' : activeEng}">
              {{ item.text }}
            </sub-paragraph>
          </text-with-circle>

          <icon-arrow class="main-swiper-container bottom"
                      direction="bottom"
                      :class="{'hover': activeNextNavigation}"
                      @click="slideToNext"/>
        </div>

        <button-arrow :active-text="$t('returnButton')"
                      @clickToButton="routToHomePage"/>
      </div>
    </transition>


    <div class="toggle-container">
        <toggle-theme name-check="theme"/>
        <toggle-local/>
    </div>
  </div>
</template>

<script>
import IconArrow from "@/components/shared/icons/IconArrow.vue";
import IconCircle from "@/components/shared/icons/IconCircle.vue";
import ToggleTheme from "@/components/shared/toggle/ToggleTheme.vue";
import ToggleLocal from "@/components/shared/toggle/ToggleLocal.vue";
import {mapActions} from "vuex";
import ButtonArrow from "@/components/shared/buttons/ButtonArrow.vue";
import TextWithCircle from "@/components/shared/TextWithCircle.vue";
import SubParagraph from "@/components/shared/text/SubParagraph.vue";
import BlockLogo from "@/components/entities/blocks/BlockLogo.vue";

export default {
  name: "MainController",
  components: {BlockLogo, SubParagraph, TextWithCircle, ButtonArrow, ToggleLocal, ToggleTheme, IconCircle, IconArrow},
  props: {
    infoMenu: {
      required: true
    },
    activeIndex: {
      required: true
    },
    activeMenuContainer: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      focusParam: -1,
    }
  },
  computed: {

    activeIndexPage() {
      return this.$store.state.activeIndexPage;
    },

    activeNextNavigation() {
      return this.activeIndex === this.infoMenu.length - 1;
    },

    activePrevNavigation() {
      return this.$route.name.includes('main')  ||  this.activeIndex !== 0
    },
  },
  methods: {
    ...mapActions(['ACTIVE_INDEX_PAGE']),

    changeIndex(item) {
      if(this.$route.name.includes('main')) {
        this.$emit('changeIndex', item.idSlide)
      } else {
        this.$emit('changeIndex', item.id)
      }
    },

    slideToPrev() {
      if(this.activePrevNavigation) {
        this.$emit('changeIndex', this.activeIndex - 1, this.$route.name.includes('main'))
      }
    },

    slideToNext() {
      if(!this.activeNextNavigation) {
          this.$emit('changeIndex', this.activeIndex + 1, this.$route.name.includes('main'))
      }
    },


    routToHome() {
      if(this.$route.name.includes('main')) {
        if(this.activeMobile) {
          this.$emit('changeIndex', 0)
        } else {
          this.$emit('changeIndex', 1)
        }
      } else {
        this.$router.push({name: 'home-navigation-page'})
      }
      this.ACTIVE_INDEX_PAGE(-1)
    },

    routToHomePage() {
      if(this.$route?.meta.activeDivisionPage) {
        this.$router.push({
          name: 'main-division-page'
        })
      } else if(this.$route?.meta.activeDivisionTechnologiesPage) {
        this.$router.push({
          name: 'main-type-division-page', params: {typeDivision: this.$route.params.typeDivision}
        })
      } else if(this.$route?.meta.activeDivisionServicesPage) {
        this.$router.push({
          name: 'main-type-division-page', params: {typeDivision: this.$route.params.typeDivision
          }})
      } else {
        this.ACTIVE_INDEX_PAGE(-1)
        this.$router.push({
          name: 'home-navigation-page'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.menu-text {
  color: var(--color-button);
  opacity: .3;
  transition: opacity .6s ease;
}

.main-swiper-container {
  opacity: 1;
  transition: opacity .6s ease;

  &.hover {
    opacity: 0 !important;
    cursor: default !important;
  }
}


  .main-controller-container {
    max-width: var(--controller-padding);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 899;
    width: 100%;
    height: 100%;

    .menu-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      height: 100%;

      .menu-content {
        display: flex;
        flex-direction: column;

        .icon-arrow-container {
          opacity: .4;
          transition: opacity .6s ease;

          &:hover {
            opacity: 1;
          }
        }
      }

      .sub-button-container {
        position: absolute;
        bottom: 0;
      }
    }

    .toggle-container {
      display: flex;
      flex-direction: column;
    }
  }

@media (max-width: 3024px){
  .menu-text {
    font-size: 18px;
  }


  .main-controller-container {
    padding: 64px 48px 64px 90px;

    .menu-container {
      max-height: 740px;

      .menu-content {
        row-gap: 48px;
        .menu-text-content {
          .icon-circle-container {
            left: -42px;
          }
        }
      }
    }


    .toggle-container {
      row-gap: 12px;
    }
  }
}


@media (max-width: 2100px) {
  .menu-text {
    font-size: 16px;
  }

  .main-controller-container {
    padding: 48px 32px 64px 68px;

    .menu-container {
      max-height: 570px;

      .menu-content {
        row-gap: 32px;


        .menu-text-content {

          .icon-circle-container {
            left: -36px;
          }
        }
      }
    }


    .toggle-container {
      row-gap: 10px;
    }
  }
}


@media (max-width: 1750px) {
  .menu-text {
    font-size: 14px;
  }


  .main-controller-container {
    padding: 32px 24px 32px 54px;

    .menu-container {
      max-height: 502px;

      .menu-content {
        row-gap: 30px;

        .menu-text-content {

          .icon-circle-container {
            left: -30px;
          }
        }
      }
    }


    .toggle-container {
      row-gap: 8px;
    }
  }
}
@media (max-height: 800px) {
  .menu-text {
    font-size: 10px;
  }


  .main-controller-container {
    padding: 24px 16px 24px 38px;

    .menu-container {
      max-height: 380px;

      .menu-content {
        row-gap: 24px;

        .menu-text-content {

          .icon-circle-container {
            left: -22px;
          }
        }
      }
    }


    .toggle-container {
      row-gap: 6px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .menu-text {
    font-size: 14px;
  }


  .main-controller-container {
    padding: 32px 24px 32px 54px;

    .menu-container {
      max-height: 502px;

      .menu-content {
        row-gap: 30px;

        .menu-text-content {

          .icon-circle-container {
            left: -30px;
          }
        }
      }
    }

    .toggle-container {
      row-gap: 8px;
    }
  }
}

@media (max-width: 1133px) {
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .menu-text {
    font-size: 32px;
  }


  .main-controller-container {
    max-width: 458px;
    padding: 128px 64px 128px 144px;

    .menu-container {
      max-height: 1086px;

      .menu-content {
        row-gap: 64px;

        .menu-text-content {

          .icon-circle-container {
            left: -80px;
          }
        }
      }
    }

    .toggle-container {
      row-gap: 16px;
    }
  }
}

</style>