<template>
  <div class="group-screen-container main-container">
    <div class="group-screen-content">

      <div class="top-info-content">
        <transition name="fade-opacity-1">
          <main-title  :class="{'active-team' : activeIndex === 2}" v-if="isVisible(1)">
            {{ activeInfo?.mainTitle }}
          </main-title>
        </transition>


        <transition name="fade-opacity-1">
        <main-paragraph v-if="activeInfo?.subTitle && isVisible(2)">
          {{ activeInfo?.subTitle }}
        </main-paragraph>
        </transition>
      </div>

      <transition name="fade-opacity-1">
        <button-arrow :direction-active="'right'"
                      v-if="isVisible(3) && activeInfo?.textButton"
                      @click="changePage(activeInfo?.idButton)"
                      :active-text="activeInfo?.textButton"/>
      </transition>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/MainTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import ButtonArrow from "@/components/shared/buttons/ButtonArrow.vue";

export default {
  name: "GroupScreen",
  components: {ButtonArrow, MainParagraph, MainTitle},
  props: {
    activeIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeScreen: false,
      currentItem: 0
    }
  },

  mounted() {
    setTimeout(() => {
      this.activeScreen = true
      this.showNextItem();
    }, 250)
  },

  computed: {
    activeInfo() {
      switch (this.activeIndex) {
        case 0:
          return {
            mainTitle: this.$t('innovationScreen.mainTitle'),
            subTitle: this.$t('navigationScreen.subTitle'),
            idButton: 0,
            // textButton: this.$tm('navigationScreen.navigationInfo')[0].navigation[0].name
          }
        case 1:
          return {
            mainTitle: this.$t('divisionScreen.mainTitle'),
            subTitle: this.$t('navigationScreen.subTitle'),
            idButton: 1,
            textButton: this.$t('breadCrumb.dynamicTitle.division.staticTitle')
          }
        case 2:
          return {
            mainTitle: this.$t('teamScreen.mainTitleMobileSLogan'),
            idButton: 2,
            textButton: this.$t('teamScreen.mainTitleMobile')
          }
      }
    }
  },
  methods: {
    showNextItem() {
      if (this.currentItem < 4) {
        setTimeout(() => {
          this.currentItem++
          this.showNextItem()
        }, 300)
      }
    },
    isVisible(index) {
      return index <= this.currentItem
    },


    changePage(id) {
      this.$emit('changeContent')
      // switch (id) {
      //   case 0:
      //     this.$router.push({name: 'main-business-page'})
      //     break;
      //   case 1:
      //     this.$router.push({name: 'main-division-page'})
      //     break;
      //   case 2:
      //     this.$router.push({name: 'main-management-page', params: {typeManagement: 'founders'}})
      //     break;
      // }
    }
  }
}
</script>

<style scoped lang="scss">
  .group-screen-container {
    position: relative;
    .group-screen-content {
      z-index: 1;
      padding: 64px 16px 0;
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      .top-info-content {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .main-title-container {
          font-weight: 400;
          white-space: pre-wrap;

          &.active-team {
            font-size: 20px;
          }
        }

        .main-paragraph-container {
          white-space: pre-wrap;
        }
      }

      .sub-button-container {
        color: var(--color-main-title)
      }
    }

  }

  @media (max-width: 769px) {
    .group-screen-container {
      .group-screen-content {
        padding: 56px 16px 0;
        row-gap: 32px;

        .top-info-content {
          row-gap: 16px;

          .main-title-container {
            &.active-team {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
    .group-screen-container {
      .group-screen-content {
        padding: 64px 16px 0;
        row-gap: 32px;

        .top-info-content {
          row-gap: 16px;

          .main-title-container {
            &.active-team {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .group-screen-container {
      .group-screen-content {
        padding: 48px 10px 0;
        row-gap: 32px;

        .top-info-content {
          row-gap: 16px;

          .main-title-container {
            font-size: 20px;
            &.active-team {
              font-size: 18px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .group-screen-container {
    .group-screen-content {
      padding: 56px 16px 0;
      row-gap: 32px;

      .top-info-content {
        row-gap: 16px;

        .main-title-container {
          &.active-team {
            font-size: 20px;
          }
        }
      }
    }
  }
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

</style>