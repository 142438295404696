<template>
  <transition name="fade-opacity-lazy">
  <div class="complience-screen-container sub-container" v-if="activeScreen">
    <transition name="fade-opacity-lazy">
      <button-title-mobile v-if="activeMobile && activeTitle"
                           :active-button="true"
                           @returnTo="routToHome"
                           :main-title="$t('complienceScreen.mainTitle')"/>
    </transition>

    <transition name="fade-opacity-lazy">
      <modal-login v-if="activeModalLogin"
                   :active-title="{
                      type: 'user',
                      mainTitle: $t('modalLogin.mainTitle'),
                      inputInfo: $tm('modalLogin.inputInfo'),
                   }"
                   @closeModal="activeModalLogin = false"/>
    </transition>

    <transition name="fade-opacity-1">
      <modal-hotline v-if="activeModalLHotline"
                   @closeModal="activeModalLHotline = false"/>
    </transition>

    <div class="complience-screen-content">
      <div class="top-info-content">
        <div class="left-info-content" v-if="activeLeftInfo">
          <main-title v-if="!activeMobile"> {{ $t('complienceScreen.mainTitle') }} </main-title>
          <main-paragraph v-if="!activeMobile"> {{ $t('complienceScreen.mainParagraph') }} </main-paragraph>
          <main-paragraph v-else> {{ $t('complienceScreen.mainParagraphMobile') }} </main-paragraph>
        </div>
        <div class="right-info-content">
          <button-with-icon v-for="item in $tm('complienceScreen.buttonController')"
                            :class="{'is-visible' : isVisible(item.id)}"
                            :active-mobile="activeMobile"
                            @click="openMainButton(item.id)"
                            :info-text="item.mainTitle"
                            :info-icon="item.icon"/>
        </div>
      </div>
      <div class="bottom-info-content">
        <button-with-icon v-for="item in $tm('complienceScreen.buttonControllerSecond')"
                          :active-sub-button="true"
                          :class="{'is-visible' : isVisible(item.id + 3)}"
                          :active-mobile="activeMobile"
                          @click="openSubButton(item.id)"
                          :info-text="item.mainTitle"
                          :info-icon="'download.svg'"/>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
import ButtonWithIcon from "@/components/shared/buttons/ButtonWithIcon.vue";
import MainTitle from "@/components/shared/text/MainTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import ModalLogin from "@/components/entities/modal/ModalLogin.vue";
import ModalHotline from "@/components/entities/modal/ModalHotline.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";

export default {
  name: "ComplienceScreen",
  components: {ButtonTitleMobile, ModalHotline, ModalLogin, MainParagraph, MainTitle, ButtonWithIcon},
  data() {
    return {
      activeModalLogin: false,
      activeModalLHotline: false,
      activeScreen: false,
      activeTitle: false,
      activeItem: false,
      activeLeftInfo: false,
      timerFirst: null,
      timerSecond: null,
      timerThird: null,

    }
  },
  unmounted() {
    clearTimeout(this.timerFirst);
    clearTimeout(this.timerSecond);
    clearTimeout(this.timerThird);
  },
  mounted() {
    this.activeTitle = true
    this.timerFirst =setTimeout(() => {
      this.activeScreen = true
      this.activeLeftInfo = true
      this.timerSecond = setTimeout(() => {
        this.showNextItem();
      }, 250)
    }, 500)
  },
  methods:{
    openMainButton(id) {
      switch (id) {
        case 0:
          this.activeModalLHotline = true
          break;
        case 1:
          this.activeModalLogin = true
          break;
        case 2:
          console.log('library')
          break;
      }
    },

    routToHome() {
      this.$router.push({name: 'home-logo-page'});
    },


    openSubButton(id) {
      switch (id) {
        case 0:
          console.log('1')
          break;
        case 1:
          console.log('2')
          break;
        case 2:
          console.log('3')
          break;
        case 3:
          console.log('4')
          break;
        case 4:
          console.log('5')
          break;
        case 5:
          console.log('6')
          break;
      }
    },
    showNextItem() {
      if (this.activeItem < this.$tm('complienceScreen.buttonController').length + this.$tm('complienceScreen.buttonControllerSecond').length) {
        this.timerThird = setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 350)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
  .complience-screen-container {
    .complience-screen-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      .sub-button-container {
        opacity: 0;
        transition: opacity 1s ease;

        &.is-visible {
          opacity: 1;
        }
      }

      .top-info-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 85%;

        .left-info-content {
          display: flex;
          flex-direction: column;

          .main-paragraph-container {
            white-space: pre-wrap;
          }
        }

        .right-info-content {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }

      .bottom-info-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        width: 100%;

        .sub-button-container {
          max-width: 32%;
          flex-basis: 50%;
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 3840px) {
    .complience-screen-container {
      .complience-screen-content {
        padding: 0 288px 160px;

        .top-info-content {
          .left-info-content {
            row-gap: 84px;
          }

          .right-info-content {
            max-width: 900px;
            row-gap: 32px;
          }
        }

        .bottom-info-content {
          row-gap: 32px;
          column-gap: 32px;

          .sub-button-container {
            max-height: 96px;
          }
        }
      }
    }
  }


  @media (max-width: 3024px){
    .complience-screen-container {
      .complience-screen-content {
        padding: 0 180px 116px;

        .top-info-content {
          .left-info-content {
            row-gap: 48px;

            .main-paragraph-container {
              font-size: 28px;
            }
          }

          .right-info-content {
            max-width: 560px;
            row-gap: 22px;
          }
        }

        .bottom-info-content {
          row-gap: 22px;
          column-gap: 22px;

          .sub-button-container {
            max-height: 68px;
          }
        }
      }
    }
  }


  @media (max-width: 2100px) {
    .complience-screen-container {
      .complience-screen-content {
        padding: 0 136px 88px;

        .top-info-content {
          .left-info-content {
            row-gap: 40px;
            .main-paragraph-container {
              font-size: 24px;
            }
          }

          .right-info-content {
            max-width: 480px;
            row-gap: 18px;
          }
        }

        .bottom-info-content {
          row-gap: 18px;
          column-gap: 18px;

          .sub-button-container {
            max-height: 54px;
          }
        }
      }
    }
  }

  @media (max-width: 1750px) {
    .complience-screen-container {
      .complience-screen-content {
        padding: 0 80px 48px;

        .top-info-content {
          .left-info-content {
            row-gap: 32px;
            .main-paragraph-container {
              font-size: 18px;
            }
          }

          .right-info-content {
            max-width: 340px;
            row-gap: 14px;
          }
        }

        .bottom-info-content {
          row-gap: 14px;
          column-gap: 14px;

          .sub-button-container {
            max-height: 42px;
          }
        }
      }
    }
  }

  @media (max-height: 800px) {
    .complience-screen-container {
      .complience-screen-content {
        padding: 0 56px 42px;

        .top-info-content {
          .left-info-content {
            row-gap: 20px;
            .main-paragraph-container {
              font-size: 16px;
            }
          }

          .right-info-content {
            max-width: 300px;
            row-gap: 12px;
          }
        }

        .bottom-info-content {
          row-gap: 12px;
          column-gap: 12px;

          .sub-button-container {
            max-height: 34px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .complience-screen-container {
      .complience-screen-content {
        padding: 0 80px 48px;

        .top-info-content {
          .left-info-content {
            row-gap: 32px;
            .main-paragraph-container {
              font-size: 18px;
            }
          }

          .right-info-content {
            max-width: 340px;
            row-gap: 14px;
          }
        }

        .bottom-info-content {
          row-gap: 14px;
          column-gap: 14px;

          .sub-button-container {
            max-height: 42px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .complience-screen-container {
      .complience-screen-content {
        padding: 0 56px 42px;

        .top-info-content {
          .left-info-content {
            row-gap: 20px;
            .main-paragraph-container {
              font-size: 16px;
            }
          }

          .right-info-content {
            max-width: 300px;
            row-gap: 12px;
          }
        }

        .bottom-info-content {
          row-gap: 12px;
          column-gap: 12px;

          .sub-button-container {
            max-height: 34px;
          }
        }
      }
    }
  }
  @media (max-width: 769px) {
    .complience-screen-container {
      --main-padding-bottom: 16px;
      padding: var(--burger-height) 0 var(--main-padding-bottom);
      flex-direction: column;

      .main-title-container {
        padding-left: var(--controller-padding);
      }

      .complience-screen-content {
        padding: 0;

        .top-info-content {
          max-height: 275px;
          height: 100%;
          flex-direction: column;

          .main-paragraph-container {
            line-height: 120%;
            font-size: 14px;
          }

          .left-info-content {
            padding: 8px var(--controller-padding);
            row-gap: 20px;
            .main-paragraph-container {
              font-size: 12px;
            }
          }

          .right-info-content {
            max-width: 100%;
            row-gap: 2px;

            .sub-button-container {
              max-height: 48px;
              min-height: 48px;
              height: 100%;
            }
          }
        }

        .bottom-info-content {
          flex-direction: column;
          flex-wrap: nowrap;
          width: 100%;
          row-gap: 2px;
          column-gap: 2px;

          .sub-button-container {
            max-height: 34px;
            min-height: 34px;
            max-width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .complience-screen-container {
      --main-padding-bottom: 16px;

      .complience-screen-content {
        .top-info-content {
          max-height: 295px;

          .main-paragraph-container {
            font-size: 14px;
          }

          .left-info-content {
            row-gap: 20px;
          }

          .right-info-content {
            row-gap: 2px;

            .sub-button-container {
              max-height: 50px;
              min-height: 50px;
            }
          }
        }

        .bottom-info-content {
          row-gap: 2px;
          column-gap: 2px;

          .sub-button-container {
            max-height: 38px;
            min-height: 38px;
          }
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .complience-screen-container {
      --main-padding-bottom: 16px;

      .complience-screen-content {
        .top-info-content {
          max-height: 238px;

          .main-paragraph-container {
            font-size: 14px;
          }

          .left-info-content {
            row-gap: 20px;
          }

          .right-info-content {
            row-gap: 2px;

            .sub-button-container {
              max-height: 38px;
              min-height: 38px;
            }
          }
        }

        .bottom-info-content {
          row-gap: 2px;
          column-gap: 2px;

          .sub-button-container {
            padding: 8px 20px;
            max-height: 30px;
            min-height: 30px;
          }
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .complience-screen-container {
      --main-padding-bottom: 16px;

      .complience-screen-content {
        .top-info-content {
          max-height: 270px;

          .main-paragraph-container {
            font-size: 14px;
          }

          .left-info-content {
            row-gap: 20px;
          }

          .right-info-content {
            row-gap: 2px;

            .sub-button-container {
              max-height: 46px;
              min-height: 46px;
            }
          }
        }

        .bottom-info-content {
          row-gap: 2px;
          column-gap: 2px;

          .sub-button-container {
            max-height: 34px;
            min-height: 34px;
          }
        }
      }
    }
  }


  @media (max-width: 320px) {
    .complience-screen-container {
      --main-padding-bottom: 16px;

      .complience-screen-content {
        .top-info-content {
          max-height: 238px;

          .main-paragraph-container {
            font-size: 10px;
          }

          .left-info-content {
            row-gap: 20px;
          }

          .right-info-content {
            row-gap: 2px;

            .sub-button-container {
              max-height: 38px;
              min-height: 38px;
            }
          }
        }

        .bottom-info-content {
          row-gap: 2px;
          column-gap: 2px;

          .sub-button-container {
            padding: 8px 20px;
            max-height: 30px;
            min-height: 30px;
          }
        }
      }
    }
  }

  @media (min-width: 3841px) {
    .complience-screen-container {
      .complience-screen-content {

        padding: 0 288px 160px;

        .top-info-content {
          .left-info-content {
            row-gap: 84px;
          }

          .right-info-content {
            max-width: 900px;
            row-gap: 32px;
          }
        }

        .bottom-info-content {
          row-gap: 32px;
          column-gap: 32px;
          flex-direction: column;
          height: 12%;

          .sub-button-container {
            max-height: 96px;
          }
        }
      }
    }
  }

</style>