<template>
  <div class="start-screen-container">
    <transition name="fade-opacity-lazy">
      <toggle-theme-local :name-check="'start'" v-if="firstAnimation && !activeMobile"/>
    </transition>

    <transition name="fade-opacity-lazy">
      <icon-arrow v-if="activeArrow && !activeMobile"
                  @click="slideTo"
                  direction="bottom"/>
    </transition>

    <div class="start-screen-content">

      <!--      START TITLE-->

      <div class="title one" :class="{'eng' : activeEng}" >
        <transition name="fade-opacity-move-x">
          <p v-if="firstAnimation">
            {{$t('startScreen.firstTitle')}}
          </p>
        </transition>
      </div>

      <!--      LOGO-->


      <div class="logo" v-if="activeColor">
        <transition name="fade-opacity-animation">
          <img class="logo-icon" v-if="oneAnimationLogo && !activeMobile" :src="getUrlLogo(logoIcon + '-dark.svg')" alt="logo-icon">
        </transition>
        <transition name="fade-opacity-animation">
          <img class="logo-text" v-if="twoAnimationLogo && !activeMobile" :src="getUrlLogo(logoText + '-dark.svg')" alt="logo-icon">
        </transition>
        <transition name="fade-opacity-animation">
          <img class="logo-text" v-if="twoAnimationLogo && activeMobile" :src="getUrlLogo(logoText + '-mobile-dark.svg')" alt="logo-icon">
        </transition>
      </div>

      <div class="logo" v-else>
        <transition name="fade-opacity-animation">
          <img class="logo-icon" v-if="oneAnimationLogo && !activeMobile" :src="getUrlLogo(logoIcon + '-light.svg')" alt="logo-icon">
        </transition>
        <transition name="fade-opacity-animation">
          <img class="logo-text" v-if="twoAnimationLogo  && !activeMobile" :src="getUrlLogo(logoText + '-light.svg')" alt="logo-icon">
        </transition>
        <transition name="fade-opacity-animation">
          <img class="logo-text" v-if="twoAnimationLogo  && activeMobile" :src="getUrlLogo(logoText + '-mobile-light.svg')" alt="logo-icon">
        </transition>
      </div>

      <!--      END TITLE-->

      <div class="title two"  :class="{'eng' : activeEng}">
        <transition name="fade-opacity-move-x-revert">
          <p v-if="threeAnimation">
            {{$t('startScreen.secondTitle')}}
          </p>
        </transition>
      </div>
    </div>

  </div>
</template>

<script>
import ToggleThemeLocal from "@/components/entities/ToggleThemeLocal.vue";
import IconArrow from "@/components/shared/icons/IconArrow.vue";

export default {
  name: "StartScreen",
  components: {IconArrow, ToggleThemeLocal},
  data() {
    return {
      logoIcon: 'circle',
      logoText: 'visioners',
      cookieActive: false,
      firstAnimation: false,
      oneAnimationLogo: false,
      twoAnimationLogo: false,
      threeAnimation: false,
      activeArrow: false,
    }
  },
  mounted() {
    this.startFirstScreen()
    this.updateBlock();
  },
  methods: {
    updateBlock() {
      this.$emit('blockNextSlide', false)
      this.$emit('blockPrevSlide', false)
    },
    startFirstScreen() {
      setTimeout(() => this.firstAnimation = true, 100)
      setTimeout(() => this.oneAnimationLogo = true, 1000)
      setTimeout(() => this.twoAnimationLogo = true, 2000)
      setTimeout(() => this.threeAnimation = true, 2500)
      setTimeout(() => this.activeArrow = true, 1500)
      if(!Boolean(localStorage.getItem('cookieActive'))) {
        setTimeout(this.changeTrueCookie, 4500);
      }
    },

    slideTo() {
      this.$emit('slideTo')
    },

    changeTrueCookie() {
      this.cookieActive = true
    },

    changeFalseCookie(item) {
      this.cookieActive = item;
      if(this.cookieActive === false) {
        this.$emit('checkCookie')
      }
    }
  },
}
</script>

<style scoped lang="scss">
.start-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: var(--calc-height);
  width: 100%;

  .icon-arrow-container {
    position: absolute;
    bottom: 0;
    left: 50%; right: 50%;
    transform: translateX(-50%);
  }

  .toggle-container {
    position: absolute;
  }

  .start-screen-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .title {
      line-height: 150%;
      white-space: pre-wrap;

      &.two {
        align-self: flex-end;
      }

    }

    .logo {
      display: flex;
      flex-direction: row;
      align-self: center;
      width: 100%;
      .logo-icon {
        width: 100%;
        height: 100%;
      }

      .logo-text {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.eng {
  line-height: 150%;
  letter-spacing: 0.075em;
}

@media (max-width: 3024px){
  .start-screen-container {
    .toggle-container {
      right: 200px;
      top: 100px
    }

    .icon-arrow-container {
      bottom: 100px;
    }


    .start-screen-content {
      max-height: 551px;
      padding: 0 300px 0 300px;

      .title {
        letter-spacing: 1.2px;
        font-size: 18px;

        &.two {
          height: 27px;
        }
      }

      .logo {
        margin: 112px 0;
        max-width: 1370px;
        max-height: 195px;
        .logo-icon {
          max-width: 358px;
          margin-right: 108px;
        }

        .logo-text {
          max-height: 156px;
        }
      }
    }
  }
}

@media (max-width: 2100px) {
  .start-screen-container {
    .toggle-container {
      right: 100px;
      top: 50px
    }

    .icon-arrow-container {
      bottom: 50px;
    }


    .start-screen-content {
      max-height: 493px;
      padding: 0 200px;

      .title {
        letter-spacing: 1.2px;
        font-size: 16px;

        &.two {
          height: 24px;
        }
      }

      .logo {
        margin: 92px 0;
        max-width: 1155px;
        max-height: 164px;
        .logo-icon {
          max-width: 298px;
          margin-right: 86px;
        }

        .logo-text {
          max-height: 130px;
        }
      }
    }
  }
}

@media (max-width: 1750px) {
  .start-screen-container {

    .toggle-container {
      right: 100px;
      top: 50px
    }

    .icon-arrow-container {
      bottom: 50px;
    }

    .start-screen-content {
      max-height: 412px;
      padding: 0 150px;

      .title {
        letter-spacing: 1.05px;
        font-size: 14px;

        &.two {
          height: 21px;
        }
      }

      .logo {
        margin: 72px 0;
        max-width: 889px;
        max-height: 126px;
        .logo-icon {
          max-width: 230px;
          margin-right: 67px;
        }

        .logo-text {
          max-height: 100px;
        }
      }
    }
  }
}

@media (max-height: 800px) {
  .start-screen-container {
    .toggle-container {
      right: 50px;
      top: 25px
    }

    .icon-arrow-container {
      bottom: 25px;
    }
    .start-screen-content {
      max-height: 348px;
      padding: 0 125px;

      .title {
        letter-spacing: 0.75px;
        font-size: 10px;

        &.two {
          height: 15px;
        }
      }

      .logo {
        margin: 55px 0;
        max-width: 681px;
        max-height: 98px;
        .logo-icon {
          max-width: 176px;
          margin-right: 51px;
        }

        .logo-text {
          max-height: 77px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .start-screen-container {

    .toggle-container {
      right: 100px;
      top: 50px
    }

    .icon-arrow-container {
      bottom: 50px;
    }

    .start-screen-content {
      max-height: 412px;
      padding: 0 150px;

      .title {
        letter-spacing: 1.05px;
        font-size: 14px;

        &.two {
          height: 21px;
        }
      }

      .logo {
        margin: 72px 0;
        max-width: 889px;
        max-height: 126px;
        .logo-icon {
          max-width: 230px;
          margin-right: 67px;
        }

        .logo-text {
          max-height: 100px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .start-screen-container {
    .toggle-container {
      right: 50px;
      top: 25px
    }

    .icon-arrow-container {
      bottom: 25px;
    }
    .start-screen-content {
      max-height: 348px;
      padding: 0 125px;

      .title {
        letter-spacing: 0.75px;
        font-size: 10px;

        &.two {
          height: 15px;
        }
      }

      .logo {
        margin: 55px 0;
        max-width: 681px;
        max-height: 98px;
        .logo-icon {
          max-width: 176px;
          margin-right: 51px;
        }

        .logo-text {
          max-height: 77px;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .start-screen-container {
    padding-bottom: 36px;

    .start-screen-content {
      max-height: 210px;
      padding: 0 20px;

      .title {
        font-weight: 200;
        letter-spacing: 0.9px;
        font-size: 12px;

        &.two {
          height: 12px;
        }
      }

      .logo {
        margin: 64px 0;
        max-width: 200px;
        max-height: 39px;

        .logo-text {
          filter: drop-shadow(3.392px 5.654px 8.481px rgba(0, 0, 0, 0.10));
          max-height: 39px;
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 748px) and (max-height: 1000px) {
  .start-screen-container {

    .start-screen-content {
      max-height: 255px;
      padding: 0 24px;

      .title {
        font-weight: 200;
        letter-spacing: 1.05px;
        font-size: 14px;

        &.two {
          height: 14px;
        }
      }

      .logo {
        margin: 80px 0;
        max-width: 240px;
        max-height: 45px;

        .logo-text {
          max-height: 45px;
        }
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

}

@media (max-width: 375px){
  .start-screen-container {

    .start-screen-content {
      max-height: 210px;
      padding: 0 20px;

      .title {
        font-weight: 200;
        letter-spacing: 0.9px;
        font-size: 12px;

        &.two {
          height: 12px;
        }
      }

      .logo {
        margin: 64px 0;
        max-width: 200px;
        max-height: 39px;

        .logo-text {
          max-height: 39px;
        }
      }
    }
  }
}

@media (min-width: 3025px) {
  .start-screen-container {

    .icon-arrow-container {
      bottom: 100px;
    }

    .toggle-container {
      right: 400px;
      top: 200px
    }

    .start-screen-content {
      max-height: 910px;
      padding: 0 600px 0 500px;

      .title {
        letter-spacing: 2.4px;
        font-size: 32px;

        &.two {
          height: 48px;
        }
      }

      .logo {
        margin: 164px 0;
        max-width: 2064px;
        max-height: 290px;
        .logo-icon {
          margin-right: 160px;
          max-width: 529px;
        }

        .logo-text {
          max-height: 230px;
          width: fit-content;
        }
      }
    }
  }
}
</style>