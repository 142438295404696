<template>
  <div class="loader-container">
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped lang="scss">
  .loader-container {
    min-width: 37px !important;
    max-width: 37px !important;
    max-height: 37px !important;
    min-height: 37px !important;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid var(--color-main-visioners);
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>