<template>
  <div class="card-team-container" :class="{'eng' : activeEng}">
    <avatar-team :img-divisioner="teamInfo.img"/>
    <div class="team-text-container">
      <p class="main-title-container">
        {{ teamInfo.name }}
      </p>
      <p class="main-paragraph-container">
        {{ teamInfo.description }}
      </p>
    </div>
  </div>
</template>

<script>
import AvatarTeam from "@/components/entities/AvatarTeam.vue";

export default {
  name: "CardTeam",
  components: {AvatarTeam},
  props: {
    teamInfo: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .card-team-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;


    .team-text-container {
      display: flex;
      flex-direction: column;

      .main-title-container {
        color: var(--color-main-title);
      }

      .main-paragraph-container {
        white-space: pre-wrap;
        color: var(--color-main-text);
        line-height: 150%;
      }
    }
  }

  @media (max-width: 3024px){
    .card-team-container {
      max-width: 665px;
      max-height: 330px;
      column-gap: 64px;

      .avatar-team-container {
        min-width: 209px;
        min-height: 330px;
        max-width: 209px;
        max-height: 330px;
        border-radius: 44px 22px 11px 5.5px;
      }

      .team-text-container {
        padding-top: 32px;
        row-gap: 24px;

        .main-title-container {
          font-size: 24px;
        }

        .main-paragraph-container {
          font-size: 22px;
        }
      }
    }
  }

  @media (max-width: 2100px) {
    .card-team-container {
      max-width: 515px;
      max-height: 240px;
      column-gap: 48px;

      .avatar-team-container {
        min-width: 152px;
        min-height: 240px;
        max-width: 152px;
        max-height: 240px;
        border-radius: 32px 16px 8px 4px;
      }

      .team-text-container {
        padding-top: 24px;
        row-gap: 16px;

        .main-title-container {
          font-size: 20px;
        }

        .main-paragraph-container {
          font-size: 18px;
        }
      }
    }
  }


  @media (max-width: 1750px) {
    .card-team-container {
      max-width: 394px;
      max-height: 180px;
      column-gap: 32px;

      .avatar-team-container {
        min-width: 114px;
        min-height: 180px;
        max-width: 114px;
        max-height: 180px;
        border-radius: 24px 12px 6px 3px;
      }

      .team-text-container {
        padding-top: 20px;
        row-gap: 12px;

        .main-title-container {
          font-size: 16px;
        }

        .main-paragraph-container {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .card-team-container {
      max-width: 290px;
      max-height: 150px;
      column-gap: 24px;

      .avatar-team-container {
        min-width: 95px;
        min-height: 150px;
        max-width: 95px;
        max-height: 150px;
        border-radius: 20px 10px 5px 2.5px;
      }

      .team-text-container {
        padding-top: 16px;
        row-gap: 10px;

        .main-title-container {
          font-size: 12px;
        }

        .main-paragraph-container {
          font-size: 10px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-team-container {
      max-width: 394px;
      max-height: 180px;
      column-gap: 32px;

      .avatar-team-container {
        min-width: 114px;
        min-height: 180px;
        max-width: 114px;
        max-height: 180px;
        border-radius: 24px 12px 6px 3px;
      }

      .team-text-container {
        padding-top: 20px;
        row-gap: 12px;

        .main-title-container {
          font-size: 16px;
        }

        .main-paragraph-container {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    .card-team-container {
      max-width: 100%;
      max-height: 150px;
      column-gap: 24px;

      .avatar-team-container {
        min-width: 95px;
        min-height: 150px;
        max-width: 95px;
        max-height: 150px;
        border-radius: 20px 10px 5px 2.5px;
      }

      .team-text-container {
        max-width: 200px;
        padding-top: 16px;
        row-gap: 10px;

        .main-title-container {
          font-size: 14px;
        }

        .main-paragraph-container {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-team-container {
      max-width: 100%;
      max-height: 150px;
      column-gap: 24px;

      .avatar-team-container {
        min-width: 95px;
        min-height: 150px;
        max-width: 95px;
        max-height: 150px;
        border-radius: 20px 10px 5px 2.5px;
      }

      .team-text-container {
        max-width: 240px;
        padding-top: 16px;
        row-gap: 10px;

        .main-title-container {
          font-size: 14px;
        }

        .main-paragraph-container {
          font-size: 12px;
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .card-team-container {
      max-width: 100%;
      max-height: 128px;
      column-gap: 24px;

      .avatar-team-container {
        min-width: 80px;
        min-height: 128px;
        max-width: 80px;
        max-height: 128px;
        border-radius: 17px 8px 4.5px 2.1px;
      }

      .team-text-container {
        padding-top: 16px;
        row-gap: 10px;

        .main-title-container {
          font-size: 12px;
        }

        .main-paragraph-container {
          font-size: 10px;
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .card-team-container {
      max-width: 100%;
      max-height: 150px;
      column-gap: 24px;

      .avatar-team-container {
        min-width: 95px;
        min-height: 150px;
        max-width: 95px;
        max-height: 150px;
        border-radius: 20px 10px 5px 2.5px;
      }

      .team-text-container {
        padding-top: 16px;
        row-gap: 10px;

        .main-title-container {
          font-size: 14px;
        }

        .main-paragraph-container {
          font-size: 12px;
        }
      }
    }
  }


  @media (max-width: 320px) {
    .card-team-container {
      max-width: 100%;
      max-height: 115px;
      column-gap: 16px;

      .avatar-team-container {
        min-width: 60px;
        min-height: 98px;
        max-width: 60px;
        max-height: 98px;
        border-radius: 15px 6px 3px 1.5px;
      }

      .team-text-container {
        padding-top: 16px;
        row-gap: 10px;

        .main-title-container {
          font-size: 10px;
        }

        .main-paragraph-container {
          font-size: 8px;
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .card-team-container {
      max-width: 1190px;
      max-height: 540px;
      column-gap: 128px;
      margin-bottom: 64px;

      .avatar-team-container {
        min-width: 342px;
        min-height: 540px;
        max-width: 342px;
        max-height: 540px;
        border-radius: 72px 36px 18px 9px;
      }

      .team-text-container {
        padding-top: 64px;
        row-gap: 32px;

        .main-title-container {
          font-size: 44px;
        }

        .main-paragraph-container {
          font-size: 32px;
        }
      }
    }
  }

</style>