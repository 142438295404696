<template>
  <div class="circle-container">
    <transition name="fade-opacity-move-y-revert">
      <div class="circle-content" :key="1">
        <div  class="circle" :class="orangeActive === 0 ? 'orange' : ''"></div>
      </div>
    </transition>
    <transition name="fade-opacity-move-y-revert">
      <div class="circle-content" :key="1">
        <div  class="circle" :class="orangeActive === 1 ? 'orange' : ''"></div>
      </div>
    </transition>
    <transition name="fade-opacity-move-y-revert">
      <div class="circle-content" :key="1">
        <div  class="circle" :class="orangeActive === 2 ? 'orange' : ''"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "CircleBlock",
  data() {
    return {
      orangeActive: -1
    }
  },
  mounted() {
    this.changeOrange();
  },
  methods: {
    changeOrange() {
      if(this.orangeActive !== 2) {
        setTimeout(() => {
          this.orangeActive++;
          this.changeOrange();
        },500)
      } else {
        setInterval(() => {
          if(this.orangeActive === 2) {
            this.orangeActive = 0
          } else {
            this.orangeActive++;
          }
        },2000)
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .circle-container {
    display: flex;
    flex-direction: row;

    .circle-content {
      .circle {
        border-radius: 100%;
        overflow: hidden;
        background-color: var(--color-main-title);
        transition: background-color .5s ease;
        &.orange {
          background-color: var(--color-main-visioners);
        }
      }
    }
  }

  @media (max-width: 3024px){
        .circle-container {
          width: 144px;
          column-gap: 24px;
          .circle-content {
            .circle {
              width: 22px;
              height: 22px;
            }
          }
        }
  }

  @media (max-width: 2100px) {
        .circle-container {
          width: 98px;
          column-gap: 16px;
          .circle-content {

            .circle {
              width: 18px;
              height: 18px;
            }
          }
        }
  }
  @media (max-height: 800px) {
        .circle-container {
          width: 98px;
          column-gap: 16px;
          .circle-content {
            .circle {
              width: 14px;
              height: 14px;
            }
          }
        }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
        .circle-container {
          width: 98px;
          column-gap: 16px;
          .circle-content {
            .circle {
              width: 14px;
              height: 14px;
            }
          }
        }
  }

  @media (max-width: 769px) {
    .circle-container {
      width: 35px;
      column-gap: 8px;
      .circle-content {
        .circle {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 748px) and (max-height: 1000px) {

  }

  @media (max-width: 375px){

  }

  @media (max-width: 375px) and (min-height: 630px) {

  }

  @media (max-width: 320px) {

  }


  @media (min-width: 3025px) {
        .circle-container {
          width: 256px;
          column-gap: 32px;
          .circle-content {
            .circle {
              width: 32px;
              height: 32px;
            }
          }
        }
  }
</style>