<template>
  <div class="footer-screen-container">


      <div class="top-info-container">

        <div class="top-footer-content">
            <div class="top-info-content">
              <transition name="fade-opacity-animation" mode="out-in">
              <animation-text @endAnimation="endAnimationText"
                              :key="activeIndexSignature"
                              :active-color-text="'visioners'"
                              :text-info="mobileMainTile"/>
              </transition>
            </div>


          <transition name="fade-opacity-animation" mode="out-in">
            <div class="center-info-content" :key="activeIndexSignature" v-if="activeSignature">

              <p class="signature-title-content" >
                {{ mobileSignatureContent.mainTitle }}
              </p>

              <div class="signature-content">
                <img v-if="activeColor" :src="getUrlFooter(mobileSignatureContent.signatureIcon + '-dark.svg')" alt="">
                <img v-else :src="getUrlFooter(mobileSignatureContent.signatureIcon + '-light.svg')" alt="">
              </div>
            </div>
          </transition>
        </div>

        <transition name="fade-opacity-animation">
          <div class="bottom-info-container" v-if="activeContact">
            <div class="top-contact-container">
              <div class="bottom-info-content" v-for="item in $tm('footerScreen.footerBottomInfo').slice(0,2).reverse()">
                <p class="main-title-container">
                  {{ item.mainTitle }}
                </p>
                <div class="bottom-info-text-container">
                  <p class="sub-title-container" v-if="item?.email">
                    <a  :href="'mailto:' + item.email">
                      {{ item.email }}
                    </a>
                  </p>
                  <p class="sub-title-container" v-if="item?.contact">
                    <a :href="'mailto:' + item.contact">
                      {{ item.contact }}
                    </a>
                  </p>
                  <p class="sub-title-container">
                    {{ item.subTitleOne }}
                  </p>
                  <p class="sub-title-container">
                    {{ item.subTitleTwo }}
                  </p>
                </div>
              </div>
            </div>
            <div class="bottom-info-content">
              <p class="main-title-container">
                {{ $tm('footerScreen.footerBottomInfo')[2].mainTitle }}
              </p>
              <div class="bottom-info-text-container">
                <p class="sub-title-container">
                  {{ $tm('footerScreen.footerBottomInfo')[2].subTitleOne }}
                </p>
                <p class="sub-title-container">
                  {{ $tm('footerScreen.footerBottomInfo')[2].subTitleTwo }}
                </p>
              </div>
            </div>
          </div>
        </transition>

      </div>

    <transition name="fade-translate-y-revert">
      <div class="footer-container" v-if="activeFooter">
        <div class="top-info-content">
          <p class="sub-title-container button" @click="changeStateModalConsulting">
            {{ $t('buttonTextController') }}
          </p>
          <p class="main-title-container">
            <a :href="'tel:' + $tm('footerScreen.footerBottomInfo')[0].tel">
              {{ $tm('footerScreen.footerBottomInfo')[0].tel }}
            </a>
          </p>
        </div>

        <p class="sub-title-container" :class="{'eng' : activeEng}">
          {{ $tm('footerScreen.footerBottomInfo')[3].dataTwo }}
        </p>

        <p class="sub-title-container">
          {{ $tm('footerScreen.footerStatic')[0] }}
          {{ $tm('footerScreen.footerStatic')[1] }}
        </p>

        <p class="sub-title-container">
          {{ `© 2014 — ${new Date().getFullYear()} HoldCo «visioners»` }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import AnimationText from "@/components/entities/AnimationText.vue";
import PartnersScreen from "@/components/features/main-page/PartnersScreen.vue";
import ModalConsulting from "@/components/entities/modal/ModalConsulting.vue";
import {mapActions} from "vuex";

export default {
  name: "FooterScreenMobile",
  components: {ModalConsulting, PartnersScreen, AnimationText},
  data() {
    return {
      activeIndexSignature: 0,
      activeSignature: false,
      intervalBlock: undefined,
      activeFooter: false,
      activeContact: false,
    }
  },
  mounted() {

  },

  unmounted() {
    clearInterval(this.intervalBlock)
  },

  computed: {
    mobileSignatureContent() {
      return this.$tm('mobileSignatureContent')[this.activeIndexSignature]
    },
    mobileMainTile() {
      if(this.activeIndexSignature === 1) {
        return this.$t('footerScreen.mainTitleSecond')
      } else {
        return this.$t('footerScreen.mainTitle')
      }
    }
  },
  methods: {
    ...mapActions(['IS_ACTIVE_MODAL_CONSULTING']),
    endAnimationText() {
        this.activeSignature = true
        this.activeFooter = true
        setTimeout(() => {
          this.activeContact = true
        }, 250)
        this.activeAnimationSignature();
    },

    activeAnimationSignature() {
      setTimeout(() => {
        this.activeSignature = false
        if(this.activeIndexSignature === 0) {
          this.activeIndexSignature = this.activeIndexSignature + 1;
        } else {
          this.activeIndexSignature = 0
        }
      }, 7000)
    },

    changeStateModalConsulting() {
      this.IS_ACTIVE_MODAL_CONSULTING(true)
    }
  }
}
</script>

<style scoped lang="scss">


.sub-title-container {
  & a {
    opacity: .6;
  }

  &.button {
    color: var(--color-main-visioners)
  }
}
.top-contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
  .footer-screen-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: var(--calc-height);
    width: 100%;


    .top-info-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: calc(var(--burger-height) + var(--padding-top-footer)) var(--padding-left-right-footer) var(--padding-bottom-footer);

      .top-footer-content {
        display: flex;
        flex-direction: column;
      }

      .center-info-content {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;

        .signature-title-content {
          white-space: pre-wrap;
          line-height: 150%;
        }

        .signature-content {
          width: 100%;
          height: 100%;

          & img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .bottom-info-container {
        display: flex;
        flex-direction: column;

        .bottom-info-content {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .footer-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;;
      height: 100%;
      background-color: var(--color-button-cookie);
      padding: var(--padding-bottom-footer) var(--padding-left-right-footer) var(--padding-bottom-footer);

      .sub-title-container {
        white-space: pre-wrap;
        text-align: center;
      }

      .top-info-content {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .bottom-info-text-container {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

@media (max-width: 769px) {

  .sub-title-container {
    font-size: 12px;
  }

  .footer-screen-container {
    --padding-top-footer: 24px;
    --padding-left-right-footer: 16px;
    --padding-bottom-footer: 32px;

    .top-info-container {

      .top-footer-content {
        row-gap: 16px;
      }


      .center-info-content {
        height: 99px;


        .signature-title-content {
          font-size: 9px;
        }

        .signature-content {
          max-width: 110px;
          max-height: 99px;
        }
      }

      .bottom-info-container {
        row-gap: 24px;


        .bottom-info-content {
          row-gap: 10px;
        }
      }
    }

    .footer-container {
      --padding-bottom-footer: 12px;
      --padding-top-footer: 12px;
      max-height: 235px;

      .main-title-container {
        font-size: 16px;
      }

      .sub-title-container {
        font-size: 12px;
        &.button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
        }
      }

      .top-info-content {
        row-gap: 16px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {

  .sub-title-container {
    font-size: 12px;
  }

  .footer-screen-container {
    --padding-top-footer: 32px;
    --padding-left-right-footer: 24px;
    --padding-bottom-footer: 56px;

    .top-info-container {

      .top-info-content {
        margin-bottom: 16px;
      }

      .center-info-content {
        height: 108px;

        .signature-title-content {
          font-size: 10px;
        }

        .signature-content {
          max-width: 120px;
          max-height: 108px;
        }
      }

      .bottom-info-container {
        row-gap: 32px;

        .bottom-info-content {
          row-gap: 10px;
        }
      }
    }

    .footer-container {
      --padding-bottom-footer: 16px;
      max-height: 235px;

      .sub-title-container {
        font-size: 12px;

      }

      .top-info-content {
        row-gap: 14px;
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){

  .sub-title-container {
    font-size: 10px;
  }

  .footer-screen-container {
    --padding-top-footer: 24px;
    --padding-left-right-footer: 16px;
    --padding-bottom-footer: 24px;

    .top-info-container {

      .top-footer-content {
        row-gap: 16px;
      }


      .center-info-content {
        height: 89px;

        .signature-title-content {
          font-size: 8px;
        }

        .signature-content {
          max-width: 100px;
          max-height: 89px;
        }
      }

      .bottom-info-container {
        row-gap: 16px;

        .bottom-info-content {
          row-gap: 10px;
        }
      }
    }

    .footer-container {
      --padding-bottom-footer: 10px;
      --padding-top-footer: 10px;
      max-height: 147px;

      .sub-title-container {
        font-size: 10px;
        &.button {
          display: none;
        }
      }

      .top-info-content {
        row-gap: 12px;
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {

  .sub-title-container {
    font-size: 12px;
  }

  .footer-screen-container {
    --padding-top-footer: 24px;
    --padding-left-right-footer: 16px;
    --padding-bottom-footer: 32px;

    .top-info-container {

      .top-footer-content {
        row-gap: 16px;
      }


      .center-info-content {
        height: 89px;

        .signature-title-content {
          font-size: 9px;
        }

        .signature-content {
          max-width: 100px;
          max-height: 89px;
        }
      }

      .bottom-info-container {
        row-gap: 16px;

        .bottom-info-content {
          row-gap: 10px;
        }
      }
    }

    .footer-container {
      --padding-bottom-footer: 12px;
      --padding-top-footer: 12px;
      max-height: 235px;

      .main-title-container {
        font-size: 12px;
      }

      .sub-title-container {
        font-size: 12px;
        &.button {
          display: block;
        }
      }

      .top-info-content {
        row-gap: 16px;
      }
    }
  }
}


@media (max-width: 320px) {

  .sub-title-container {
    font-size: 8px;
  }

  .footer-screen-container {
    --padding-top-footer: 24px;
    --padding-left-right-footer: 16px;
    --padding-bottom-footer: 24px;

    .top-info-container {

      .top-footer-content {
        row-gap: 16px;
      }


      .center-info-content {
        height: 79px;


        .signature-title-content {
          font-size: 7px;
        }

        .signature-content {
          max-width: 90px;
          max-height: 79px;
        }
      }

      .bottom-info-container {
        row-gap: 16px;


        .main-title-container {
          font-size: 14px;
        }

        .bottom-info-content {
          row-gap: 10px;
        }
      }
    }

    .footer-container {
      --padding-bottom-footer: 10px;
      --padding-top-footer: 10px;
      max-height: 147px;

      .sub-title-container {
        font-size: 10px;
        &.button {
          display: none;
        }
      }

      .top-info-content {
        row-gap: 12px;
      }
    }
  }
}

</style>