<template>
  <div class="line-controller-content"
       @mouseenter="focusTrue"
       @click="changeActiveLine(lineText.id, lineText.text)"
       @mouseleave="focusFalse">
      <div class="text-content">
        <div class="img-content" v-if="lineText?.img !== undefined" :class="{'active' : activeLine === lineText.id}">
          <transition name="fade-opacity-lazy">
            <img v-if="activeColor"
                 :src="getUrlHistory(lineText.img + '-dark.svg')" alt="">
            <img v-else
                 :src="getUrlHistory(lineText.img + '-light.svg')" alt="">
          </transition>
        </div>
        <p class="text" v-if="lineText?.text !== undefined"
           :class="{'active' : activeLine === lineText.id || focusParam,
        'eng' : activeEng, 'active-vision': lineText.text === 'vision'}">{{ lineText.text }}</p>
      </div>
      <div class="line"></div>
      <div class="line-active"
           :class="activeLine === lineText.id || focusParam ? 'active' : ''">
      </div>
    </div>
</template>

<script>

export default {
  name: "LineController",
  props: {
    lineText: {
      type: Object,
      required: true
    },
    activeLine: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      focusParam: false
    }
  },
  methods: {
    changeActiveLine(id, position) {
      this.$emit('changeActiveLine',id, position)
    },
  }
}
</script>

<style scoped lang="scss">
.line-controller-content {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;


    &:hover {
      cursor: pointer;

      .text-content {
        .text {
          opacity: 1;
        }
        .img-content {
          opacity: 1;
        }
      }
    }

    .text-content {
      display: flex;
      align-items: center;
      flex-direction: row;
      .img-content {
        opacity: .3;
        position: relative;
        transition: all .6s ease;
        & img {
          width: 100%;
          height: inherit;
          object-fit: contain;
        }

        &.active {
          opacity: 1;
        }
      }

      .text {
        white-space: nowrap;
        opacity: .3;
        transition: all .6s ease;

        &.active-vision {
          font-variant: all-small-caps;
        }

        &:hover {
          color: var(--color-main-title)
        }

        &.active {
          opacity: 1;
          color: var(--color-main-title)
        }
      }
    }

    .line {
      opacity: .3;
      position: absolute;
      content: "";
      width: 100%;
      bottom: 0;
      background-color: var(--color-main-title);
    }

    .line-active {
      position: absolute;
      content: "";
      width: 100%;
      bottom: 0;
      background-color: var(--color-main-visioners);
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.5s;

      &.active {
        transform: scaleX(1);
        transform-origin: left;
        transition: transform 0.5s;
      }
    }
}

@media (max-width: 3024px){
  .line-controller-content {
    height: 64px;

    .text-content {
      .img-content {
        height: 22px;
        margin-right: 12px;

      }
      .text {
        font-size: 18px;
      }
    }

    .line {
      height: 4px;
    }

    .line-active {
      height: 4px;
    }
  }
}


@media (max-width: 2100px) {
  .line-controller-content {
    height: 59px;

    .text-content {
      .img-content {
        height: 17px;
        margin-top: 2px;
        margin-bottom: 0;
        margin-right: 12px;
      }
      text {
        font-size: 16px;
      }
    }

    .line {
      height: 3px;
    }

    .line-active {
      height: 3px;
    }
  }
}


@media (max-width: 1750px) {
  .line-controller-content {
    height: 49px;

    .text-content {
      .img-content {
        display: flex;
        max-width: 34px;
        width: 100%;
        flex-direction: row;
        align-items: center;
        height: 12px;
        margin-right: 12px;

        & img {
          object-fit: contain;
        }
      }

      .text {
        font-size: 14px;
      }
    }
    .line {
      height: 2px;
    }

    .line-active {
      height: 2px;
    }
  }
}
@media (max-height: 800px) {
  .line-controller-content {
    height: 36px;

    .text-content {
      .img-content {
        display: flex;
        max-width: 25px;
        flex-direction: row;
        align-items: center;
        height: 12px;
        margin-right: 12px;
      }

      .text {
        font-size: 12px;
      }
    }
    .line {
      height: 1px;
    }

    .line-active {
      height: 1px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .line-controller-content {
    height: 49px;

    .text-content {
      .img-content {
        display: flex;
        max-width: 34px;
        flex-direction: row;
        align-items: center;
        height: 12px;
        margin-right: 12px;
      }

      .text {
        font-size: 14px;
      }
    }
    .line {
      height: 2px;
    }

    .line-active {
      height: 2px;
    }
  }
}

@media (max-width: 1133px) {
  .line-controller-content {
    height: 36px;

    .text-content {
      .img-content {
        display: flex;
        max-width: 25px;
        flex-direction: row;
        align-items: center;
        height: 12px;
        margin-right: 12px;
      }

      .text {
        font-size: 12px;
      }
    }
    .line {
      height: 1px;
    }

    .line-active {
      height: 1px;
    }
  }
}


@media (min-width: 3025px) {
  .line-controller-content {
    height: 90px;

    .text-content {
      align-items: center;
      .img-content {
        height: 38px;
        margin-right: 12px;
      }

      .text {
        font-size: 32px;
      }
    }

    .line {
      height: 5px;
    }

    .line-active {
      height: 5px;
    }
  }
}

</style>