<template>
  <div class="card-navigation-container">
    <div class="main-title-container">
      <medium-title>
        {{ cardInfo.mainTitle }}
      </medium-title>
    </div>
    <div class="navigation-info-container">
      <div class="navigation-info-content"
           @mouseenter="focusTrue(item.id)" @mouseleave="focusFalse"
           @click="changeActivePage(item.id)"
           v-for="item in cardInfo.navigation"
           :key="item.id"
           :class="{'eng' : activeEng}">
        <transition name="fade-scale">
          <icon-circle v-if="activeIndex === item.id"/>
        </transition>
        <span>
           {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MediumTitle from "@/components/shared/text/MediumTitle.vue";
import IconCircle from "@/components/shared/icons/IconCircle.vue";

export default {
  name: "CardNavigation",
  components: {IconCircle, MediumTitle},
  props: {
    cardInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeIndex: -1,
    }
  },
  methods: {
    changeActivePage(id) {
      this.$emit('changeActivePage', this.cardInfo.id, id)
    },
    focusTrue(id) {
      this.activeIndex = id
    },

    focusFalse() {
      this.activeIndex = -1
    }
  }
}
</script>

<style scoped lang="scss">

.card-navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 128px;
  background-color: var(--color-button-cookie);

  .medium-title-container {
      color: var(--color-main-title);
      text-transform: uppercase;
      font-weight: 200;
  }

  .navigation-info-container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    max-width: 362px;
    align-items: flex-start;
    width: 100%;
    .navigation-info-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      font-size: 42px;

      &:hover {
        cursor: pointer;
        & span {
          opacity: 1;
        }
      }

      .icon-circle-container {
        position: absolute;
        left: 0;
      }

      & span {
        transition: opacity .6s ease;
        opacity: .3;
        color: var(--color-button-text);
        margin-left: 56px;
      }
    }
  }
}

@media (max-width: 3024px){
  .card-navigation-container {
    padding: 64px;

    .navigation-info-container {
      row-gap: 20px;
      max-width: 200px;
      .navigation-info-content {
        font-size: 22px;
        & span {
          margin-left: 34px;
        }
      }
    }
  }
}


@media (max-width: 2100px) {
  .card-navigation-container {
    padding: 56px;

    .navigation-info-container {
      row-gap: 16px;
      max-width: 168px;
      .navigation-info-content {
        font-size: 18px;
        & span {
          margin-left: 30px;
        }
      }
    }
  }
}

@media (max-width: 1750px) {
  .card-navigation-container {
    padding: 56px;

    .navigation-info-container {
      row-gap: 12px;
      max-width: 168px;
      .navigation-info-content {
        font-size: 14px;
        & span {
          margin-left: 26px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .card-navigation-container {
    padding: 48px;

    .navigation-info-container {
      row-gap: 10px;
      max-width: 108px;
      .navigation-info-content {
        font-size: 12px;
        & span {
          margin-left: 20px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .card-navigation-container {
    padding: 56px;

    .navigation-info-container {
      row-gap: 12px;
      max-width: 168px;
      .navigation-info-content {
        font-size: 14px;
        & span {
          margin-left: 26px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .card-navigation-container {
    padding: 48px;

    .navigation-info-container {
      row-gap: 10px;
      max-width: 108px;
      .navigation-info-content {
        font-size: 12px;
        & span {
          margin-left: 20px;
        }
      }
    }
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .card-navigation-container {
    padding: 128px;

    .navigation-info-container {
      row-gap: 24px;
      max-width: 362px;
      .navigation-info-content {
        font-size: 42px;
        & span {
          margin-left: 56px;
        }
      }
    }
  }
}

</style>