<template>
  <div class="division-title-container">
    <div class="division-icon-container">
              <img class="icon" :src="getUrlDivision(infoTitle.icon + '-orange.svg')" alt="icon">
<!--        <img class="icon" v-if="activeColor" :src="getUrlDivision(infoTitle.icon + '-dark.svg')" alt="icon">-->
<!--        <img class="icon" v-else :src="getUrlDivision(infoTitle.icon + '-light.svg')" alt="icon">-->
    </div>
    <p class="division-title-content">
        {{ infoTitle.mainTitle }}
      <transition name="fade-scale">
        <span class="circle-container"/>
      </transition>
    </p>
  </div>
</template>

<script>
export default {
  name: "TextWithIcon",
  props: {
    infoTitle: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped lang="scss">
 .division-title-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   color: var(--color-main-title);

   .division-icon-container {

     & img {
       object-fit: contain;
       width: 100%;
       height: 100%;
     }
   }

   .division-title-content {
     position: relative;
     width: fit-content;
     .circle-container {
       border-radius: 50%;
       overflow: hidden;
       background-color: var(--color-main-visioners);
       position: absolute;
     }
   }
 }

 @media (max-width: 3024px){
   .division-title-container {
     column-gap: 32px;

     .division-icon-container {
       width: 32px;
       height: 32px;
     }

     .division-title-content {
       font-size: 24px;
       .circle-container {
         bottom: 3px;
         height: 4px;
         width: 4px;
         right: -6px;
       }
     }
   }
 }

 @media (max-width: 2240px) {
   .division-title-container {
     column-gap: 24px;

     .division-icon-container {
       width: 24px;
       height: 24px;
     }

     .division-title-content {
       font-size: 20px;
       .circle-container {
         bottom: 3px;
         height: 4px;
         width: 4px;
         right: -6px;
       }
     }
   }
 }

 @media (max-width: 1750px) {
   .division-title-container {
     column-gap: 16px;

     .division-icon-container {
       width: 18px;
       height: 18px;
     }

     .division-title-content {
       font-size: 16px;
       .circle-container {
         bottom: 6px;
         height: 3px;
         width: 3px;
         right: -5px;
       }
     }
   }
 }
 @media (max-height: 800px) {
   .division-title-container {
     column-gap: 12px;

     .division-icon-container {
       width: 14px;
       height: 14px;
     }

     .division-title-content {
       font-size: 12px;
       .circle-container {
         bottom: 7px;
         height: 2px;
         width: 2px;
         right: -3px;
       }
     }
   }
 }

 @media (max-width: 1400px) and (min-height: 801px) {
   .division-title-container {
     column-gap: 16px;

     .division-icon-container {
       width: 18px;
       height: 18px;
     }

     .division-title-content {
       font-size: 16px;
       .circle-container {
         bottom: 6px;
         height: 3px;
         width: 3px;
         right: -5px;
       }
     }
   }
 }

 @media (max-width: 1133px) {
   .division-title-container {
     column-gap: 12px;

     .division-icon-container {
       width: 14px;
       height: 14px;
     }

     .division-title-content {
       font-size: 12px;
       .circle-container {
         bottom: 7px;
         height: 2px;
         width: 2px;
         right: -3px;
       }
     }
   }
 }
 @media (max-width: 769px) {
   .division-title-container {
     column-gap: 12px;

     .division-icon-container {
       width: 14px;
       height: 14px;
     }

     .division-title-content {
       margin-bottom: -4px;
       font-size: 12px;
       .circle-container {
         bottom: 7px;
         height: 2px;
         width: 2px;
         right: -3px;
       }
     }
   }
 }

 @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
   .division-title-container {
     column-gap: 12px;

     .division-icon-container {
       width: 14px;
       height: 14px;
     }

     .division-title-content {
       font-size: 12px;
       .circle-container {
         bottom: 7px;
         height: 2px;
         width: 2px;
         right: -3px;
       }
     }
   }
 }
 @media (min-height: 600px) and (max-height: 630px){
   //для экранов 8 плюс
 }

 @media (max-width: 375px){
   //iPhone 8
 }


 @media (max-width: 375px) and (min-height: 630px) {
   //iPhone X
 }


 @media (max-width: 320px) {
   //iPhone SE
 }

 @media (min-width: 3025px) {
   .division-title-container {
     column-gap: 56px;

     .division-icon-container {
       width: 64px;
       height: 64px;
     }

     .division-title-content {
       font-size: 46px;
       .circle-container {
         bottom: -2px;
         height: 8px;
         width: 8px;
         right: -12px;
       }
     }
   }
 }

</style>