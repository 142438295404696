<template>
  <div class="cookie-container blur-active">
    <toggle-theme-local v-if="!activeMobile" :name-check="'cookie'"/>
    <transition name="fade-opacity-lazy">
      <cookie-start-button v-if="!cookieSetting"
                           @changeModalCookieSetting="changeModalCookieSetting"
                           @closeCookie="closeCookie"/>
      <cookie-modal @changeStateCookie="changeModalCookieSetting"
                    @closeCookie="closeCookie"
                    v-else/>
    </transition>
  </div>
</template>

<script>

import ToggleThemeLocal from "@/components/entities/ToggleThemeLocal.vue";
import CookieStartButton from "@/components/entities/cookie/CookieStartButton.vue";
import CookieModal from "@/components/entities/cookie/CookieModal.vue";
import {mapActions} from "vuex";

export default {
  name: "CookieScreen",
  components: {CookieModal, CookieStartButton, ToggleThemeLocal},
  data() {
    return {
      cookieSetting: false,
    }
  },
  methods: {
    ...mapActions(['ACTIVE_BLOCK_SWIPER']),
    changeModalCookieSetting() {
      this.cookieSetting = !this.cookieSetting;
    },
    closeCookie() {
      localStorage.setItem('cookieActive', 'true')
      this.ACTIVE_BLOCK_SWIPER(false)
      this.$emit('closeCookie', false)
    }
  }
}
</script>

<style scoped lang="scss">
.cookie-container {
  position: absolute;
  display: flex;
  justify-content: center;
  top:0;left:0;right:0;bottom: 0;
  width: var(--calc-width);
  height: var(--calc-height);
  z-index: 900;

  .toggle-container {
    z-index: 901;
    position: absolute;
  }
}

@media (max-width: 3024px){
  .cookie-container {
    .toggle-container {
      right: 200px;
      top: 100px
    }
  }
}


@media (max-width: 2100px) {
  .cookie-container {
    .toggle-container {
      right: 100px;
      top: 50px
    }
  }
}


@media (max-width: 1750px) {
  .cookie-container {
    .toggle-container {
      right: 100px;
      top: 50px
    }
  }
}
@media (max-height: 800px){
  .cookie-container {
    .toggle-container {
      right: 50px;
      top: 25px
    }
  }
}


@media (max-width: 1400px) and (min-height: 801px) {
  .cookie-container {
    .toggle-container {
      right: 100px;
      top: 50px
    }
  }
}


@media (max-width: 1133px) {
  .cookie-container {
    .toggle-container {
      right: 50px;
      top: 25px
    }
  }
}

@media (max-width: 769px) {
  .cookie-container {
    align-items: center;
    padding: 0 14px;
  }
}
@media (max-width: 769px)  and (min-height: 748px) and (max-height: 1000px) {
  .cookie-container {
    align-items: center;
    padding: 0 16px;
  }
}


@media (min-width: 3025px) {
  .cookie-container {

    .toggle-container {
      right: 400px;
      top: 200px
    }
  }
}
</style>