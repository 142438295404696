<template>
  <div class="subbrand-screen-container main-container" v-if="!activeMobile">
    <transition name="fade-opacity-lazy" mode="out-in">
      <card-text-info v-if="activeContent"
                      @clickActive="changeContent"
                      :paragraph-info="$tm('subBrandsScreen.mainParagraph')"
                      :active-button="true"
                      :active-text="$t('subBrandsScreen.moreButton')"
                      :main-title="$t('subBrandsScreen.mainTitle')"/>
      <card-text-info v-else
                      :paragraph-info="$tm('subBrandsScreen.mainParagraphSecond')"
                      :active-static-title="false"
                      :active-button="true"
                      @clickActive="changeContent"
                      :direction-active="'left'"
                      :active-text="$t('subBrandsScreen.returnButton')"/>
    </transition>

    <div class="right-info-container">
      <div class="card-subbrand-container" v-for="item in $tm('subBrandsScreen.subBrandInfo')" :key="item.id">
        <transition name="fade-opacity-1">
          <card-info class="subbrand-card"
                        @click="routTo(item.rout)"
                         v-show="isVisible(item.id)"
                         :active-division="false"
                         :info-card="item"/>
        </transition>
      </div>
    </div>
  </div>

  <div class="subbrand-screen-container main-container" v-else>
    <transition name="fade-opacity-1">
      <button-title-mobile v-if="activeTitle"
                           @returnTo="changeContentMobile"
                           :active-button="activeFullCard || !activeContent"
                           :main-title="$t('breadCrumb.dynamicTitle.subbrands.staticTitle')"/>
    </transition>

    <transition name="fade-opacity-lazy" mode="out-in">
    <div class="subbrand-screen-content" v-if="!activeFullCard">
      <transition name="fade-opacity-lazy" mode="out-in">
        <card-text-info v-if="activeContent"
                        :active-animation="activeMountedAnimation"
                        @clickActive="changeContent"
                        :paragraph-info="$tm('subBrandsScreen.mainParagraph')"
                        :active-button="false"
                        :active-text="$t('subBrandsScreen.moreButton')"
                        :main-title="$t('subBrandsScreen.mainTitle')"/>
        <card-text-info v-else
                        class="about"
                        :active-animation="false"
                        :paragraph-info="$tm('subBrandsScreen.mainParagraphSecond')"
                        :active-static-title="false"
                        :active-button="false"
                        @clickActive="changeContent"
                        :direction-active="'left'"
                        :active-text="$t('subBrandsScreen.returnButton')"/>
      </transition>
      <transition name="fade-opacity-lazy">
        <div class="button-container" v-if="activeContentMobile">
          <transition name="fade-opacity-1">
            <button-arrow @click="changeContent"
                          :direction-active="'right'"
                          v-if="isVisible(0)"
                          :active-text="$t('subBrandsScreen.moreButton')"/>
          </transition>
        </div>
      </transition>

      <transition name="fade-opacity-lazy">
      <div class="right-info-container" v-if="activeContentMobile">
        <div class="card-subbrand-container" v-for="item in $tm('subBrandsScreen.subBrandInfo')" :key="item.id">
          <transition name="fade-opacity-1">
            <card-info class="subbrand-card"
                       @click="changeStateActiveFullCard"
                       :active-text="false"
                       v-show="isVisible(item.id + 1)"
                       :active-division="false"
                       :info-card="item"/>
          </transition>
        </div>
      </div>
      </transition>
    </div>

    <div class="right-info-container active" v-else>
      <div class="card-subbrand-container"
           v-for="item in $tm('subBrandsScreen.subBrandInfo')" :key="item.id">
        <transition name="fade-opacity-lazy">
          <card-info class="subbrand-card"
                     @click="routTo(item.rout)"
                     :active-text="true"
                     :active-division="false"
                     :info-card="item"/>
        </transition>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
import CardTextInfo from "@/components/entities/cards/CardTextInfo.vue";
import CardInfo from "@/components/entities/cards/CardInfo.vue";
import ButtonArrow from "@/components/shared/buttons/ButtonArrow.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";
export default {
  name: "SubbrandsScreen",
  components: {ButtonTitleMobile, ButtonArrow, CardTextInfo, CardInfo},
  data() {
    return {
      activeContent: true,
      activeContentMobile: true,
      currentItem: -1,
      activeFullCard: false,
      activeTitle: false,
      activeMountedAnimation: true
    }
  },
  mounted() {
    this.activeTitle = true
    this.activeMountedAnimation = false
    if(this.activeMobile) {
      setTimeout(() => {
        this.showNextItem(500);
      }, 1250)
    } else {
      setTimeout(() => {
        this.showNextItem(1000);
      }, 500)
    }
  },

  methods: {

    routTo(rout) {
      if(rout !== '') {
        window.open(rout, '_blank');
      }
    },

    changeContent() {
      this.activeContent = !this.activeContent;
      if(this.activeMobile) {
        if(this.activeContentMobile) {
          this.activeContentMobile = false
        } else {
          setTimeout(() => {
            this.activeContentMobile = true;
          }, 500)
        }
      }
    },

    changeContentMobile() {
      if(this.activeFullCard) {
        this.changeStateActiveFullCard();
      } else {
        this.changeContent();
      }
    },

    changeStateActiveFullCard() {
      this.activeFullCard = !this.activeFullCard;
    },


    showNextItem(time) {
        if (this.currentItem < 4) {
          setTimeout(() => {
            this.currentItem++
            this.showNextItem(time)
          }, time)
        }
    },
    isVisible(index) {
      return index <= this.currentItem
    },
  }
}
</script>

<style scoped lang="scss">
.subbrand-screen-container {
  flex-direction: row;
  align-items: center;

  .right-info-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .card-subbrand-container {
      flex-basis: 25%;
    }
  }
}

@media (min-width: 770px) {
  .subbrand-screen-container {
    --main-padding-bottom: 0;
  }
}

@media (max-width: 769px) {
  .subbrand-screen-container {
    padding: var(--burger-height) 0 0;
    flex-direction: column;

    .main-title-container {
      padding-left: var(--controller-padding);
    }

    .right-info-container {
      &.active {
        .card-subbrand-container {
          max-height: 118px;
          .card-info-container {
            max-height:  inherit;
          }
        }
      }
    }


    .subbrand-screen-content {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .card-text-container {
        max-height: 202px;

        &.about {
          max-height: 292px;
        }
      }


      .button-container {
        position: relative;
        height: 12px;
        align-self: flex-end;
        margin-right: 36px;
      }


      .right-info-container {
        max-height: 256px;
        justify-content: flex-end;
        width: 100%;


        .card-subbrand-container {
          width: 100%;
          max-height: 58px;
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .subbrand-screen-container {
    .right-info-container {
      &.active {
        .card-subbrand-container {
          max-height: 130px;
        }
      }
    }
    .subbrand-screen-content {
      .card-text-container {
        max-height: 204px;

        &.about {
          max-height: 276px;
        }
      }
      .button-container {
        height: 12px;
        margin-right: 36px;
      }
      .right-info-container {
        max-height: 278px;
        .card-subbrand-container {
          max-height: 70px;
        }
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .subbrand-screen-container {
    .right-info-container {
      &.active {
        .card-subbrand-container {
          max-height: 101px;
        }
      }
    }
    .subbrand-screen-content {
      .card-text-container {
        max-height: 218px;

        &.about {
          max-height: 292px;
        }
      }
      .button-container {
        height: 12px;
        margin-right: 36px;
      }
      .right-info-container {
        max-height: 141px;
        .card-subbrand-container {
          max-height: 36px;
        }
      }
    }
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .subbrand-screen-container {
    .right-info-container {
      &.active {
        .card-subbrand-container {
          max-height: 111px;
        }
      }
    }
    .subbrand-screen-content {
      .card-text-container {
        max-height: 218px;

        &.about {
          max-height: 292px;
        }
      }
      .button-container {
        height: 12px;
        margin-right: 36px;
      }
      .right-info-container {
        max-height: 190px;
        .card-subbrand-container {
          max-height: 48px;
        }
      }
    }
  }
}


@media (max-width: 320px) {
  .subbrand-screen-container {
    .right-info-container {
      &.active {
        .card-subbrand-container {
          max-height: 101px;
        }
      }
    }
    .subbrand-screen-content {
      .card-text-container {
        max-height: 164px;

        &.about {
          max-height: 292px;
        }
      }
      .button-container {
        height: 12px;
        margin-right: 36px;
      }
      .right-info-container {
        max-height: 141px;
        .card-subbrand-container {
          max-height: 36px;
        }
      }
    }
  }
}

</style>