<template>
  <modal-frame-background>
    <modal-frame :active-title="activeTitle.mainTitle" @closeModalCross="closeModal">
      <div class="input-info-container">
        <input-main v-for="item in activeTitle.inputInfo"
                    @changeTextInput="changeTextInput"
                    :name-block="'input-info-container'"
                    :info="item"/>
      </div>
      <button-with-icon
          @click="submitInfo"
          :disabled-button="!isValid"
          :info-text="$t('modalLogin.submitInfo')"
          :info-icon="'exit.svg'"/>
    </modal-frame>
  </modal-frame-background>
</template>

<script>
import ModalFrameBackground from "@/components/entities/modal/ModalFrameBackground.vue";
import ModalFrame from "@/components/entities/modal/ModalFrame.vue";
import InputMain from "@/components/shared/input/InputMain.vue";
import ButtonWithIcon from "@/components/shared/buttons/ButtonWithIcon.vue";

export default {
  name: "ModalLogin",
  components: {ButtonWithIcon, InputMain, ModalFrame, ModalFrameBackground},
  props: {
    activeTitle: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      resultInput: {
        login: '',
        password: ''
      }
    }
  },
  computed: {
    isValid() {
      return Object.values(this.resultInput).every(v => v.length)
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    changeTextInput(item, dataIndex) {
      this.resultInput[dataIndex] = item
    },
    submitInfo() {
      if(this.isValid) {
        this.$emit('closeModal')
      }
    }
  }
}
</script>

<style scoped lang="scss">

.frame-modal-container {
  .input-info-container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 3024px){
  .frame-modal-container {
    max-width: 740px;
    row-gap: 64px;
    .input-info-container {
      row-gap: 11px;
    }
  }
}


@media (max-width: 2100px) {
  .frame-modal-container {
    max-width: 640px;
    row-gap: 48px;
    .input-info-container {
      row-gap: 9px;
    }
  }
}

@media (max-width: 1750px) {
  .frame-modal-container {
    max-width: 480px;
    row-gap: 32px;
    .input-info-container {
      row-gap: 7px;
    }
  }
}
@media (max-height: 800px) {
  .frame-modal-container {
    max-width: 440px;
    row-gap: 24px;
    .input-info-container {
      row-gap: 6px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .frame-modal-container {
    max-width: 480px;
    row-gap: 32px;
    .input-info-container {
      row-gap: 7px;
    }
  }
}

@media (max-width: 1133px) {
  .frame-modal-container {
    max-width: 440px;
    row-gap: 24px;
    .input-info-container {
      row-gap: 6px;
    }
  }
}
@media (max-width: 769px) {
  .frame-modal-container {
    height: 100%;
    border-width: 0;
    border-radius: 0;
    max-width: 100%;
    row-gap: 24px;
    .input-info-container {
      row-gap: 6px;
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .frame-modal-container {
    max-width: 1100px;
    row-gap: 80px;
    .input-info-container {
      row-gap: 16px;
    }
  }
}


</style>