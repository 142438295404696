<template>
  <modal-result :active-text="$t('mistakesModal.resultText')"
                :active-result="activeResult"
                :active-title="$t('mistakesModal.resultTitle')">
    <modal-frame :active-title="$t('mistakesModal.mainTitle')"
                 @closeModalCross="closeModal">
      <input-main :info="$tm('mistakesModal.textareaInfo')"
                  @changeTextInput="changeTextInput"
                  :name-block="'frame-modal-container'"
                  :length-text-area="300"/>

      <input-file active-input="file"
                  @changeActiveFile="changeActiveFile"/>
      <button-with-icon :info-icon="'message.svg'"
                        @click="submitInfo"
                        :disabled-button="!isValid"
                        :info-text="$t('submitButton')"/>
    </modal-frame>
  </modal-result>
</template>

<script>
import ModalFrameBackground from "@/components/entities/modal/ModalFrameBackground.vue";
import ModalFrame from "@/components/entities/modal/ModalFrame.vue";
import InputMain from "@/components/shared/input/InputMain.vue";
import InputFile from "@/components/shared/input/InputFile.vue";
import ButtonWithIcon from "@/components/shared/buttons/ButtonWithIcon.vue";
import ModalResult from "@/components/entities/modal/ModalResult.vue";

export default {
  name: "ModalMistakes",
  components: {ModalResult, ButtonWithIcon, InputFile, InputMain, ModalFrame, ModalFrameBackground},
  data() {
    return {
      fileSrc: null,
      messageFile: '',
      activeResult: false
    }
  },
  computed: {
    isValid() {
      return this.fileSrc !== null && this.messageFile.length;
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    changeTextInput(item) {
      this.messageFile = item
    },
    changeActiveFile(item) {
      this.fileSrc = item
    },
    submitInfo() {
      if(this.isValid) {
        console.log('submit info')
        this.activeResult = true
      }
    }
  }
}
</script>

<style scoped lang="scss">

@media (max-width: 3024px){
  .frame-modal-container {
    max-width: 1085px;
  }
}


@media (max-width: 2100px) {
  .frame-modal-container {
    max-width: 890px;
  }
}

@media (max-width: 1750px) {
  .frame-modal-container {
    max-width: 680px;
  }
}
@media (max-height: 800px) {
  .frame-modal-container {
    max-width: 520px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .frame-modal-container {
    max-width: 680px;
  }
}

@media (max-width: 1133px) {
  .frame-modal-container {
    max-width: 520px;
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .frame-modal-container {
    max-width: 1360px;
  }
}

</style>