<template>
  <modal-result :active-text="activeText?.resultText"
                :active-result="activeResult"
                :active-title="activeText?.resultTitle">
    <modal-frame @closeModalCross="closeModal"
                 :active-title="activeText?.mainTitle">

      <div class="modal-frame-input-container">
        <div class="modal-frame-input-content"
             :class="item.url"
             v-for="item in $tm('consultingModal.inputInfo')">
          <sub-paragraph :class="{'eng' : activeEng}">
            {{ item.mainTitle }}
          </sub-paragraph>
          <input-main :info="itemInput"
                      :key="itemInput.id"
                      :name-block="item.url"
                      v-for="itemInput in item.inputInfo"
                      @changeTextInput="changeTextInput"
          />
        </div>
      </div>

      <div class="modal-frame-textarea-content">
        <input-main :info="$tm('consultingModal.textareaInfo')"
                    :length-text-area="500"
                    :name-block="'modal-frame-textarea-content'"/>
      </div>

      <div class="modal-frame-button-container">
        <button-agreement @changeStateAgreementButton="changeStateAgreementButton"/>
        <button-with-icon :info-icon="'message.svg'"
                          @click="submitInfo"
                          :disabled-button="!isValid"
                          :info-text="$t('submitButton')"/>
      </div>
    </modal-frame>
  </modal-result>
</template>

<script>
import ModalFrameBackground from "@/components/entities/modal/ModalFrameBackground.vue";
import ModalFrame from "@/components/entities/modal/ModalFrame.vue";
import ButtonAgreement from "@/components/shared/buttons/ButtonAgreement.vue";
import MainButton from "@/components/shared/MainButton.vue";
import ButtonWithIcon from "@/components/shared/buttons/ButtonWithIcon.vue";
import SubParagraph from "@/components/shared/text/SubParagraph.vue";
import InputMain from "@/components/shared/input/InputMain.vue";
import ModalResult from "@/components/entities/modal/ModalResult.vue";

export default {
  name: "ModalConsulting",
  props: {
    activeText: {
      type: Object
    }
  },
  components: {
    ModalResult,
    InputMain, SubParagraph, ButtonWithIcon, MainButton, ButtonAgreement, ModalFrame, ModalFrameBackground},

  data() {
    return {
      stateAgreementButton: false,
      resultInput: {
        name: '',
        company: '',
        position: '',
        email: '',
        telegram: '',
        message: ''
      },
      activeResult: false
    }
  },

  computed:{
    isValid() {
      return this.stateAgreementButton
          && this.resultInput.name.length
          && this.resultInput.email.length
    }
  },

  methods: {
    closeModal() {
      this.$emit('closeModal')
    },

    changeStateAgreementButton() {
      this.stateAgreementButton = !this.stateAgreementButton
    },

    changeTextInput(item, dataIndex) {
      this.resultInput[dataIndex] = item
    },
    submitInfo() {
      if(this.isValid) {
        console.log('submit info')
        this.activeResult = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .frame-modal-container {
    .modal-frame-button-container  {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .modal-frame-input-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      .modal-frame-input-content {
        display: flex;
        flex-direction: column;
        flex-basis: 33.33%;
      }
    }
  }

  @media (max-width: 3024px){
    .frame-modal-container {
      max-width: 1452px;

      .modal-frame-button-container  {
        .sub-button-container {
          max-width: 420px;
        }
      }

      .modal-frame-input-container {
        column-gap: 32px;
        .modal-frame-input-content {
          row-gap: 11px;
        }
      }
    }
  }


  @media (max-width: 2100px) {
    .frame-modal-container {
      max-width: 1344px;

      .modal-frame-button-container  {
        .sub-button-container {
          max-width: 400px;
        }
      }

      .modal-frame-input-container {
        column-gap: 24px;
        .modal-frame-input-content {
          row-gap: 9px;
        }
      }
    }
  }

  @media (max-width: 1750px) {
    .frame-modal-container {
      max-width: 966px;

      .modal-frame-button-container  {
        .sub-button-container {
          max-width: 290px;
        }
      }

      .modal-frame-input-container {
        column-gap: 16px;
        .modal-frame-input-content {
          row-gap: 7px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .frame-modal-container {
      max-width: 732px;

      .modal-frame-button-container  {
        .sub-button-container {
          max-width: 220px;
        }
      }

      .modal-frame-input-container {
        column-gap: 12px;
        .modal-frame-input-content {
          row-gap: 6px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .frame-modal-container {
      max-width: 966px;

      .modal-frame-button-container  {
        .sub-button-container {
          max-width: 290px;
        }
      }

      .modal-frame-input-container {
        column-gap: 16px;
        .modal-frame-input-content {
          row-gap: 7px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
  }
  @media (max-width: 769px) {
    .frame-modal-container {
      padding: 20px;
      justify-content: space-between;
      border-width: 0;
      border-radius: 0;
      max-width: 100%;
      height: 100%;

      .sub-paragraph-container {
        font-size: 12px;
      }

      .modal-frame-textarea-content {
        height: 100%;
      }

      .modal-frame-button-container  {
        row-gap: 12px;
        flex-direction: column;


        .sub-button-container {
          max-width: 100%;
        }
      }

      .modal-frame-input-container {
        flex-direction: column;
        row-gap: 12px;

        .modal-frame-input-content {
          row-gap: 6px;

          .sub-paragraph-container {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .frame-modal-container {
      .modal-frame-button-container  {
        row-gap: 12px;
      }

      .modal-frame-input-container {
        row-gap: 12px;

        .modal-frame-input-content {
          row-gap: 6px;

        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    .frame-modal-container {
      padding: 16px;

      .sub-paragraph-container {
        font-size: 10px;
      }


      .modal-frame-button-container  {
        row-gap: 10px;
      }

      .modal-frame-input-container {
        flex-direction: column;
        row-gap: 10px;

        .modal-frame-input-content {
          row-gap: 6px;
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .frame-modal-container {
      max-width: 2100px;

      .modal-frame-button-container  {
        .sub-button-container {
          max-width: 620px;
        }
      }

      .modal-frame-input-container {
        column-gap: 40px;
        .modal-frame-input-content {
          row-gap: 16px;
        }
      }
    }
  }

</style>