<template>
  <div class="partners-screen-container main-container">

    <button-title-mobile v-if="activeMobile"
                         :active-button="false"
                         :main-title="$t('partnersScreen.smallTitleMobile')"/>
    
    <div class="partners-screen-content">

      <transition name="fade-opacity-1" mode="out-in">
          <div class="top-info-container" v-if="activeTop">
            <medium-title v-if="!activeMobile">
              {{ $t('partnersScreen.mainTitle') }}
            </medium-title>
            <medium-title v-else>
              {{ $t('partnersScreen.mainTitleMobile') }}
            </medium-title>
          </div>
      </transition>

      <transition name="fade-translate-y-revert">
          <div class="slider-container" v-if="activeBottom">
            <slider-scroll-partners :active-info="partnersInfo"/>
          </div>
      </transition>
    </div>

  </div>
</template>

<script>
import MediumTitle from "@/components/shared/text/MediumTitle.vue";
import SliderScrollPartners from "@/components/entities/sliders/SliderScrollPartners.vue";
import ButtonTitleMobile from "@/components/entities/ButtonTitleMobile.vue";

export default {
  name: "PartnersScreen",
  components: {ButtonTitleMobile, SliderScrollPartners, MediumTitle},
  data() {
    return {
      activeTop: false,
      activeBottom: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.startAnimation();
    }, 500)
  },
  computed: {
    partnersInfo() {
      return this.$tm('partnersScreen.partnersInfo')
    }
  },
  methods: {
    startAnimation() {
      this.activeTop = true;
      setTimeout(() => {
        this.activeBottom = true
      }, 500)
    },
  }
}
</script>

<style scoped lang="scss">
  .partners-screen-container {
    justify-content: flex-end;
      .partners-screen-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .slider-container {
          height: 100%
        }
      }
  }

  @media (max-width: 3840px) and (min-width: 3025px) {
    .partners-screen-container {
      .partners-screen-content {
        max-height: 1757px;

        .top-info-container {
          padding-left: 120px;
          .medium-title-container {
            font-size: 80px;
          }
        }

        .slider-container {
          max-height: 1232px;
        }
      }
    }
  }

  @media (max-width: 3024px){
    .partners-screen-container {
      .partners-screen-content {
        max-height: 1084px;

        .top-info-container {
          padding-left: 48px;
        }

        .slider-container {
          max-height: 698px;

          .slider-scroll-container {
            //max-height: 698px;
          }
        }
      }
    }
  }

  @media (max-width: 2240px) {
    .partners-screen-container {
      .partners-screen-content {
        max-height: 975px;
        .slider-container {
          max-height: 698px;

          .slider-scroll-container {
            //max-height: 616px;
          }
        }
      }
    }
  }


  @media (max-width: 2100px) {
    .partners-screen-container {
      .partners-screen-content {
        max-height: 878px;


        .slider-container {
          max-height: 616px;

          .slider-scroll-container {
            //max-height: 616px;
          }
        }
      }
    }
  }


  @media (max-width: 1750px) {
    .partners-screen-container {
      .partners-screen-content {
        max-height: 720px;

        .top-info-container {
          padding-left: 32px;
        }
        .slider-container {
          max-height: 514px;

          .slider-scroll-container {
            //max-height: 514px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .partners-screen-container {
      .partners-screen-content {
        max-height: 600px;

        .top-info-container {
          padding-left: 24px;
        }

        .slider-container {
          max-height: 430px;

          .slider-scroll-container {
            //max-height: 430px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .partners-screen-container {
      .partners-screen-content {
        max-height: 783px;

        .top-info-container {
          padding-left: 32px;
        }
        .slider-container {
          max-height: 514px;

          .slider-scroll-container {
            //max-height: 514px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .partners-screen-container {
      .partners-screen-content {
        max-height: 600px;

        .top-info-container {
          padding-left: 24px;
        }

        .slider-container {
          max-height: 430px;

          .slider-scroll-container {
            //max-height: 430px;
          }
        }
      }
    }
  }
  @media (max-width: 769px) {
    .partners-screen-container {
      .partners-screen-content {
        max-height: 100%;

        .top-info-container {
          padding: 35px 14px 35px 0;

          .medium-title-container {
            font-weight: 400;
            font-size: 16px;
          }
        }

        .slider-container {
          max-height: 455px;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .partners-screen-container {
      .partners-screen-content {

        .top-info-container {
          padding: 32px 16px 32px 0;

          .medium-title-container {
            font-size: 18px;
          }
        }

        .slider-container {
          max-height: 510px;
        }
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    .partners-screen-container {
      .partners-screen-content {
        .top-info-container {
          padding: 22px 14px 22px 0;

          .medium-title-container {
            font-size: 14px;
          }
        }

        .slider-container {
          max-height: 379px;
        }
      }
    }
  }


  @media (max-width: 375px) and (min-height: 630px) {
    .partners-screen-container {
      .partners-screen-content {
        .top-info-container {
          padding: 35px 14px 35px 0;

          .medium-title-container {
            font-size: 16px;
          }
        }

        .slider-container {
          max-height: 428px;
        }
      }
    }
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3841px) {
    .partners-screen-container {
      .partners-screen-content {
        max-height: 1905px;

        .top-info-container {
          padding-left: 120px;

          .medium-title-container {
            font-size: 80px;
          }
        }

        .slider-container {
          //max-height: 1200px;
        }
      }
    }
  }

</style>