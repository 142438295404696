<template>
  <p class="medium-title-container" :class="{'eng' : activeEng}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MediumTitle"
}
</script>

<style scoped lang="scss">
.medium-title-container {
  color: var(--color-main-text);
  white-space: pre-wrap;
  font-weight: 200;
}
@media (max-width: 3840px){
        .medium-title-container {
          font-size: 96px;
        }
}

@media (max-width: 3024px){
        .medium-title-container {
          font-size: 56px;
        }
}


@media (max-width: 2100px) {
        .medium-title-container {
          font-size: 48px;
        }
}


@media (max-width: 1750px) {
        .medium-title-container {
          font-size: 32px;
        }
}
@media (max-height: 800px) {
        .medium-title-container {
          font-size: 28px;
        }
}

@media (max-width: 1400px) and (min-height: 801px) {
        .medium-title-container {
          font-size: 32px;
        }
}

@media (max-width: 1133px) {
        .medium-title-container {
          font-size: 28px;
        }
}
@media (max-width: 769px) {
  .medium-title-container {
    font-size: 18px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .medium-title-container {
    font-size: 22px;
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  .medium-title-container {
    font-size: 16px;
  }
}


@media (max-width: 375px) and (min-height: 630px) {
  .medium-title-container {
    font-size: 18px;
  }
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3841px) {
  .medium-title-container {
    font-size: 96px;
  }
}

</style>