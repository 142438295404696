<template>
  <slider-scroll :active-info-length="activeLength"
                 @mouseenter="mouseEnterBlockSwiper"
                 @mouseleave="mouseEnterUnblockSwiper"
                 :active-scroll-bar="activeScrollBar"
                 :slides-per-view="slidesPerView">
    <swiper-slide v-for="(item, index) in activeLength"
                  class="clickable"
                  v-slot="{ isActive, isPrev }"
                  :virtual-index="index"
                  :key="index">
      <card-partners
          v-if="index < this.activeInfo.length"
          :class="{'is-last' : activeMobile && index === activeLength - 1 && isActive,
          'is-last-prev' : activeMobile && isPrev && index === activeLength - 2}"
          :active-card-mobile="isActive"
          :info-card="activeInfo[index]"/>
      <div v-else class="empty-project"></div>
    </swiper-slide>
  </slider-scroll>
</template>

<script>
import CardPartners from "@/components/entities/cards/CardPartners.vue";
import SliderScroll from "@/components/entities/sliders/SliderScroll.vue";

export default {
  name: "SliderScrollPartners",
  components: {SliderScroll, CardPartners},
  props: {
    activeInfo: {
      type: Array,
      required: true
    }
  },
  computed: {
    slidesPerView() {
      if(this.activeMobile) {
        return 1.3;
      } else {
        return 3;
      }
    },

    activeScrollBar() {
      if(this.activeMobile) {
        return false
      } else {
        return true;
      }
    },


    activeLength() {
      return this.activeInfo.length
    }
  }
}
</script>

<style scoped lang="scss">

 @media (max-width: 769px) {
   .card-partners-container {
     &.is-last {
       transform: translateX(-86px);
     }
     &.is-last-prev {
       transform: translateX(-86px);
     }
   }
 }

 @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
   .card-partners-container {
     &.is-last {
       transform: translateX(-96px);
     }
     &.is-last-prev {
       transform: translateX(-96px);
     }
   }
 }

 @media (max-width: 375px){
   .card-partners-container {
     &.is-last {
       transform: translateX(-83px);
     }
     &.is-last-prev {
       transform: translateX(-83px);
     }
   }
 }
</style>