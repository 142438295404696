<template>
  <p class="small-paragraph-container">
   <slot/>
  </p>
</template>

<script>
export default {
  name: "SmallParagraph"
}
</script>

<style scoped lang="scss">

.small-paragraph-container {
  line-height: 120%;
  color: var(--color-main-text);
  white-space: pre-wrap;
}

@media (max-width: 3024px){
  .small-paragraph-container {
    font-size: 24px;
  }
}


@media (max-width: 2100px) {
  .small-paragraph-container {
    font-size: 18px;
  }
}


@media (max-width: 1750px) {
  .small-paragraph-container {
    font-size: 16px;
  }
}
@media (max-height: 800px) {
  .small-paragraph-container {
    font-size: 12px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .small-paragraph-container {
    font-size: 16px;
  }
}

@media (max-width: 1133px) {
  .small-paragraph-container {
    font-size: 12px;
  }
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .small-paragraph-container {
    font-size: 36px;
  }
}

</style>